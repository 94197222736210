// src/components/PrivateRoute.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../user/context/AuthContext";
import Preloader from "./Preloader";

const PrivateRoute = ({ roles, navigateToPath }) => {
  const { loader, user } = useAuth();

  if (loader) return <Preloader />;

  if ((!user && !loader) || (roles && !roles.includes(user.type))) {
    return <Navigate to={navigateToPath} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
