import axiosInstance from "../../interceptor/axiosInstance";

export const getAllUomsListAdmin = async () => {
  const response = await axiosInstance.get("/adminpanel/uom/list/");
  return response.data.data || [];
};

export const createUom = async (data) => {
  const response = await axiosInstance.post("adminpanel/uom/create/", {
    ...data,
  });
  return response.data.data;
};

export const updateUom = async (id, data) => {
  const response = await axiosInstance.patch(
    "adminpanel/uom/update/?uom_id=" + id,
    {
      ...data,
    }
  );
  return response.data.data;
};

export const deleteUom = async (id) => {
  const response = await axiosInstance.delete(
    "/adminpanel/uom/delete/?uom_id=" + id
  );
  return response.data;
};
