import { useSelector } from "react-redux";
import { shopPaginationComponent } from "../../constants/localization";

const Pagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onChangePage,
  totalPages,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const { language } = useSelector((state) => state.setting);
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="mbp_pagination mt30 text-center">
          <ul className="page_navigation d-flex align-items-center justify-content-center">
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => onChangePage(currentPage - 1)}
                aria-disabled={currentPage === 1}
              >
                <span className="fas fa-angle-left"></span>
              </a>
            </li>
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`page-item ${
                  currentPage === number ? "active" : ""
                }`}
              >
                <a className="page-link" onClick={() => onChangePage(number)}>
                  {number}
                </a>
              </li>
            ))}
            <li className="page-item">
              <button
                className="page-link inline-block"
                onClick={() => onChangePage(currentPage + 1)}
                disabled={currentPage * itemsPerPage >= totalItems}
              >
                <span className="fas fa-angle-right"></span>
              </button>
            </li>
          </ul>
          <p className="mt20 pagination_page_count text-center">
            {shopPaginationComponent.items[language]}{" "}
            {(currentPage - 1) * itemsPerPage + 1} –
            {Math.min(currentPage * itemsPerPage, totalItems)}{" "}
            {shopPaginationComponent.of[language]} {totalItems}
            {shopPaginationComponent.productsFound[language]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
