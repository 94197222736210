const UOMFilter = ({ onChangeFilter, filter, onClick }) => {
  const { search } = filter;

  return (
    <div className="row d-block d-sm-flex justify-content-center justify-content-sm-between">
      <div className="col-sm-6 col-lg-3">
        <div className="dashboard_page header_search_widget mb30 mb15-520">
          <div className="input-group">
            <input
              value={search}
              onChange={(e) => onChangeFilter("search", e.target.value)}
              type="text"
              className="form-control"
              placeholder="Search Tag"
              aria-label="Recipient's username"
            />
            <div className="input-group-append">
              <button className="btn" type="button">
                <span className="fa fa-search"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-lg-3">
        <div className="d-flex justify-content-end">
          <button onClick={onClick} className="btn btn-new btn-lg btn-thm">
            Create Tag
          </button>
        </div>
      </div>
    </div>
  );
};

export default UOMFilter;
