import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import { useSelector } from "react-redux";
import CustomerPortalLayout from "../components/CustomerPortal/CustomerPortalLayout";
import { getDeliveries } from "../../vendor/services/order";
import MyDeliveriesTable from "../components/MyDeliveries/MyDeliveriesTable";

import ReactSelect from "react-select";

const refundOptions = [
  { value: false, label: { eng: "ALL", arb: "الكل" } },
  { value: true, label: { eng: "Refund", arb: "استرداد" } },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    zIndex: 10,
    marginBottom: "10px",
  }),
};

const MyDeliveries = () => {
  const [loading, setLoading] = useState(true);
  const [deliveries, setDeliveries] = useState([]);
  const [filteredDeliveries, setFilteredDeliveries] = useState([]);
  const { language } = useSelector((state) => state.setting);

  const [isRefund, setIsRefund] = useState(false);
  const { loading: loader } = useSelector((state) => state.category);

  const localizedOptions = refundOptions.map((option) => ({
    value: option.value,
    label: option.label[language],
  }));

  useEffect(() => {
    if (!loader)
      (async () => {
        const response = await getDeliveries(isRefund);
        setDeliveries(response);
        setFilteredDeliveries(response);
        setLoading(false);
      })();
  }, [loader]);

  const handleDeliveries = (is_refund) => {
    let data = [];
    if (is_refund) {
      data = deliveries.filter((item) => item.parent_id !== null);
    } else {
      data = deliveries.filter((item) => !item.parent_id);
    }
    setIsRefund(is_refund);
    setFilteredDeliveries(data);
  };

  if (loading) return <Preloader />;
  return (
    <CustomerPortalLayout>
      <div className="container mt-4">
        <div className="column">
          <div className="d-flex justify-content-end">
            <ReactSelect
              onChange={({ value }) => handleDeliveries(value)}
              value={localizedOptions.find((item) => item.value === isRefund)}
              options={localizedOptions}
              styles={customStyles}
            />
          </div>

          <MyDeliveriesTable deliveries={filteredDeliveries} />
        </div>
      </div>
    </CustomerPortalLayout>
  );
};

export default MyDeliveries;
