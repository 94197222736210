import axios from "axios";
import axiosInstance from "../../interceptor/axiosInstance";

export const getAllAdminSaleOrders = async () => {
  const response = await axiosInstance.get("adminpanel/sale_order/list/");
  return response.data.data || [];
};

export const getSaleOrderById = async (id) => {
  const response = await axios.get(
    "http://localhost:3001/adminpanel/sale_order/details"
  );

  return response.data;
};
