const TableRow = ({ productItem, onEdit, onDelete, index }) => {
  const {
    id,
    name_eng,
    brand,
    sku,
    sale_price,
    cost_price,
    category,
    created_at,
    variant_count,
    name_arb,
  } = productItem;
  const { name_eng: categoryNameEng, name_arb: categoryNameArb } = category;

  return (
    <tr>
      <th scope="row">{id}</th>
      <td>
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            whiteSpace: "nowrap",
          }}
        >
          {name_eng}
        </p>
      </td>
      <td>
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            whiteSpace: "nowrap",
          }}
        >
          {name_arb}
        </p>
      </td>

      <td>{brand.name}</td>

      <td>{sku}</td>
      <td className="status">
        <span className="style4">Stock</span>
      </td>
      <td className="status">
        <span className="style4">{variant_count}</span>
      </td>
      <td>${sale_price}</td>
      <td>${cost_price}</td>
      <td>{`${categoryNameEng}`}</td>
      <td className="action">
        <span>{created_at}</span>
      </td>
      <td className="editing_list align-middle">
        <ul>
          <li className="list-inline-item mb-1">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => onEdit(id)}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit"
              data-bs-original-title="View"
              aria-label="View"
            >
              <span className="flaticon-pencil"></span>
            </span>
          </li>
          <li className="list-inline-item mb-1">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => onDelete(id, index)}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete"
              data-bs-original-title="Edit"
              aria-label="Edit"
            >
              <span className="flaticon-delete"></span>
            </span>
          </li>
        </ul>
      </td>
    </tr>
  );
};

export default TableRow;
