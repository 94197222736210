import InvoiceTableRow from "./InvoiceTableRow";

const InvoiceTableBody = ({ onPayment, invoices }) => {
  return (
    <tbody style={{ width: "100%" }}>
      {invoices.map((invoiceItem) => (
        <InvoiceTableRow
          key={invoiceItem.id}
          onPayment={onPayment}
          invoiceItem={invoiceItem}
        />
      ))}
    </tbody>
  );
};

export default InvoiceTableBody;
