import { ThreeDots } from "react-loader-spinner";
import ProductCard from "../ProductListing/ProductCard";
import NoProductFound from "./NoProductFound";
import ShopHeader from "./ShopHeader";
import DottedLoading from "../../../shared/components/DottedLoading";

const ShopProductListing = ({
  currentPage,
  totalItems,
  products,
  limit,
  onChangeLimit,
  isFiltered,

  onFilter,
  productTypes,
  customRange,
  filters,
  onRemoveFilter,
}) => {
  return (
    <div className="col-lg-9 col-xl-10 pl50 pl15-md">
      <ShopHeader
        totalItems={totalItems}
        currentPage={currentPage}
        limit={limit}
        onChangeLimit={onChangeLimit}
        onFilter={onFilter}
        productTypes={productTypes}
        customRange={customRange}
        filters={filters}
        onRemoveFilter={onRemoveFilter}
      />
      <div className="row">
        {isFiltered ? (
          <DottedLoading />
        ) : products.length > 0 ? (
          products.map((product, index) => (
            <ProductCard product={product} key={index} />
          ))
        ) : (
          <NoProductFound />
        )}
      </div>
    </div>
  );
};

export default ShopProductListing;
