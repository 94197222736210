import { Link } from "react-router-dom";

const MyDeliveryTableItem = ({ deliveryItem, onView, index }) => {
  const {
    id,
    name,
    schedule_date,
    tracking_reference,
    delivery_state,
    customer_id,
    delivery_address,
    sale_order,
  } = deliveryItem;

  return (
    <>
      <tr>
        <th scope="row">#{id}</th>
        <td>{name}</td>
        <td>{schedule_date}</td>
        <td>{tracking_reference}</td>
        <td className="status">
          <span>{delivery_state}</span>
        </td>
        <td>{customer_id.name}</td>
        <td>{customer_id.address.address}</td>
        <td className="d-flex gap-2">
          <Link to={`/my-deliveries/${id}/detail`} className="btn btn-custom">
            <i className="fa-regular fa-eye"></i>
          </Link>
        </td>
      </tr>
    </>
  );
};

export default MyDeliveryTableItem;
