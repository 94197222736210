import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import { getAllAdminInvoices } from "../services/invoice";
import useApi from "../../hooks/useAPI";
import Error from "../../shared/components/Error";
import InvoiceFilter from "../components/invoices/InvoiceFilter";
import InvoiceTable from "../components/invoices/Table/InvoiceTable";

const AdminInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const { data, error, loading, request } = useApi(getAllAdminInvoices);

  const [filter, setFilter] = useState({
    status: "",
    search: "",
  });

  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setInvoices(data);
    }
  }, [loading, data]);

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  const handleView = (index) => {
    console.log(invoices[index]);
  };

  if (loading) return <Preloader />;

  if (!loading && error) return <Error error={error} />;

  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>invoices</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <InvoiceFilter
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
            <InvoiceTable
              onView={handleView}
              invoices={invoices}
              filter={filter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminInvoices;
