import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authPageData } from "../../constants/localization";
import { userLogin } from "../../services/userAuth";
import { onUserLogin } from "../../store/userAuthSlice/userAuthSlice";
import { useDispatch, useSelector } from "react-redux";

const SignInHiddenSidebar = () => {
  const { language } = useSelector((state) => state.setting);

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await userLogin(form);
    if (response.flag) {
      const { user, access } = response;
      const data = {
        user,
        token: access,
      };
      dispatch(onUserLogin(data));
      handleHideSignIn();
      navigate("/");
    } else {
      setError(response.message);
    }
  };

  const handleHideSignIn = () =>
    document.body.classList.remove("signin-hidden-sidebar-content");
  const handleShowSignUp = () => {
    document.body.classList.add("singup-hidden-sidebar-content");
  };

  return (
    <div className="signin-hidden-sbar">
      <div className="hsidebar-header">
        <div
          onClick={handleHideSignIn}
          className={`sidebar-close-icon ${
            language === "eng"
              ? "sidebar-close-icon__eng"
              : "sidebar-close-icon__arb"
          }`}
        >
          <span className="flaticon-close" />
        </div>
        <h4 className="title">{authPageData.signIn[language]}</h4>
      </div>
      <div className="hsidebar-content">
        <div className="log_reg_form sidebar_area">
          <div className="login_form">
            <form onSubmit={handleSubmit}>
              <div className="mb-2 mr-sm-2">
                <label className="form-label">
                  {authPageData.yourEmail[language]}
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  className="form-control"
                  placeholder={authPageData.yourEmail[language]}
                />
              </div>
              <div className="form-group mb5">
                <label className="form-label">
                  {authPageData.password[language]}
                </label>
                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  className="form-control"
                  placeholder={authPageData.password[language]}
                />
              </div>
              {error && <h4 style={{ color: "red" }}>{error}</h4>}

              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="exampleCheck3"
                />
                <label className="custom-control-label" htmlFor="exampleCheck3">
                  {authPageData.rememberMe[language]}
                </label>
                <a
                  className={`btn-fpswd ${
                    language === "eng" ? "float-end" : "float-start"
                  }`}
                >
                  {authPageData.lostYourPassword[language]}
                </a>
              </div>
              <button type="submit" className="btn btn-log btn-thm mt20">
                {authPageData.login[language]}
              </button>
              <p className="text-center mb25 mt10">
                {authPageData.dontHaveAnAccount[language]}
                <span
                  style={{
                    fontWeight: "bold",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleShowSignUp}
                  className="signup-filter-btn"
                >
                  {authPageData.createAccount[language]}
                </span>
              </p>
              <div className="hr_content">
                <hr />
                <span className="hr_top_text">{authPageData.or[language]}</span>
              </div>
              <ul className="login_with_social text-center mt30 mb0">
                <li className="list-inline-item">
                  <a>
                    <i className="fab fa-facebook" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a>
                    <i className="fab fa-google" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a>
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a>
                    <i className="fab fa-apple" />
                  </a>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInHiddenSidebar;
