import React, { useState } from "react";
import { useSelector } from "react-redux";
import AppliedFilters from "./AppliedFilters";
import {
  deliveryOptions,
  customPriceOptions,
  discountOptions,
  filterText,
  productTypeOptions,
} from "../../constants/filtersOptions";
import { shopFilterSideBar } from "../../constants/localization";

const HiddenSideBarFilter = ({
  onFilter,
  customRange,
  filters,
  onRemoveFilter,
}) => {
  const { language } = useSelector((state) => state.setting);
  const [openPanel, setOpenPanel] = useState(null);

  const handleSelection = (name, checked, value) => {
    if (checked) {
      onFilter(name, value);
    }
  };

  const togglePanel = (panel) => {
    setOpenPanel(openPanel === panel ? null : panel);
  };

  const handleHideHiddenFilterSideBar = () => {
    document.body.classList.remove("allfilter-hidden-sidebar-content");
  };

  return (
    <div className="allfilter-hidden-sbar-left">
      <div className="hsidebar-header">
        <div
          onClick={handleHideHiddenFilterSideBar}
          className={`sidebar-close-icon ${
            language === "eng"
              ? "sidebar-close-icon__eng"
              : "sidebar-close-icon__arb"
          }`}
        >
          <span className="flaticon-close"></span>
        </div>
        <h4 className="title">{shopFilterSideBar.allFilters[language]}</h4>
        <AppliedFilters filters={filters} onRemoveFilter={onRemoveFilter} />
      </div>
      <div className="hsidebar-content">
        <div className="leftside_navbar_content_details">
          <div className="leftside_navbar_menu department_version shop_listing_page_style">
            <ul className="navbar-nav">
              {/* Department */}
              <FilterItem
                language={language}
                title={filterText.productType[language]}
                panelId="Department"
                openPanel={openPanel}
                togglePanel={togglePanel}
              >
                {productTypeOptions.map((item, index) => (
                  <label
                    className={`custom_radio_field ${
                      language === "eng" ? "" : "custom_radio_field__pr"
                    }`}
                    key={index}
                  >
                    <input
                      onChange={(e) =>
                        handleSelection(
                          "product_type",
                          e.target.checked,
                          item.value
                        )
                      }
                      checked={filters.product_type === item.value}
                      type="radio"
                    />
                    <span className="mx-2">{item.label[language]}</span>
                    <span className="checkmark"></span>
                  </label>
                ))}
              </FilterItem>

              {/* Price */}
              <FilterItem
                language={language}
                title={filterText.price[language]}
                panelId="Price"
                openPanel={openPanel}
                togglePanel={togglePanel}
              >
                {customPriceOptions.map((item, index) => (
                  <label
                    className={`custom_radio_field ${
                      language === "eng" ? "" : "custom_radio_field__pr"
                    }`}
                    key={index}
                  >
                    <input
                      onChange={(e) =>
                        handleSelection(
                          "priceRange",
                          e.target.checked,
                          item.value
                        )
                      }
                      checked={filters.priceRange === item.value}
                      type="radio"
                    />
                    <span className="mx-2">{item.label[language]}</span>
                    <span className="checkmark"></span>
                  </label>
                ))}
                <h4
                  style={{ paddingRight: language === "eng" ? "0px" : "28px" }}
                >
                  {shopFilterSideBar.customPriceRange[language]}
                </h4>
                <div className="d-flex gap-1">
                  <input
                    type="number"
                    className="amount"
                    placeholder="Min"
                    value={customRange.min}
                    onChange={(e) =>
                      onFilter("customRange", {
                        ...customRange,
                        min: +e.target.value,
                      })
                    }
                  />
                  <input
                    type="number"
                    className="amount"
                    placeholder="Max"
                    value={customRange.max}
                    onChange={(e) =>
                      onFilter("customRange", {
                        ...customRange,
                        max: +e.target.value,
                      })
                    }
                  />
                </div>
                <button
                  onClick={() =>
                    onFilter("customRange", {
                      ...customRange,
                      isConfirmed: true,
                    })
                  }
                  className="btn btn-thm mt-2"
                >
                  {shopFilterSideBar.confirm[language]}
                </button>
              </FilterItem>

              {/* Discount */}
              <FilterItem
                language={language}
                title={filterText.discount[language]}
                panelId="Discount"
                openPanel={openPanel}
                togglePanel={togglePanel}
              >
                {discountOptions.map((item, index) => (
                  <label
                    className={`custom_radio_field ${
                      language === "eng" ? "" : "custom_radio_field__pr"
                    }`}
                    key={index}
                  >
                    <input
                      onChange={(e) =>
                        handleSelection(
                          "discount",
                          e.target.checked,
                          item.value
                        )
                      }
                      checked={filters.discount === item.value}
                      type="radio"
                    />
                    <span className="mx-2">{item.label[language]}</span>
                    <span className="checkmark"></span>
                  </label>
                ))}
              </FilterItem>

              {/* Delivery */}
              <FilterItem
                language={language}
                title={filterText.delivery[language]}
                panelId="Delivery"
                openPanel={openPanel}
                togglePanel={togglePanel}
              >
                {deliveryOptions.map((item, index) => (
                  <label
                    className={`custom_radio_field ${
                      language === "eng" ? "" : "custom_radio_field__pr"
                    }`}
                    key={index}
                  >
                    <input
                      onChange={(e) =>
                        handleSelection(
                          "delivery_fee",
                          e.target.checked,
                          item.value
                        )
                      }
                      checked={filters.delivery_fee === item.value}
                      type="radio"
                    />
                    <span className="mx-2">{item.label[language]}</span>
                    <span className="checkmark"></span>
                  </label>
                ))}
              </FilterItem>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterItem = ({
  title,
  panelId,
  openPanel,
  togglePanel,
  children,
  language,
}) => (
  <li className="nav-item">
    <a
      className="nav-link collapsed"
      href={`#sidebar${panelId}`}
      data-bs-toggle="collapse"
      role="button"
      aria-expanded={openPanel === panelId}
      aria-controls={`sidebar${panelId}`}
    >
      {title}{" "}
      <i
        className={`fa fa-plus arrow ${
          language === "eng" ? "right__icon" : "left__icon"
        }`}
      ></i>
    </a>
    <div
      className={`collapse ${openPanel === panelId ? "show" : ""}`}
      id={`sidebar${panelId}`}
    >
      <ul className="nav nav-sm flex-column">{children}</ul>
    </div>
  </li>
);

export default HiddenSideBarFilter;
