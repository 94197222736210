import usePagination from "../../../../hooks/usePagination";
import TablePagination from "../../TablePagination";
import PortalCategoryHeader from "./PortalCategoryHeader";
import PortalCategoryTableBody from "./PortalCategoryTableBody";

const PortalCategoryTable = ({
  filter,
  categories,
  role = "",
  onDelete,
  onEdit,
}) => {
  const { search } = filter;
  let filteredCategories = categories;

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredCategories = categories.filter((category) =>
      category.name.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredCategories, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <PortalCategoryHeader role={role} />
          <PortalCategoryTableBody
            onDelete={onDelete}
            onEdit={onEdit}
            role={role}
            categories={currentData()}
          />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default PortalCategoryTable;
