import axiosInstance from "../../interceptor/axiosInstance";
export const getMyOrders = async () => {
  const response = await axiosInstance.get("/customer/orders/");
  return response.data.data || [];
};

export const confirmRefundDelivery = async (data) => {
  const response = await axiosInstance.post("/delivery/create/", { ...data });
  return response.data.data || [];
};

export const getDeliveryByIdClient = async (id) => {
  const response = await axiosInstance.get(
    "/delivery/detail/?delivery_id=" + id
  );
  return response.data.data[0];
};
