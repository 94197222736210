import usePagination from "../../../../hooks/usePagination";
import TablePagination from "../../../../shared/components/TablePagination";
import UOMTableBody from "./UOMTableBody";
import UOMTableHeader from "./UOMTableHeader";

const UOMTable = ({ filter, uoms, onDelete, onEdit }) => {
  const { search } = filter;
  let filteredUoms = uoms;

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredUoms = uoms.filter((category) =>
      category.name.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredUoms, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <UOMTableHeader />
          <UOMTableBody
            onDelete={onDelete}
            onEdit={onEdit}
            uoms={currentData()}
          />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default UOMTable;
