import axiosInstance from "../../interceptor/axiosInstance";
const getCategories = async () => {
  const response = await axiosInstance.get("/all/category");
  return response.data.data || [];
};

const getCategoriesWithSubLevels = async () => {
  const response = await axiosInstance.get("/category/details/");
  return response.data.data || [];
};

const getCategorySubCat = async (id) => {
  const response = await axiosInstance.get("/category/details/?cat_id=" + id);
  return response.data.data || [];
};

const getCategoriesForUser = async () => {
  const response = await axiosInstance.get("/adminpanel/category/list/");
  return response.data.data || [];
};

export {
  getCategories,
  getCategoriesWithSubLevels,
  getCategorySubCat,
  getCategoriesForUser,
};
