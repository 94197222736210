import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import EnglishFlag from "../../svg/englishFlag";
import Arabic from "../../svg/arabic";
import { changeLanguage } from "../../store/setting/settingSlice";
const options = [
  {
    label: "ENG",
    value: "eng",
  },
  {
    label: "ARB",
    value: "arb",
  },
];

const customSingleValue = ({ data }) => (
  <div>{data.value === "eng" ? <EnglishFlag /> : <Arabic />}</div>
);

const customOption = (props) => {
  const { innerRef, innerProps, data } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <div>{data.value === "eng" ? <EnglishFlag /> : <Arabic />}</div>
      <div>{data.label}</div>
    </div>
  );
};

const LanguageSelector = () => {
  const { language } = useSelector((state) => state.setting);
  const dispatch = useDispatch();

  const handleLanguageChange = (value) => {
    const rootContainer = document.getElementById("root");

    // removing and adding classes
    if (value === "eng") {
      const menuContainer = document.getElementById("menu");
      menuContainer.classList.remove("mm-menu_offcanvas__right")
      rootContainer.classList.remove("mm-slideout-arb");
      rootContainer.classList.add("mm-slideout");
    } else {
      rootContainer.classList.remove("mm-slideout");
      rootContainer.classList.add("mm-slideout-arb");
    }

    // storing the value in the local storage and also in redux store.
    dispatch(changeLanguage(value));
  };

  return (
    <div
      style={{
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <ReactSelect
        isSearchable={false}
        isRtl={language === "arb"}
        components={{
          SingleValue: customSingleValue,
          Option: customOption,
        }}
        options={options}
        value={options.find((op) => op.value === language)}
        onChange={({ value }) => handleLanguageChange(value)}
        styles={{
          control: (provided) => ({
            ...provided,
            display: "flex",
          }),
          singleValue: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
          }),
        }}
      />
    </div>
  );
};

export default LanguageSelector;
