import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getVendorInvoiceBySaleId } from "../services/order";
import Preloader from "../../shared/components/Preloader";
import InvoiceSaleOrderItem from "../components/Invoices/InvoiceDetail/InvoiceSaleOrderItem";
import { getJournals } from "../services/common";
import { generateRandomString } from "../utils/generateRandomString";
import Modal from "../../shared/components/Modal";
import ReactSelect from "react-select";
import PaymentContainerVendor from "../../user/components/Checkout/PaymentContainerVendor";
import { createPaymentStripe } from "../services/stripePayment";
import CustomerDetail from "../components/CustomerDetail";

const classes = {
  DRAFT: "status-draft",
  SALE: "status-sale",
  DONE: "status-done",
  CANCELLED: "status-cancelled",
};

const VendorInvoiceDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState();
  const [openPayment, setOpenPayment] = useState(false);
  const [journals, setJournals] = useState([]);
  const [selectedJournal, setSelectedJouranl] = useState({
    value: "",
    label: "",
  });
  const [reference_no, setReferenceNo] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await getVendorInvoiceBySaleId(id);
      const jouranlsResponse = await getJournals();
      const journalOptions = jouranlsResponse.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setJournals(journalOptions);
      setInvoice(response);
      setLoading(false);
    })();
  }, [id]);

  if (loading) return <Preloader />;

  const { customer, order_state, delivery_address } = invoice;

  const handleOpenPayment = () => {
    setOpenPayment(true);
    const reference = generateRandomString(15);
    setReferenceNo(reference);
  };

  const handlePayment = async () => {
    const data = {
      reference_no: reference_no,
      amount_paid: invoice.total_with_tax,
      journal_id:
        selectedJournal.label === "Bank" ? null : selectedJournal.value,
      invoice_id: invoice.id,
    };
    const response = await createPaymentStripe(data);
    handleClear();
  };
  const handleClear = () => {
    setOpenPayment(false);
    setReferenceNo(null);
    setIsSubmit(false);
    setSelectedJouranl({ label: "", value: "" });
  };

  const handleConfirmPayment = (isConfirmed) => {
    if (isConfirmed) handlePayment();
  };
  return (
    <>
      <div className="order_detail_body p-3">
        <div className="main-title text-center">
          <h2>Invoice Detail</h2>
        </div>
        <CustomerDetail customer={customer} />

        <div className="application_statics mt-3 px-3">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="short_code_title ">
                Invoice# <span>{id}</span>
                <span className={classes[order_state]}>{order_state}</span>
              </h4>
            </div>
            <div>
              <button
                onClick={handleOpenPayment}
                className="btn btn-new btn-lg btn-thm mx-2"
              >
                Confirm Payment
              </button>
            </div>
          </div>
          <div className="account_user_deails dashboard_page mt-3">
            <div className="order_table table-responsive">
              <table className="table">
                <thead className="table-light">
                  <tr className="text-center">
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Tax</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.invoice_lines.map((saleOrderItem) => (
                    <InvoiceSaleOrderItem
                      key={saleOrderItem.id}
                      saleOrderItem={saleOrderItem}
                    />
                  ))}
                </tbody>
              </table>
              <hr />
              <div className="fix-order-table">
                <table className="table sub-table d-flex justify-content-end">
                  <tbody>
                    <tr>
                      <td className="sub-table-label">Sub Total:</td>
                      <td className="sub-table-value">
                        ${invoice.total_with_tax}
                      </td>
                    </tr>
                    <tr>
                      <td className="sub-table-label">Discount (VELZON15):</td>
                      <td className="sub-table-value">-$0</td>
                    </tr>
                    <tr>
                      <td className="sub-table-label">Shipping Charge:</td>
                      <td className="sub-table-value">$0</td>
                    </tr>
                    <tr>
                      <td className="sub-table-label">Estimated Tax:</td>
                      <td className="sub-table-value">$0</td>
                    </tr>
                    <tr>
                      <td className="sub-table-label total">Total (USD):</td>
                      <td className="sub-table-value total">
                        ${invoice.total_with_tax}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openPayment && (
        <Modal show={openPayment} onClose={handleClear} title="">
          <div className="custom-field">
            <h5 className="short_code_title">Reference Number</h5>
            <div className="form-group">
              <input
                value={reference_no}
                disabled
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="custom-field">
            <h5 className="short_code_title">Payment</h5>
            <input
              value={invoice.total_with_tax}
              disabled
              type="text"
              className="form-control"
            />
          </div>
          <div className="custom-field">
            <h5 className="short_code_title">Select Journal</h5>
            <div className="form-group">
              <ReactSelect
                options={journals}
                onChange={(value) => setSelectedJouranl(value)}
                value={selectedJournal}
              />
            </div>
          </div>

          {selectedJournal.label &&
            selectedJournal.label.toLocaleLowerCase() !== "bank" && (
              <PaymentContainerVendor
                onConfirmPayment={handleConfirmPayment}
                amount={invoice.total_with_tax}
                isSubmit={isSubmit}
              />
            )}
          <button
            style={{
              zIndex: "initial",
            }}
            className="btn btn-log btn-thm mt20"
            onClick={() => {
              selectedJournal.label === "Bank"
                ? handlePayment()
                : setIsSubmit(true);
            }}
          >
            Confirm Payment
          </button>
        </Modal>
      )}
    </>
  );
};
export default VendorInvoiceDetail;
