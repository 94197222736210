import React, { useState } from "react";
import ReactSelect from "react-select";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Cell,
} from "recharts";

const selectOptions = [
  { value: "month", label: "Month" },
  { value: "year", label: "Year" },
  { value: "week", label: "Week" },
];

const piData = [
  { name: "Income", value: 32, color: "#8884d8" },
  { name: "Taxes", value: 28, color: "#8dd1e1" },
  { name: "Fees", value: 40, color: "#82ca9d" },
];

const weekData = [
  { week: "1", Sale: 150 },
  { week: "2", Sale: 200 },
  { week: "3", Sale: 170 },
  { week: "4", Sale: 180 },
  { week: "5", Sale: 150 },
];

const monthdata = [
  { month: "Jan", Sale: 150 },
  { month: "Feb", Sale: 200 },
  { month: "Mar", Sale: 170 },
  { month: "Apr", Sale: 180 },
  { month: "May", Sale: 150 },
  { month: "Jun", Sale: 210 },
  { month: "Jul", Sale: 195 },
  { month: "Aug", Sale: 230 },
  { month: "Sep", Sale: 200 },
  { month: "Oct", Sale: 215 },
  { month: "Nov", Sale: 250 },
  { month: "Dec", Sale: 240 },
];

const yearData = [
  { year: "2001", Sale: 150 },
  { year: "2002", Sale: 200 },
  { year: "2003", Sale: 170 },
  { year: "2004", Sale: 180 },
  { year: "2005", Sale: 150 },
  { year: "2006", Sale: 210 },
  { year: "2007", Sale: 195 },
  { year: "2008", Sale: 230 },
  { year: "2009", Sale: 200 },
  { year: "2010", Sale: 215 },
  { year: "2011", Sale: 250 },
  { year: "2012", Sale: 240 },
];

function AdminDashboardChart() {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("month");
  const [chartData, setChartData] = useState({
    month: monthdata,
    year: yearData,
    week: weekData,
  });

  const handleDropdownChange = ({ value }) => {
    setSelectedTimeFrame(value);
  };

  const exportReport = () => {
    // const json = JSON.stringify(data);
    // const blob = new Blob([json], { type: "text/plain;charset=utf-8" });
    // saveAs(blob, "EarningStatistics.json");
  };

  return (
    <div className="row">
      <div className="col-xl-8">
        <div className="application_statics mb30">
          <div className="report_widget d-block d-sm-flex justify-content-center justify-content-sm-between">
            <h4 className="title pl30">Earning Statistics</h4>

            <ReactSelect
              options={selectOptions}
              onChange={handleDropdownChange}
              value={selectOptions.filter(
                (option) => option.value === selectedTimeFrame
              )}
            />
          </div>
        </div>
        <ResponsiveContainer style={{ marginTop: "20px" }} height={230}>
          <LineChart
            data={chartData[selectedTimeFrame]}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={selectedTimeFrame} />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="Sale"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="col-xl-4">
        <PieChart width={400} height={400}>
          <Pie
            data={piData}
            cx={200}
            cy={200}
            innerRadius={60}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            label={(entry) => `${entry.name}: ${entry.value}%`}
          >
            {piData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
    </div>
  );
}

export default AdminDashboardChart;
