import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import { getCategoriesForProduct } from "../services/categories";
import PortalCategoryFilter from "../../shared/components/Categories/CategoryFilter";
import PortalCategoryTable from "../../shared/components/Categories/Table/PortalCategoryTable";

const VendorCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
  });
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getCategoriesForProduct();
      setCategories(response);
      setLoading(false);
    })();
  }, []);

  if (loading) return <Preloader />;

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  if (loading) return <Preloader />;

  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Categories</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <PortalCategoryFilter
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
            <PortalCategoryTable categories={categories} filter={filter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorCategories;
