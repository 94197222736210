import { Outlet } from "react-router-dom";
import PortalHiddenSideBar from "./PortalHiddenSideBar";
import { useState } from "react";
import PortalHeader from "./PortalHeader";
import PortalFooter from "./PortalFooter";

const PortalLayOut = () => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div className="wrapper">
      <PortalHeader onToggle={() => setIsShow(!isShow)} />
      <div className="dashboard_content_wrapper">
        <div
          className={`dashboard dashboard_wrapper pr30 pr0-md ${
            isShow ? "dsh_board_sidebar_hidden" : ""
          }`}
        >
          <PortalHiddenSideBar />
        </div>
        <div className="dashboard__main pl0-md">
          <Outlet />
          <PortalFooter />
        </div>
      </div>
    </div>
  );
};

export default PortalLayOut;
