import axiosInstance from "../../interceptor/axiosInstance";

export const getDashboardOrderStat = async () => {
  const response = await axiosInstance.get("/dashboard/order/stat");
  return response.data.data;
};
export const getRecentOrders = async () => {
  const response = await axiosInstance.get("/vendor/recent/orders/");
  return response.data.data || [];
};

export const handleUploadFile = async (data) => {
  const response = await axiosInstance.post("/upload/excel/", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
