const DeliveryItem = ({ deliveryItem }) => {
  const { product_id, quantity, is_active } = deliveryItem;
  return (
    <tr>
      <td className="body-color">{product_id}</td>
      <td className="body-color">{is_active ? "Active" : "InActive"}</td>
      <td className="body-color">{quantity}</td>
    </tr>
  );
};

export default DeliveryItem;
