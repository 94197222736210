import { useSelector } from "react-redux";
import ReviewStars from "./ReviewStars";
import { productDetailPage } from "../../constants/localization";
import ReviewItem from "./ReviewItem";

const stars = [5, 4, 3, 2, 1];
const ProductReviewsRating = ({ reviews = [] }) => {
  const { language } = useSelector((state) => state.setting);
  const getRating = () => {
    const allRatings = reviews.reduce((total, item) => total + item.rating, 0);
    return Math.floor(allRatings / reviews.length);
  };

  const getRatingPercentage = (rating) => {
    if (reviews.length === 0) return 0;
    const count = reviews.filter((review) => review.rating === rating).length;
    return Math.floor((count / reviews.length) * 100);
  };

  return (
    <div className="accordion" id="vendor_accordin">
      <div className="card">
        <div className="card-header" id="headingSeven">
          <h2 className="mb-0">
            <button
              className="btn btn-link collapsed text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              {productDetailPage.customerReviews[language]}
            </button>
          </h2>
        </div>
        <div
          id="collapseSeven"
          className="collapse"
          aria-labelledby="headingSeven"
          data-parent="#accordionExample"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-lg-10 col-xl-7">
                {reviews.length > 0 ? (
                  <div className="review_average mb30">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <div className="title">{getRating()}</div>
                      </div>
                      <ReviewStars
                        rating={getRating()}
                        reviewCount={reviews.length}
                      />
                    </div>
                  </div>
                ) : (
                  <h4>{productDetailPage.noReviewsYet[language]}</h4>
                )}

                {stars.map((star, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center single_line_review pr30 pr0-lg mb10"
                  >
                    <div className="me-1">{star} star</div>
                    <div className="progress-bar mx-3">
                      <div className="progress-bar__bg"></div>
                      <div
                        style={{ width: `${getRatingPercentage(star)}%` }}
                        className="progress-bar__bar"
                      ></div>
                    </div>
                    <div className="heading-color">
                      {getRatingPercentage(star)}%
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-12">
                <div className="product_single_content mb30">
                  {reviews.map((review, index) => (
                    <ReviewItem
                      index={index}
                      length={reviews.length}
                      key={index}
                      reviewItem={review}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductReviewsRating;
