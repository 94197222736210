import { URL_PATH } from "../../../../shared/constants/common";

const VendorTableRow = ({ vendorItem, index, onEdit }) => {
  const {
    id,
    name,
    company,
    shop_name,
    shop_img,
    tax_id,
    phone,
    is_active,
    user: { name: userName },
    address: { address, country, street, zip_code, city },
    brand: { name: brandName },
    bank_id: { name: bankName, iban, account_number },
  } = vendorItem;

  return (
    <tr>
      <th scope="row">#{id}</th>
      <td>{name}</td>
      <td>{company}</td>
      <td>{shop_name}</td>
      <td>
        <img
          src={URL_PATH + shop_img}
          style={{
            width: "50px",
            height: "50px",
          }}
        />
      </td>
      <td>{brandName}</td>
      <td>{bankName}</td>
      <td>{iban}</td>
      <td>{account_number}</td>
      <td>{tax_id}</td>
      <td>{phone}</td>
      <td>{name}</td>
      <td>{is_active ? "Active" : "Not Active"}</td>
      <td>{userName}</td>
      <td>{address}</td>
      <td>{country}</td>
      <td>{street}</td>
      <td>{zip_code}</td>
      <td>{city}</td>

      <td className="d-flex gap-2">
        <button onClick={() => onEdit(id)} className="btn btn-custom">
          <i className="fa-regular fa-eye"></i>
        </button>
      </td>
    </tr>
  );
};

export default VendorTableRow;
