import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { createPaymentIntent } from "../../services/checkout";

const StripePaymentVendor = ({ amount, onConfirmPayment, isSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    (async () => {
      if (isSubmit) await handleSubmit();
    })();
  }, [isSubmit]);

  const handleSubmit = async () => {
    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    // as Stripe accept in the form of cents so multiply it with 100 make 1 => 1$
    const response = await createPaymentIntent({
      amount: amount * 100,
      currency: "usd",
    });

    const { clientSecret } = response;
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://example.com",
      },
      redirect: "if_required",
      clientSecret,
    });

    if (result.paymentIntent.status === "succeeded") {
      onConfirmPayment(true);
    }
    if (result.error) {
      onConfirmPayment(false);
    } else {
      onConfirmPayment(false);
    }
  };
  return (
    <>
      <PaymentElement />
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

export default StripePaymentVendor;
