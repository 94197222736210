import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MobileNavCategoryItem from "./MobileNavCategoryItem";
import LanguageSelector from "./LanguageSelector";
import {
  headerMiddleComponent,
  loginUserMenu,
  menusText,
} from "../../constants/localization";
import useDynamicPanels from "../../hooks/useDynamicPanels";
import useMobileNavigation from "../../hooks/useMobileNavigation";
import useResponsiveQuery from "../../hooks/useResponsiveQuery";
import { useState } from "react";
import { getLoginUserName } from "../../utils/common";
import { onUserLogOut } from "../../store/userAuthSlice/userAuthSlice";

const MobileNavBar = () => {
  const { categoriesWithLevel } = useSelector((state) => state.category);
  const { cartProducts } = useSelector((state) => state.cart);
  const { language } = useSelector((state) => state.setting);
  const { user } = useSelector((state) => state.userAuth);
  const [isShowUserMenus, setIsShowUserMenus] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navContainerRef = useDynamicPanels(categoriesWithLevel, language);
  useMobileNavigation();
  useResponsiveQuery();
  const handleShowCartSideBar = () => {
    document.body.classList.add("cart-hidden-sidebar-content");
  };

  const handleShowSignIn = () =>
    document.body.classList.add("signin-hidden-sidebar-content");

  const handleHideArb = () => {
    if (language === "arb") {
      const menuElement = document.getElementById("menu");
      menuElement.classList.remove("mm-menu_opened");
      const htmlElement = document.documentElement;
      htmlElement.classList.remove(
        "mm-wrapper_opened",
        "mm-wrapper_blocking",
        "mm-wrapper_background",
        "mm-wrapper_opening"
      );
    }
  };

  const handleShowMenu = () => {
    const rootContainer = document.getElementById("menu");
    rootContainer.classList.add("mm-menu_opened");
    const htmlElement = document.documentElement;
    htmlElement.classList.add(
      "mm-wrapper_opened",
      "mm-wrapper_blocking",
      "mm-wrapper_background",
      "mm-wrapper_opening"
    );
  };

  const handleLogout = () => {
    dispatch(onUserLogOut());
    navigate("/");
  };

  return (
    <div id="page" className="stylehome1">
      <div className="mobile-menu">
        <div className="header stylehome1">
          <div className="menu_and_widgets">
            <div
              className={`mobile_menu_bar ${
                language === "eng" ? "float-start" : "float-end"
              }`}
            >
              <a
                onClick={handleShowMenu}
                className={`menubar ${
                  language === "eng" ? "" : "menubar__right"
                }`}
                href="#menu"
              >
                <span></span>
              </a>
              <Link
                to="/"
                className={`mobile_logo ${
                  language === "eng" ? "mobile_logo__eng " : "mobile_logo__arb"
                }`}
              >
                <span className="text-thm">
                  {headerMiddleComponent.appLogoText[language]}
                </span>
              </Link>
            </div>
            <div
              className={`mobile_menu_widget_icons ${
                language === "eng"
                  ? "mobile_menu_widget_icons__eng"
                  : "mobile_menu_widget_icons__arb"
              }`}
            >
              <ul className="cart mt15">
                {user ? (
                  <li
                    onClick={() =>
                      setIsShowUserMenus((prevState) => !prevState)
                    }
                    className="list-inline-item"
                  >
                    <div className="header_top_iconbox signin-filter-btn">
                      <div className="d-flex align-items-center">
                        <div
                          className={`icon ${
                            language === "eng" ? "icon__right" : "icon__left"
                          }`}
                        >
                          <span>{getLoginUserName(user.name)}</span>
                        </div>
                      </div>
                    </div>
                    {isShowUserMenus && (
                      <div className="login__user__menus">
                        <Link className="user__menu__item" to="/my-orders">
                          {loginUserMenu.myOrders[language]}
                        </Link>
                        <Link className="user__menu__item" to="/my-reviews">
                          {loginUserMenu.myReviews[language]}
                        </Link>
                        <Link className="user__menu__item" to="/my-deliveries">
                          {loginUserMenu.myDeliveries[language]}
                        </Link>
                        <span
                          onClick={handleLogout}
                          className="user__menu__item"
                        >
                          {loginUserMenu.logout[language]}
                        </span>
                      </div>
                    )}
                  </li>
                ) : (
                  <li className="list-inline-item">
                    <a className="header_top_iconbox signin-filter-btn">
                      <div
                        onClick={handleShowSignIn}
                        className="d-block d-md-flex align-items-center"
                      >
                        <div
                          className={`icon ${
                            language === "eng" ? "icon__right" : "icon__left"
                          }`}
                        >
                          <span className="flaticon-profile" />
                        </div>
                      </div>
                    </a>
                  </li>
                )}
                <li className="language-container">
                  <LanguageSelector />
                </li>
                <li
                  onClick={handleShowCartSideBar}
                  className="list-inline-item"
                >
                  <span className="cart_btn cart-filter-btn">
                    <span className="icon">
                      <img src="/images/icons/flaticon-shopping-cart-white.svg" />
                    </span>
                    <span className="badge bgc-thm">{cartProducts.length}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="mobile_menu_search_widget">
            <div className="header_middle_advnc_search">
              <div className="container search_form_wrapper">
                <div className="row">
                  <div>
                    <div className="top-search text-start">
                      <form className="form-search">
                        <div className="box-search">
                          <input
                            className="form_control"
                            type="text"
                            name="search"
                            placeholder={
                              headerMiddleComponent.searchProducts[language]
                            }
                          />
                          <div className="search-suggestions text-start">
                            <div className="box-suggestions">
                              <ul>
                                <li>
                                  <div className="thumb">
                                    <img
                                      src="/images/listing/sf1.png"
                                      alt="sf1.png"
                                    />
                                  </div>
                                  <div className="info-product">
                                    <div className="item_title">
                                      Sony DJ Headphones 4334205465, Black,
                                      Standard
                                    </div>
                                    <div className="price">
                                      <span className="sale">$32.50</span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="thumb">
                                    <img
                                      src="/images/listing/sf2.png"
                                      alt="sf2.png"
                                    />
                                  </div>
                                  <div className="info-product">
                                    <div className="item_title">
                                      Sony E-Mount Full Frame FE 24-70mm f/2.8
                                      GM II G Master
                                    </div>
                                    <div className="price">
                                      <span className="sale">$32.50</span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="thumb">
                                    <img
                                      src="/images/listing/sf3.png"
                                      alt="sf3.png"
                                    />
                                  </div>
                                  <div className="info-product">
                                    <div className="item_title">
                                      TV 55" 4-Series 4K UHD smart TV
                                    </div>
                                    <div className="price">
                                      <span className="sale">$32.50</span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="thumb">
                                    <img
                                      src="/images/listing/sf4.png"
                                      alt="sf4.png"
                                    />
                                  </div>
                                  <div className="info-product">
                                    <div className="item_title">
                                      Hugolog Baby Monitor, 2K Security Camera,
                                      PT Cameras for
                                    </div>
                                    <div className="price">
                                      <span className="sale">$32.50</span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="thumb">
                                    <img
                                      src="/images/listing/sf5.png"
                                      alt="sf5.png"
                                    />
                                  </div>
                                  <div className="info-product">
                                    <div className="item_title">
                                      Apple iPhone Retina 6s Plus 64GB
                                    </div>
                                    <div className="price">
                                      <span className="sale">$32.50</span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div>
                    <div className="advscrh_frm_btn">
                      <button type="submit" className="btn search-btn">
                        <span className="flaticon-search"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="posr">
            <div onClick={handleHideArb} className="mobile_menu_close_btn">
              <span className="flaticon-close"></span>
            </div>
          </div>
        </div>
      </div>
      <nav ref={navContainerRef} id="menu" className="stylehome1">
        <ul>
          <li className="mm-listitem">
            <a href="/">{menusText.home[language]}</a>
          </li>
          <li className="mm-listitem">
            <a href="/shop">{menusText.shop[language]}</a>
          </li>
          <li className="title my-3 bb1 pl20 fz20 fw500 pb-3">
            {menusText.categories[language]}
          </li>
          {categoriesWithLevel.map((category, index) => (
            <MobileNavCategoryItem
              key={index}
              categoryItem={category}
              index={index}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default MobileNavBar;
