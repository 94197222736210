import { useSelector } from "react-redux";

const ShopCategory = () => {
  const { categories } = useSelector((state) => state.category);

  return (
    <section className="p0 bb1 overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="custom_shop_category_nav_list_menu">
              <ul className="mb0 d-flex">
                {categories.map((category, index) => (
                  <li key={index}>
                    <a>{category.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopCategory;
