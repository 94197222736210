import React from "react";
import Slider from "react-slick";
import ShopBannerItem from "./ShopBannerItem";
const ShopBannerCarousel = ({ banners = [] }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <>
      {banners.length === 1 ? (
        <ShopBannerItem banner={banners[0]} />
      ) : (
        <Slider {...settings}>
          {banners.map((banner, index) => (
            <ShopBannerItem banner={banner} key={index} />
          ))}
        </Slider>
      )}
    </>
  );
};

export default ShopBannerCarousel;
