import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import Modal from "../../shared/components/Modal";
import UOMFilter from "../components/uoms/UOMFilter";
import UOMTable from "../components/uoms/Table/UOMTable";
import UOMForm from "../forms/UOMForm";
import { deleteUom, getAllUomsListAdmin } from "../services/uom";

const AdminUOMs = () => {
  const [UOMs, setUOMs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editId, setEditID] = useState(-1);
  const [deleteId, setDeleteID] = useState(-1);
  const [mode, setMode] = useState("none");

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
  });
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getAllUomsListAdmin();
      setUOMs(response);
      setLoading(false);
    })();
  }, []);

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  const handleEdit = (id) => {
    setMode("UPDATE");
    setDeleteID(-1);
    setEditID(id);
    openModal();
  };

  const handleDelete = (id) => {
    setMode("NONE");
    setEditID(-1);
    setDeleteID(id);
    openModal();
  };

  const handleCreate = () => {
    setMode("CREATE");
    setEditID(-1);
    setDeleteID(-1);
    openModal();
  };

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const resetState = () => {
    setEditID(-1);
    setDeleteID(-1);
    closeModal();
  };

  const confirmDelete = async () => {
    //call api to Delete
    const response = await deleteUom(deleteId);
    if (response.flag) {
      setUOMs((prevState) => prevState.filter((cat) => cat.id !== deleteId));
    }
    resetState();
  };

  const handleSubmit = (data) => {
    if (editId > -1) {
      setUOMs((prevState) =>
        prevState.map((item) => (item.id === editId ? { ...data } : item))
      );
    } else {
      setUOMs((prevState) => [...prevState, { ...data }]);
    }
    resetState();
  };

  const getUOMName = (id) => UOMs.find((cat) => cat.id === id);
  if (loading) return <Preloader />;

  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>UOMs</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <UOMFilter
              onClick={handleCreate}
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
            <UOMTable
              onEdit={handleEdit}
              onDelete={handleDelete}
              uoms={UOMs}
              filter={filter}
            />
          </div>
        </div>
      </div>
      <Modal show={modalOpen} onClose={closeModal} title="">
        {(mode == "CREATE" || mode == "UPDATE") && (
          <UOMForm
            id={editId}
            uom={UOMs.find((UOM) => UOM.id === editId)}
            isEdit={mode === "UPDATE" ? true : false}
            onSubmit={handleSubmit}
          />
        )}
        {deleteId > -1 && (
          <>
            <h6>
              Are you sure you want to delete
              <span style={{ fontWeight: "bold", color: "red" }}>
                {" " + getUOMName(deleteId).name}
              </span>
              ? This action cannot be undone.
            </h6>
            <div className="d-flex justify-content-end gap-2">
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirmDelete}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AdminUOMs;
