import { useEffect, useState } from "react";
import { createBrand, updateBrand } from "../services/brand";

const BrandForm = ({ onSubmit, isEdit = false, id, brand = null }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    if (brand && isEdit) {
      const { name } = brand;
      setName(name);
    }
  }, [id, brand, isEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEdit && id) {
      const response = await updateBrand(id, { name });
      onSubmit(response);
    } else {
      const response = await createBrand({ name });
      onSubmit(response);
    }
  };

  return (
    <div className="login_form">
      <form onSubmit={handleSubmit}>
        <div className="mb-2 mr-sm-2">
          <label className="form-label">Brand Name</label>
          <input
            value={name}
            name="name"
            type="text"
            className="form-control"
            placeholder="Brand Name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-log btn-thm mt20">
          {isEdit ? "Update" : "Create"}
        </button>
      </form>
    </div>
  );
};

export default BrandForm;
