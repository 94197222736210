import { useEffect, useState } from "react";
import { URL_PATH } from "../../../shared/constants/common";
import { useSelector } from "react-redux";
import { deliveryRefundForm } from "../../constants/localization";

const DeliveryRefundForm = ({ onConfirm, isRefund, refund, onClose }) => {
  const [refundForm, setRefundForm] = useState({
    reason: "",
    images: [],
  });

  const { language } = useSelector((state) => state.setting);

  useEffect(() => {
    if (isRefund) {
      setRefundForm(refund);
    }
  }, [isRefund, refund]);

  const handleAttachments = (event) => {
    const files = Array.from(event.target.files);
    Promise.all(
      files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            resolve(e.target.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })
    )
      .then((base64Files) => {
        setRefundForm((prevState) => ({
          ...prevState,
          images: base64Files,
        }));
      })
      .catch((error) => {
        console.error("Error reading files: ", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isRefund) {
      onClose();
    } else onConfirm(refundForm);
  };

  const handleImageDelete = (index) => {
    let allImages = [...refundForm.images];
    allImages.splice(index, 1);
    setRefundForm((prevState) => ({
      ...prevState,
      images: allImages,
    }));
  };
  return (
    <div className="login_form p-3">
      <form onSubmit={handleSubmit}>
        <div className="mb-2 mr-sm-2">
          <label className="form-label">
            {deliveryRefundForm.description[language]}
          </label>
          <textarea
            disabled={isRefund}
            value={refundForm.reason}
            onChange={(e) =>
              setRefundForm((prevState) => ({
                ...prevState,
                reason: e.target.value,
              }))
            }
            className="form-control"
            placeholder={deliveryRefundForm.description[language]}
            rows={10}
            style={{
              resize: "none",
            }}
          />
        </div>
        {!isRefund && (
          <div className="form-group">
            <input
              multiple
              onChange={handleAttachments}
              type="file"
              className="form-control"
              placeholder="Upload Image"
            />
          </div>
        )}
        {refundForm.images.map((image, index) => (
          <div key={index} className="image-container m-2">
            <img
              src={isRefund ? URL_PATH + image.images : image}
              alt="Preview Image"
            />

            {!isRefund && (
              <div
                onClick={() => handleImageDelete(index)}
                className="close-icon"
              >
                &times;
              </div>
            )}
          </div>
        ))}
        <button type="submit" className="btn btn-log btn-thm mt20">
          {deliveryRefundForm.confirm[language]}
        </button>
      </form>
    </div>
  );
};

export default DeliveryRefundForm;
