import { Link } from "react-router-dom";
import ReactSelect from "react-select";

const statusOptions = [
  { value: "All", label: "All" },
  { value: "Active", label: "Active" },
  { value: "InActive", label: "InActive" },
];

const ProductFilter = ({ categories, onChangeFilter, filter }) => {
  const options = [
    {
      value: "All",
      label: "All",
    },
    ...categories.map((category) => ({
      value: category.id,
      label: category.name_eng,
    })),
  ];
  const { status, selectedCategory } = filter;
  const statusValue = statusOptions.find((option) => option.value === status);
  const selectedCategoryOption = options.find(
    (option) => option.value === selectedCategory
  );

  return (
    <div className="row d-block d-sm-flex justify-content-center justify-content-sm-between">
      <div className="col-sm-6 col-lg-3">
        <div className="dashboard_page header_search_widget mb30 mb15-520">
          <div className="input-group">
            <input
              value={filter.product}
              onChange={(e) => onChangeFilter("product", e.target.value)}
              type="text"
              className="form-control"
              placeholder="Search product"
              aria-label="Recipient's username"
            />
            <div className="input-group-append">
              <button className="btn" type="button">
                <span className="fa fa-search"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-lg-3">
        <div className="dashboard_page sidebar_location_filter mb30 mb5-520">
          <ReactSelect
            onChange={({ value: newValue }) =>
              onChangeFilter("selectedCategory", newValue)
            }
            value={selectedCategoryOption}
            options={options}
          />
        </div>
      </div>
      <div className="col-sm-6 col-lg-3">
        <div className="dashboard_page sidebar_location_filter mb30 mb5-520">
          <ReactSelect
            onChange={({ value: newValue }) =>
              onChangeFilter("status", newValue)
            }
            value={statusValue}
            options={statusOptions}
          />
        </div>
      </div>
      <div className="col-sm-6 col-lg-3">
        <div className="dashboard_page_add_listing text-center text-lg-end mb30 mb15-520 d-grid">
          <Link
            to="/vendor/products/create"
            className="btn btn-new btn-lg btn-thm"
          >
            Add New Products
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;
