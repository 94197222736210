import axiosInstance from "../../interceptor/axiosInstance";
export const getAllVendors = async () => {
  const response = await axiosInstance.get("/vendor/shops/list/");
  return response.data.data || [];
};

export const getVendorShopProductsById = async (id, offset, limit) => {
  const url = `/vendor/products_by_id/?ven_id=${id}${
    limit ? `&limit=${limit}` : ""
  }${offset ? `&offset=${offset}` : ""}`;
  const response = await axiosInstance.get(url);
  return {
    results: response.data.results || [],
    count: response.data.count || 0,
  };
};
