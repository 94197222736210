import { useEffect, useState } from "react";
import { getPolicyAdmin, updatePolicy } from "../services/policy";
import Preloader from "../../shared/components/Preloader";

const Policy = () => {
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await getPolicyAdmin();
      setPolicy(response);
      setLoading(false);
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await updatePolicy(policy.id ? policy.id : null, {
      policy: policy.policy,
    });
  };

  if (loading) return <Preloader />;
  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Policy</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <section className="our-log-reg bgc-f5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-xl-5 col-xxl-4 m-auto">
                    <div className="log_reg_form mt70-992">
                      <div className="login_form">
                        <form onSubmit={handleSubmit}>
                          <div className="mb-2 mr-sm-2">
                            <label className="form-label">Policy Text</label>
                            <textarea
                              value={policy.policy}
                              type="text"
                              className="form-control"
                              style={{ height: "150px", resize: "none" }}
                              placeholder={
                                policy.id ? "Update Policy" : "Create Policy"
                              }
                              onChange={(e) =>
                                setPolicy((prevState) => ({
                                  ...prevState,
                                  policy: e.target.value,
                                }))
                              }
                            />
                          </div>

                          <button
                            type="submit"
                            className="btn btn-log btn-thm mt20"
                          >
                            Update
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
