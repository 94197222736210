const baseUrl = process.env.REACT_APP_BASE_URL_LIVE;

const BannerTableBody = ({ banners, onEdit, onDelete }) => {
  return (
    <tbody style={{ width: "100%" }}>
      {banners.map(({ id, text_eng, text_arb, image, category }, index) => (
        <tr key={index}>
          <td scope="row">#{id}</td>
          <td
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100px",
              whiteSpace: "nowrap",
            }}
            scope="row"
          >
            {category.name_eng}
          </td>
          <td>
            <img
              src={baseUrl + image}
              alt={text_eng}
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "10px",
                aspectRatio: 3 / 4,
              }}
            />
          </td>
          <td
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100px",
              whiteSpace: "nowrap",
            }}
          >
            {text_eng}
          </td>
          <td
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100px",
              whiteSpace: "nowrap",
            }}
          >
            {text_arb}
          </td>
          <td className="editing_list align-middle">
            <ul>
              <li className="list-inline-item mb-1">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => onEdit(id)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  data-bs-original-title="View"
                  aria-label="View"
                >
                  <span className="flaticon-pencil"></span>
                </span>
              </li>
              <li className="list-inline-item mb-1">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => onDelete(id)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                  data-bs-original-title="Edit"
                  aria-label="Edit"
                >
                  <span className="flaticon-delete"></span>
                </span>
              </li>
            </ul>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default BannerTableBody;
