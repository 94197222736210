const TagTableBody = ({ tags, onEdit, onDelete }) => {
  return (
    <tbody style={{ width: "100%" }}>
      {tags.map(({ id, name, is_active }, index) => (
        <tr key={index}>
          <th scope="row">#{id}</th>
          <td>{is_active ? "ACTIVE" : "INACTIVE"}</td>
          <td>{name}</td>
          <td className="editing_list align-middle">
            <ul>
              <li className="list-inline-item mb-1">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => onEdit(id)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  data-bs-original-title="View"
                  aria-label="View"
                >
                  <span className="flaticon-pencil"></span>
                </span>
              </li>
              <li className="list-inline-item mb-1">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => onDelete(id)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                  data-bs-original-title="Edit"
                  aria-label="Edit"
                >
                  <span className="flaticon-delete"></span>
                </span>
              </li>
            </ul>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TagTableBody;
