import axiosInstance from "../../interceptor/axiosInstance";

export const getAllBanners = async () => {
  const response = await axiosInstance.get("adminpanel/banner/list/");
  return response.data.data || [];
};

export const createBanner = async (data) => {
  const response = await axiosInstance.post("adminpanel/banner/create/", {
    ...data,
  });
  return response.data.data;
};

export const updateBanner = async (id, data) => {
  const response = await axiosInstance.patch(
    "adminpanel/banner/update/?id=" + id,
    {
      ...data,
    }
  );
  return response.data.data;
};

export const deleteBanner = async (id) => {
  const response = await axiosInstance.delete(
    "/adminpanel/banner/delete/?cat_id=" + id
  );
  return response.data;
};
