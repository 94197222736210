import TableRow from "./TableRow";

const TableBody = ({ products, onEdit, onDelete }) => {
  return (
    <tbody>
      {products.map((productItem, index) => (
        <TableRow
          key={index}
          productItem={productItem}
          onEdit={onEdit}
          onDelete={onDelete}
          index={index}
        />
      ))}
    </tbody>
  );
};

export default TableBody;
