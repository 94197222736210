import { URL_PATH } from "../../../shared/constants/common";
import UserIcon from "../../svg/userIcon";
import RatingStar from "./RatingStar";

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const ReviewItem = ({ reviewItem, index, length }) => {
  const { rating, review_text, userprofile, created_at, images } = reviewItem;
  return (
    <>
      <div className="d-flex justify-content-between p-2">
        <div className="d-flex gap-2 align-items-center ">
          <div
            className="p-2"
            style={{ backgroundColor: "lightcyan", borderRadius: "100%" }}
          >
            <UserIcon width="3em" height="3em" />
          </div>
          <div>
            {userprofile.name}
            <RatingStar rating={rating} />
          </div>
        </div>
        <h5>{formatDate(created_at)}</h5>
      </div>

      <div className="mt-2">
        <h5 className="sub_title">{review_text}</h5>
        <div key={index} className="d-flex flex-wrap gap-1">
          {images.map((item, index) => (
            <img
              style={{
                borderRadius: "10px",
                height: "50px",
                width: "50px",
              }}
              src={URL_PATH + item.images}
              alt="review-img"
            />
          ))}
        </div>
      </div>
      {index - 1 !== length && <hr />}
    </>
  );
};

export default ReviewItem;
