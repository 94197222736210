import Pagination from "./Pagination";
import ShopFilters from "./ShopFilters";
import ShopProductListing from "./ShopProductListing";

const ProductListingWithSideBar = ({
  products,
  currentPage,
  limit,
  onChangeLimit,
  onChangePage,
  totalItems,
  totalPages,
  onFilter,
  productTypes,
  customRange,
  filters,
  onRemoveFilter,
  isFiltered,
}) => {
  return (
    <div className="row mt60">
      <ShopFilters
        onFilter={onFilter}
        productTypes={productTypes}
        customRange={customRange}
        filters={filters}
        onRemoveFilter={onRemoveFilter}
      />

      <ShopProductListing
        currentPage={currentPage}
        products={products}
        limit={limit}
        totalItems={totalItems}
        onChangeLimit={onChangeLimit}
        isFiltered={isFiltered}
        onFilter={onFilter}
        productTypes={productTypes}
        customRange={customRange}
        filters={filters}
        onRemoveFilter={onRemoveFilter}
      />
      <Pagination
        itemsPerPage={limit}
        currentPage={currentPage}
        onChangePage={onChangePage}
        totalItems={totalItems}
        totalPages={totalPages}
      />
    </div>
  );
};

export default ProductListingWithSideBar;
