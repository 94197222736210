import { vendorListingComponent } from "../../constants/localization";
import VendorCard from "./VendorCard";
import { useSelector } from "react-redux";
const VendorListing = ({ vendors }) => {
  const { language } = useSelector((state) => state.setting);
  return (
    vendors.length > 0 && (
      <section className="top-category">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <div className="main-title">
                  <h2>{vendorListingComponent.shopByVendors[language]}</h2>
                </div>
                <div className="main-title mb-5">
                  <a className="title_more_btn mt10">
                    {vendorListingComponent.viewAllVendors[language]}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row ovh">
            {vendors.map((item, index) => (
              <VendorCard key={index} vendorItem={item} />
            ))}
          </div>
        </div>
      </section>
    )
  );
};
export default VendorListing;
