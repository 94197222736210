import { Link } from "react-router-dom";

const InvoiceTableRow = ({ invoiceItem }) => {
  const {
    id,
    name,
    customer,
    vendor_id,
    state,
    invoice_date,
    tracking_reference,
    delivery_address,
    total_with_tax,
    total_without_tax,
    product_id,
    variant_id,
  } = invoiceItem;

  const { name: customerName } = customer;
  const { name: vendorName } = vendor_id;
  const { address } = delivery_address;

  return (
    <tr>
      <th scope="row">#{id}</th>
      <td>{name}</td>
      <td>{address}</td>
      <td>{customerName}</td>
      <td>{vendorName}</td>
      <td className="status">
        <span>{state}</span>
      </td>
      <td>{invoice_date}</td>
      <td>{tracking_reference}</td>
      <td>{total_with_tax}</td>
      <td>{total_without_tax}</td>
    </tr>
  );
};

export default InvoiceTableRow;
