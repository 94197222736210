import { useState } from "react";

const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const currentData = () => {
    const endIndex = currentPage * itemsPerPage;
    const startIndex = endIndex - itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const jump = (page) => {
    const pageNumber = Math.max(1, page);
    setCurrentPage((prev) => Math.min(pageNumber, totalPages));
  };

  const next = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  return { next, prev, jump, currentData, currentPage, totalPages };
};

export default usePagination;
