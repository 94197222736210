import { useSelector } from "react-redux";
import CategoryMenuItem from "./CategoryMenuItem";
import { useEffect, useRef } from "react";

const CategoriesMenuDropDown = () => {
  const { categoriesWithLevel } = useSelector((state) => state.category);
  const { language } = useSelector((state) => state.setting);

  const menuRef = useRef(null);

  useEffect(() => {
    const menuElement = menuRef.current;

    if (menuElement) {
      const menuItems = menuElement.querySelectorAll("li");
      if (language === "eng") {
        menuElement.classList.remove("menu-right");
      } else if (language === "arb") {
        menuElement.classList.add("menu-right");
      }
      menuItems.forEach((menuItem) => {

        if (language === "eng") {
          menuItem.classList.remove("mega-menu__right");
          menuItem.classList.add("mega-menu__left");

        } else if (language === "arb") {
          menuItem.classList.remove("mega-menu__left");
          menuItem.classList.add("mega-menu__right");

        }
      });
    }
  }, [language]);
  return (
    <ul className="menu" ref={menuRef}>
      {categoriesWithLevel.map((item, index) => (
        <CategoryMenuItem key={index} categoryItem={item} />
      ))}
    </ul>
  );
};

export default CategoriesMenuDropDown;
