import usePagination from "../../../hooks/usePagination";
import TablePagination from "../../TablePagination";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

const productStatus = {
  Active: true,
  InActive: false,
};

const VendorProductTable = ({ filter, products, onEdit, onDelete }) => {
  const { selectedCategory, status, product } = filter;
  let filteredProducts = products;


  if (selectedCategory && selectedCategory !== "All") {
    filteredProducts = filteredProducts.filter(
      (pr) => pr.category.id === selectedCategory
    );
  }
  if (status && status !== "All") {
    const boolValue = productStatus[status];
    filteredProducts = filteredProducts.filter(
      (pr) => pr.is_active === boolValue
    );
  }

  if (product) {
    const normalizedSearchText = product.toLowerCase();
    filteredProducts = filteredProducts.filter((order) =>
      order.name_eng.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredProducts, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <TableHeader />
          <TableBody
            products={currentData()}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default VendorProductTable;
