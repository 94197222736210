import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReactSelect from "react-select";
import {
  createVendorProductOrVariant,
  updateProductOrVariant,
} from "../services/vendorProducts";
import { useNavigate } from "react-router-dom";
import PCAListing from "./PCAListing";
import { toast } from "react-toastify";
import { URL_PATH } from "../../shared/constants/common";

const selectionOptions = [
  {
    label: "Physical",
    value: "physical",
  },
  {
    label: "Digital",
    value: "digital",
  },
  {
    label: "Both",
    value: "both",
  },
];

const ProductForm = ({
  productTitle = "",
  product,
  isEdit = false,
  id = null,
  categories,
  tags,
  brands,
  uoms,
  method = "",
  btnTitle = "",
  onChangeCategory,
  allPCAs = [],
  onFetchPCA,
  isFetchedPCA,
  onAddVariant,
  onUpdateVariant,
  policyText = "",
}) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const categoryOptions = categories.map((cat) => ({
    value: cat.id,
    label: cat.name_eng,
  }));

  const tagsOptions = tags.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));

  const brandsOptions = brands.map((brand) => ({
    value: brand.id,
    label: brand.name,
  }));

  const uomsOptions = uoms.map((brand) => ({
    value: brand.id,
    label: brand.name,
  }));
  const [productForm, setProductForm] = useState({
    images: [],
    name_eng: "",
    name_arb: "",
    sale_price: "",
    current_qty: 0,
    forcast_qty: 0,
    overview_eng: "",
    overview_arb: "",
    details_specifications_eng: "",
    details_specifications_arb: "",
    sku: "",
    model_number: "",
    video: "",
    cost_price: "",
    main_image: "",
    bar_code: "",
    tax: "",
    weight: "",
    volume: "",
    category: {
      label: "",
      value: "",
    },
    brand: {
      label: "",
      value: "",
    },
    uom: { label: "", value: "" },
    product_tags: [],
    pcas: [],
    attachments: [],
    discount: 0,
    selection: { label: "", value: "" },
    delivery_fee: 0,
    policy_eng: policyText,
    policy_arb: "",
  });

  useEffect(() => {
    if (!isEdit && isFetchedPCA) {
      const pcas = allPCAs.map((pc) => ({
        id: null,
        attribute: pc.attribute_name,
        value: pc.attribute_value,
      }));
      setProductForm((prevState) => ({
        ...prevState,
        pcas,
      }));
      onFetchPCA(false);
    }
  }, [isFetchedPCA]);

  useEffect(() => {
    if (id && isEdit && product) {
      setProductForm({
        images:
          isEdit && product && method !== "create"
            ? product?.images?.map(({ id, images }) => ({
                id,
                images: images,
                operation: "DONOTHING",
              }))
            : [],
        name_eng: product.name_eng,
        name_arb: product.name_arb,
        sale_price: product.sale_price,
        current_qty: product.current_qty,
        forcast_qty: product.forcast_qty,
        overview_eng: product.overview_eng,
        overview_arb: product.overview_arb,
        details_specifications_eng: product.details_specifications_eng,
        details_specifications_arb: product.details_specifications_arb,
        sku: product.sku,
        model_number: product.model_number,
        video:
          isEdit && product && method !== "create"
            ? { video: product.video, operation: "DONOTHING" }
            : { video: null, operation: null },
        cost_price: product.cost_price,
        main_image:
          isEdit && product && method !== "create"
            ? { image: product.main_image, operation: "DONOTHING" }
            : { image: null, operation: null },
        bar_code: product.bar_code,
        tax: product.tax,
        weight: product.weight,
        volume: product.volume,
        category: {
          label: product?.category?.name_eng,
          value: product?.category?.id,
        },
        brand: { label: product?.brand?.name, value: product?.brand?.id },
        uom: { label: product?.uom?.name, value: product?.uom?.id },
        product_tags:
          product?.product_tags?.map((tag) => ({
            label: tag.name,
            value: tag.id,
          })) || [],
        pcas:
          product?.product_template_attributes?.map((pca) => ({
            id: pca.id,
            attribute: pca.attribute_name,
            value: pca.attribute_value,
            operation: "NONE",
          })) || [],
        selection: product.selection
          ? selectionOptions.find((item) => item.value === product.selection)
          : {
              label: "",
              value: "",
            },
        attachments:
          isEdit && product && method !== "create"
            ? product?.attachments?.map((item) => ({
                id: item.id,
                attachment: item.attachment,
                filename: item.filename,
                operation: "DONOTHING",
              }))
            : [],
        policy_eng: product.policy_eng,
        policy_arb: product.policy_arb,
        discount: product.discount,
        delivery_fee: product.delivery_fee,
      });
    }
  }, [id, isEdit, productTitle]);

  const mainImageRef = useRef(null);
  const videoRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    // submitted Data common modification for All cases => product (CREATE,UPDATE) Varirant (CREATE,UPDATE)
    const submitedData = {
      ...productForm,
      product_tags: productForm.product_tags.length
        ? productForm.product_tags.map((pt) => pt.value)
        : [],
      brand: productForm.brand.value ? +productForm.brand.value : null,
      uom: productForm.uom.value ? +productForm.uom.value : null,
      sale_price: +productForm.sale_price,
      current_qty: +productForm.current_qty,
      forcast_qty: +productForm.forcast_qty,
      cost_price: +productForm.cost_price,
      weight: +productForm.weight,
      category: productForm.category.value ? productForm.category.value : null,
      discount: +productForm.discount,
      delivery_fee: +productForm.delivery_fee,
      model_number: +productForm.model_number,
      volume: +productForm.volume,
      sku: +productForm.sku,
    };

    if (isEdit) {
      // Edit three cases => (Product Update/ Product Variant Create and Product Variant Update)
      // If method is create don't pass variants i.e. Create the variant

      //  ============================== Case 1 ============================= //
      // Variant Create
      if (method === "create") {
        const { variants, ...rest } = submitedData;

        const data = {
          ...rest,
          product_id: +id,
          images: productForm.images.map(({ id, images, operation }) => ({
            image:
              operation === "CREATE"
                ? images.slice(images.indexOf(",") + 1)
                : images,
          })),
          main_image:
            (productForm.main_image.image &&
              productForm.main_image.image.slice(
                productForm.main_image.image.indexOf(",") + 1
              )) ||
            null,
          PTA: productForm.pcas
            .filter((pc) => pc.operation !== "DELETE")
            .map(({ attribute, value }) => ({
              [attribute]: value,
            })),
          video:
            (productForm.video.video &&
              productForm.video.video.slice(
                productForm.video.video.indexOf(",") + 1
              )) ||
            null,
          selection: productForm.selection.value,
          attachments: productForm.attachments.map(
            ({ attachment, ext, filename }) => ({
              attachment: attachment.slice(attachment.indexOf(",") + 1),
              ext,
              filename,
            })
          ),
        };

        delete data.pcas;

        const response = await createVendorProductOrVariant({
          ...data,
        });
        if (response.data && response.flag) {
          onAddVariant(response.data);
        } else {
          toast.error("Product Variant Creation Failed");
          setIsSubmitting(false);
        }
      } else if (method === "update") {
        //  ============================== Case 2 ======================== //
        // Variant Update
        const data = {
          ...submitedData,
          PTA: productForm.pcas.map(({ id, attribute, value, operation }) => ({
            pta_id: id,
            attribute_name: attribute,
            attribute_value: value,
            operation,
          })),
          main_image: {
            image:
              productForm.main_image.operation === "UPDATE"
                ? productForm.main_image.image.slice(
                    productForm.main_image.image.indexOf(",") + 1
                  )
                : productForm.main_image.image,
            operation: productForm.main_image.operation,
          },
          images: productForm.images.map(({ id, images, operation }) => ({
            id,
            operation,
            image:
              operation === "CREATE"
                ? images.slice(images.indexOf(",") + 1)
                : images,
          })),
          video: {
            video:
              productForm.video.operation === "UPDATE"
                ? productForm.video.video.slice(
                    productForm.video.video.indexOf(",") + 1
                  )
                : productForm.video.video,
            operation: productForm.video.operation,
          },
          attachments: productForm.attachments.map((item) => ({
            ...item,
            attachment:
              item.operation === "CREATE"
                ? item.attachment.slice(item.attachment.indexOf(",") + 1)
                : item.attachment,
          })),
          selection: productForm.selection.value,
        };

        delete data.pcas;

        const response = await updateProductOrVariant(product.id, {
          ...data,
        });

        if (response.data) {
          onUpdateVariant(response.data);
          setIsSubmitting(false);
        } else {
          toast.error("updating variant failed");
          setIsSubmitting(false);
        }
      } else {
        //  =========================== Case 3 =========================== //
        // update product
        const data = {
          ...submitedData,
          images: productForm.images.map(({ id, images, operation }) => ({
            id,
            operation,
            image:
              operation === "CREATE"
                ? images.slice(images.indexOf(",") + 1)
                : images,
          })),
          PTA: productForm.pcas.map((pc) => ({
            pta_id: pc.id,
            attribute_name: pc.attribute,
            attribute_value: pc.value,
            operation: pc.operation,
          })),
          main_image:
            productForm.main_image.operation === "DONOTHING"
              ? { image: productForm.main_image.image, operation: "DONOTHING" }
              : {
                  image: productForm.main_image.image.slice(
                    productForm.main_image.image.indexOf(",") + 1
                  ),
                  operation: productForm.main_image.operation,
                },
          video:
            productForm.video.operation === "DONOTHING"
              ? {
                  video: productForm.video.video,
                  operation: productForm.video.operation,
                }
              : {
                  video: productForm.video.video.slice(
                    productForm.video.video.indexOf(",") + 1
                  ),
                  operation: productForm.video.operation,
                },
          attachments: productForm.attachments.map((item) => ({
            ...item,
            attachment:
              item.operation === "CREATE"
                ? item.attachment.slice(item.attachment.indexOf(",") + 1)
                : item.attachment,
          })),
          selection: productForm.selection.value,
        };

        delete data.pcas;

        const response = await updateProductOrVariant(id, {
          ...data,
        });

        if (!response.data) {
          toast.error("Update Product failed");
          setIsSubmitting(false);
        } else {
          navigate("/vendor/products");
        }
      }
    } else {
      // Case 4 Product Create
      // Create Product i.e Main
      const data = {
        ...submitedData,
        images: productForm.images.map(({ image }) => ({
          image: image.slice(image.indexOf(",") + 1),
        })),
        PTA: productForm.pcas
          .filter((pc) => pc.operation !== "DELETE")
          .map(({ attribute, value }) => ({ [attribute]: value })),
        main_image: productForm.main_image.image
          ? productForm.main_image.image.slice(
              productForm.main_image.image.indexOf(",") + 1
            )
          : null,
        video: productForm.video.video
          ? productForm.video.video.slice(
              productForm.video.video.indexOf(",") + 1
            )
          : null,
        attachments: productForm.attachments.map((item) => ({
          ...item,
          attachment: item.attachment.slice(item.attachment.indexOf(",") + 1),
        })),
        selection: productForm.selection.value,
        product_id: null,
      };
      delete data.pcas;

      const response = await createVendorProductOrVariant({
        ...data,
      });

      if (!response.data) {
        toast.error("Product Creation failed");
        setIsSubmitting(false);
      }

      if (!method && response.data) {
        navigate("/vendor/products");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleFileChange = (event) => {
    const name = event.target.name;
    const files = Array.from(event.target.files);
    Promise.all(
      files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (name === "attachments") {
              const attachment = e.target.result;
              const filename = file.name;
              const ext = filename.split(".").pop();
              resolve({ attachment, filename, ext });
            } else resolve(e.target.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })
    )
      .then((base64Files) => {
        if (name === "images") {
          setProductForm((prevState) => ({
            ...prevState,
            [name]: [
              ...prevState[name],
              ...base64Files.map((image) =>
                isEdit
                  ? { id: null, images: image, operation: "CREATE" }
                  : { image }
              ),
            ],
          }));
        } else if (name === "attachments") {
          const base64FilesData = isEdit
            ? base64Files.map((item) => ({
                id: null,
                operation: "CREATE",
                ...item,
              }))
            : base64Files;
          setProductForm((prevState) => ({
            ...prevState,
            [name]: [...prevState[name], ...base64FilesData],
          }));
        } else
          setProductForm((prevState) => ({
            ...prevState,
            [name]: {
              [name === "main_image" ? "image" : "video"]: base64Files[0],
              operation: isEdit ? "UPDATE" : "CREATE",
            },
          }));
      })
      .catch((error) => {
        console.error("Error reading files: ", error);
      });
  };

  const handleImageDelete = (index) => {
    let allImages = [...productForm.images];
    if (isEdit) {
      const imageDetail = allImages[index];
      const { id } = imageDetail;
      if (id) {
        allImages[index] = {
          ...allImages[index],
          operation: "DELETE",
        };
      } else {
        allImages.splice(index, 1);
      }
    } else {
      allImages.splice(index, 1);
    }
    setProductForm((prevState) => ({
      ...prevState,
      images: allImages,
    }));
  };

  const handleAttachementDelete = (index) => {
    let allAttachements = [...productForm.attachments];
    if (isEdit) {
      const attachment = allAttachements[index];
      const { id } = attachment;
      if (id) {
        allAttachements[index] = {
          ...allAttachements[index],
          operation: "DELETE",
        };
      } else {
        allAttachements.splice(index, 1);
      }
    } else {
      allAttachements.splice(index, 1);
    }
    setProductForm((prevState) => ({
      ...prevState,
      attachments: allAttachements,
    }));
  };

  const handleFileDelete = (name) => {
    setProductForm((prevState) => ({
      ...prevState,
      [name]: {
        [name === "main_image" ? "image" : "video"]: "",
        operation: "DELETE",
      },
    }));

    if (name === "main_image") {
      if (mainImageRef.current) {
        mainImageRef.current.value = "";
      }
    }
    if (name === "video") {
      if (videoRef.current) {
        videoRef.current.value = "";
      }
    }
  };

  const handlePCAChange = (index, attribute, value) => {
    const prevState = { ...productForm };
    const productPCAs = prevState.pcas;
    const pca = productPCAs[index];

    if (pca.operation === "NONE") {
      productPCAs[index] = {
        ...productPCAs[index],
        [attribute]: value,
        operation: "UPDATE",
      };
      setProductForm((prevState) => ({ ...prevState, pcas: productPCAs }));
    } else {
      productPCAs[index] = {
        ...productPCAs[index],
        [attribute]: value,
      };
      setProductForm((prevState) => ({ ...prevState, pcas: productPCAs }));
    }
  };

  const handlePCADelete = (index) => {
    const prevState = { ...productForm };
    const productPCAs = prevState.pcas;
    const pca = productPCAs[index];

    if (pca.operation === "NONE" || pca.operation === "UPDATE") {
      productPCAs[index] = {
        ...productPCAs[index],
        operation: "DELETE",
      };
      setProductForm((prevState) => ({ ...prevState, pcas: productPCAs }));
    } else if (pca.operation === "CREATE") {
      productPCAs.splice(index, 1);
      setProductForm((prevState) => ({ ...prevState, pcas: productPCAs }));
    }
  };

  const handlePCACreate = () => {
    const prevState = { ...productForm };
    const productPCAs = prevState.pcas;

    productPCAs.push({
      id: null,
      value: "",
      attribute: "",
      operation: "CREATE",
    });
    setProductForm((prevState) => ({ ...prevState, pcas: productPCAs }));
  };

  return (
    <section className="our-ui-elements pt70">
      <div className="container">
        <div className="row form-row">
          <div className="col-lg-6 offset-lg-3">
            <div className="main-title text-center">
              <h1>{productTitle ? productTitle : "PRODUCT"}</h1>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row form-row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="shortcode_widget_form">
                <div className="ui_kit_input mb25">
                  <div className="custom-field">
                    <h5 className="short_code_title">Name (Eng)</h5>
                    <div className="form-group">
                      <input
                        value={productForm.name_eng}
                        name="name_eng"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        placeholder="Product Name"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Name Arabic</h5>
                    <div className="form-group">
                      <input
                        value={productForm.name_arb}
                        name="name_arb"
                        onChange={handleChange}
                        style={{
                          unicodeBidi: "bidi-override",
                          direction: "rtl",
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Product Name"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Sale price</h5>
                    <div className="form-group">
                      <input
                        type="number"
                        value={productForm.sale_price}
                        name="sale_price"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Sale price"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Current qty</h5>
                    <div className="form-group">
                      <input
                        type="number"
                        value={productForm.current_qty}
                        name="current_qty"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Current qty"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Forecast qty</h5>
                    <div className="form-group">
                      <input
                        type="number"
                        value={productForm.forcast_qty}
                        name="forcast_qty"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Forecast qty"
                      />
                    </div>
                  </div>
                  <div className="ui_kit_textarea mt30 mt-custom custom-field">
                    <h4 className="short_code_title">Detail Specs (Eng)</h4>
                    <div className="form-group">
                      <ReactQuill
                        value={productForm.details_specifications_eng}
                        name="details_specifications_eng"
                        onChange={(content) =>
                          setProductForm((prevState) => ({
                            ...prevState,
                            details_specifications_eng: content,
                          }))
                        }
                        theme="snow"
                        style={{
                          height: "300px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="ui_kit_textarea mt30 mt-custom custom-field">
                    <h4 className="short_code_title">Detail Specs (Arb)</h4>
                    <div className="form-group">
                      <ReactQuill
                        value={productForm.details_specifications_arb}
                        name="details_specifications_arb"
                        onChange={(content) =>
                          setProductForm((prevState) => ({
                            ...prevState,
                            details_specifications_arb: content,
                          }))
                        }
                        theme="snow"
                        style={{
                          height: "300px",
                          unicodeBidi: "bidi-override",
                          direction: "rtl",
                        }}
                      />
                    </div>
                  </div>

                  <div className="ui_kit_textarea mt30 mt-custom custom-field">
                    <h4 className="short_code_title">Overview (Eng)</h4>
                    <div className="form-group">
                      <ReactQuill
                        value={productForm.overview_eng}
                        name="overview_eng"
                        onChange={(content) =>
                          setProductForm((prevState) => ({
                            ...prevState,
                            overview_eng: content,
                          }))
                        }
                        theme="snow"
                        style={{
                          height: "300px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="ui_kit_textarea mt30 mt-custom custom-field">
                    <h4 className="short_code_title">Overview (Arb)</h4>
                    <div className="form-group">
                      <ReactQuill
                        value={productForm.overview_arb}
                        name="overview_arb"
                        onChange={(content) =>
                          setProductForm((prevState) => ({
                            ...prevState,
                            overview_arb: content,
                          }))
                        }
                        theme="snow"
                        style={{
                          height: "300px",
                          unicodeBidi: "bidi-override",
                          direction: "rtl",
                        }}
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <PCAListing
                      pcas={productForm?.pcas || []}
                      onChange={handlePCAChange}
                      onDelete={handlePCADelete}
                      onCreate={handlePCACreate}
                    />
                  </div>

                  <div className="custom-field">
                    <h5 className="short_code_title">Brand</h5>
                    <ReactSelect
                      options={brandsOptions}
                      onChange={(value) =>
                        setProductForm((prevState) => ({
                          ...prevState,
                          brand: value,
                        }))
                      }
                      value={productForm.brand}
                    />
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">SKU</h5>
                    <div className="form-group">
                      <input
                        value={productForm.sku}
                        name="sku"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Model number</h5>
                    <div className="form-group">
                      <input
                        value={productForm.model_number}
                        name="model_number"
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="shortcode_widget_form">
                <div className="ui_kit_input mb25">
                  <div className="custom-field">
                    <h5 className="short_code_title">Images</h5>
                    <div className="form-group">
                      <input
                        multiple
                        onChange={handleFileChange}
                        type="file"
                        name="images"
                        className="form-control"
                        accept="image/*"
                        placeholder="Upload Image"
                      />
                    </div>

                    {productForm?.images?.map((image, index) =>
                      isEdit &&
                      (image.operation === "DONOTHING" ||
                        image.operation === "CREATE") ? (
                        <div key={index} className="image-container m-2">
                          <img
                            src={
                              image.operation === "DONOTHING"
                                ? URL_PATH + image.images
                                : image.images
                            }
                            alt="Preview Image"
                          />
                          <div
                            onClick={() => handleImageDelete(index)}
                            className="close-icon"
                          >
                            &times;
                          </div>
                        </div>
                      ) : (
                        !isEdit && (
                          <div key={index} className="image-container m-2">
                            <img src={image.image} alt="Preview Image" />
                            <div
                              onClick={() => handleImageDelete(index)}
                              className="close-icon"
                            >
                              &times;
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>

                  <div className="custom-field">
                    <div className="ui_kit_select_search">
                      <h5 className="short_code_title">Category</h5>
                      <ReactSelect
                        isDisabled={method === "create" || method === "update"}
                        options={categoryOptions}
                        onChange={(value) => {
                          setProductForm((prevState) => ({
                            ...prevState,
                            category: value,
                          }));
                          !isEdit && onChangeCategory(value.value);
                        }}
                        value={productForm.category}
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <div className="shortcode_widget_multiselect">
                      <h5 className="short_code_title">Product Tags</h5>
                      <ReactSelect
                        onChange={(value) =>
                          setProductForm((prevState) => ({
                            ...prevState,
                            product_tags: value,
                          }))
                        }
                        isMulti
                        options={tagsOptions}
                        value={productForm.product_tags}
                      />
                    </div>
                  </div>

                  <div className="custom-field">
                    <h5 className="short_code_title">Upload Video</h5>
                    <div className="form-group">
                      <input
                        ref={videoRef}
                        name="video"
                        onChange={handleFileChange}
                        type="file"
                        className="form-control"
                        accept="video/*"
                      />
                    </div>

                    {productForm.video.video && productForm.video.operation && (
                      <div className="video-container">
                        <video controls className="preview-video">
                          <source
                            src={
                              productForm.video.operation === "DONOTHING"
                                ? URL_PATH + productForm.video.video
                                : productForm.video.video
                            }
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <div
                          onClick={() => handleFileDelete("video")}
                          className="close-icon"
                        >
                          &times;
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Cost Price</h5>
                    <div className="form-group">
                      <input
                        value={productForm.cost_price}
                        name="cost_price"
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Main Image</h5>
                    <div className="form-group">
                      <input
                        onChange={handleFileChange}
                        name="main_image"
                        type="file"
                        className="form-control"
                        accept="image/*"
                        placeholder="Upload Image"
                        ref={mainImageRef}
                      />
                    </div>
                    {productForm.main_image.operation &&
                      productForm.main_image.image && (
                        <div className="image-container">
                          <img
                            src={
                              productForm.main_image.operation === "DONOTHING"
                                ? URL_PATH + productForm.main_image.image
                                : productForm.main_image.image
                            }
                            alt="Preview Image"
                          />
                          <div
                            onClick={() => handleFileDelete("main_image")}
                            className="close-icon"
                          >
                            &times;
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Barcode</h5>
                    <div className="form-group">
                      <input
                        value={productForm.bar_code}
                        name="bar_code"
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Weight</h5>
                    <div className="form-group">
                      <input
                        value={productForm.weight}
                        name="weight"
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Volume</h5>
                    <div className="form-group">
                      <input
                        value={productForm.volume}
                        name="volume"
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">UOM</h5>
                    <ReactSelect
                      isDisabled={method === "create" || method === "update"}
                      options={uomsOptions}
                      onChange={(value) =>
                        setProductForm((prevState) => ({
                          ...prevState,
                          uom: value,
                        }))
                      }
                      value={productForm.uom}
                    />
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Selection</h5>
                    <ReactSelect
                      isDisabled={method === "create" || method === "update"}
                      options={selectionOptions}
                      onChange={(value) =>
                        setProductForm((prevState) => ({
                          ...prevState,
                          selection: value,
                        }))
                      }
                      value={productForm.selection}
                    />
                    {(productForm.selection.value === "digital" ||
                      productForm.selection.value === "both") && (
                      <div className="custom-field">
                        <h5 className="short_code_title">Attachments</h5>
                        <div className="form-group">
                          <input
                            multiple
                            onChange={handleFileChange}
                            type="file"
                            name="attachments"
                            className="form-control"
                            placeholder="Upload Image"
                          />
                        </div>
                        {productForm.attachments.length > 0 && (
                          <div className="attachments-container">
                            {productForm.attachments.map(
                              (item, index) =>
                                (item.operation === "CREATE" ||
                                  item.operation === "DONOTHING") && (
                                  <div className="attachment-item">
                                    <span className="file-name">
                                      {item.filename}
                                    </span>
                                    <span
                                      onClick={() =>
                                        handleAttachementDelete(index)
                                      }
                                      className="remove-btn text-danger ml-3"
                                    >
                                      <i className="fas fa-times"></i>
                                    </span>
                                  </div>
                                )
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Discount</h5>
                    <div className="form-group">
                      <input
                        type="number"
                        value={productForm.discount}
                        name="discount"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Discount"
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Policy (Eng)</h5>
                    <div className="form-group">
                      <textarea
                        value={productForm.policy_eng}
                        name="policy_eng"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Discount"
                        rows={8}
                        style={{
                          resize: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Policy (Arb)</h5>
                    <div className="form-group">
                      <textarea
                        value={productForm.policy_arb}
                        name="policy_arb"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Discount"
                        rows={8}
                        style={{
                          resize: "none",
                          unicodeBidi: "bidi-override",
                          direction: "rtl",
                        }}
                      />
                    </div>
                  </div>
                  <div className="custom-field">
                    <h5 className="short_code_title">Delivery Fee</h5>
                    <div className="form-group">
                      <input
                        type="number"
                        value={productForm.delivery_fee}
                        name="delivery_fee"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Delivery Fee"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              disabled={isSubmitting || !productForm.name_eng}
              type="submit"
              className="btn btn-new btn-lg btn-thm"
            >
              {isEdit ? btnTitle : "Create"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ProductForm;
