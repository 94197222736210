import React from "react";
import { useSelector } from "react-redux";
import { URL_PATH } from "../../../shared/constants/common";
import { myOrdersPage } from "../../constants/localization";

const completeStyle = {
  padding: "5px 10px",
  borderRadius: "10px",
  background: "#f5c34b",
  color: "white",
};

const notDeliveredStyle = {
  padding: "5px 10px",
  borderRadius: "10px",
  background: "grey",
  color: "white",
};

const reviewBtnStyle = {
  padding: "5px 10px",
  borderRadius: "10px",
  background: "#443297",
  color: "white",
  fontWeight: "bold",
};
const MyOrderItem = ({ order, onReview }) => {
  const { order_lines, id, is_complete_delivery } = order;
  const { language } = useSelector((state) => state.setting);
  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>
            {myOrdersPage.order[language]} #{id}
          </h5>
          <span
            style={is_complete_delivery ? completeStyle : notDeliveredStyle}
          >
            {is_complete_delivery
              ? myOrdersPage.delivered[language]
              : myOrdersPage.pending[language]}
          </span>
        </div>
        <div className="card-body">
          {order_lines.map((item, index) => (
            <div className="column" key={index}>
              <div className="d-flex align-items-center gap-4">
                <div>
                  <img
                    src={
                      item.main_image
                        ? URL_PATH + item.image
                        : "/images/no-image.png"
                    }
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      height: "120px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h5>
                  {language === "eng"
                    ? item.product_id.name_eng
                    : item.product_id.name_arb}
                </h5>
                <div className="flex-grow-1 d-flex justify-content-around">
                  <span className="inline-block">{item.unit_price}$</span>
                  <span className="inline-block">
                    {myOrdersPage.qty[language]}:{item.quantity}
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex align-items-center gap-2">
                  <i className="fa-solid fa-gift"></i>
                  <span className="fw-bold">{item.vendor_id.name}</span>
                </div>
                {item.is_reviewed ? (
                  <span style={reviewBtnStyle}>
                    {myOrdersPage.reviewed[language]}
                  </span>
                ) : (
                  <button
                    onClick={() => onReview(item.id, item.product_id.id)}
                    className="btn btn-thm btn-sm"
                  >
                    {myOrdersPage.giveReview[language]}
                  </button>
                )}
              </div>
              {order_lines.length - 1 !== index && <hr />}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MyOrderItem;
