const PCAForm = ({ pca, index, onChange }) => {
  return (
    <div className="d-flex">
      <div>
        <input
          className="form-control"
          placeholder="Attribute"
          value={pca.attribute}
          onChange={(e) => onChange(index, "attribute", e.target.value)}
        />
      </div>
      <div>
        <input
          className="form-control"
          placeholder="Value"
          value={pca.value}
          onChange={(e) => onChange(index, "value", e.target.value)}
        />
      </div>
    </div>
  );
};

export default PCAForm;
