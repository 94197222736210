import SaleOrderRow from "./SaleOrderRow";

const SaleOrderTableBody = ({ onView, saleOrders }) => {
  return (
    <tbody style={{ width: "100%" }}>
      {saleOrders.map((deliveryItem, index) => (
        <SaleOrderRow
          key={index}
          onView={onView}
          deliveryItem={deliveryItem}
          index={index}
        />
      ))}
    </tbody>
  );
};

export default SaleOrderTableBody;
