const DeliveryItemModal = ({ item, onChangeQty, index, isInvoice = false }) => {
  const { product_id, variant_id, displayQty } = item;
  const product = product_id ? product_id : variant_id;
  const { name_eng, main_image } = product;

  return (
    <tr>
      <td className="body-color">
        <img
          src={process.env.REACT_APP_BASE_URL_LIVE + main_image}
          alt="Product Image"
          style={{
            width: "50px",
            height: "50px",
          }}
        />
      </td>
      <td className="body-color">
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            whiteSpace: "nowrap",
          }}
        >
          {name_eng}
        </p>
      </td>
      <td className="body-color">
        <input
          disabled={isInvoice}
          value={displayQty}
          onChange={(e) =>
            !isInvoice &&
            onChangeQty(index, +(e.target.value < 0) ? 1 : +e.target.value)
          }
          type="number"
          className="form-control"
        />
      </td>
    </tr>
  );
};

export default DeliveryItemModal;
