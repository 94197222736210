import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCategories,
  getCategoriesForUser,
  getCategoriesWithSubLevels,
} from "../services/categories";
import { getProductsByCategory } from "../services/product";

const initialState = {
  categories: [],
  allCategories: [],
  categoriesWithLevel: [],
  selectedCategory: {},
  homeProducts: [],
  categoriesKeyBased: {},
  loading: true,
};

export const getAllCategories = createAsyncThunk(
  "category/list",
  async (thunkAPI) => {
    try {
      const categories = await getCategories();
      const allCategories = await getCategoriesForUser();
      const categoriesWithLevel = await getCategoriesWithSubLevels();
      const categoriesKeyBased = extractSubcategories(categoriesWithLevel);
      const homeCategories = categories.slice(0, 3);

      const promisesArr = homeCategories.map(
        async ({ id, name_eng, name_arb }) => {
          const response = await getProductsByCategory(id);

          const data = {
            id,
            name_eng,
            name_arb,
            products: response.data,
          };
          return data;
        }
      );

      const homeProducts = await Promise.all(promisesArr);

      return {
        categories,
        homeProducts,
        categoriesWithLevel,
        categoriesKeyBased,
        allCategories,
      };
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    handleSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories;
        state.categoriesWithLevel = action.payload.categoriesWithLevel;
        state.homeProducts = action.payload.homeProducts;
        state.categoriesKeyBased = action.payload.categoriesKeyBased;
        state.allCategories = action.payload.allCategories;
        state.loading = false;
      })
      .addCase(getAllCategories.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { handleSelectedCategory } = categorySlice.actions;

export default categorySlice.reducer;

function extractSubcategories(response) {
  const result = {};

  function addSubcats(item) {
    if (!result[item.id]) {
      result[item.id] = [];
    }
    item.sub_cat.forEach((sub) => {
      result[item.id].push(sub.id);
      addSubcats(sub);
    });
  }

  response.forEach((item) => addSubcats(item));
  return result;
}
