import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CategoryMenuItem = ({ categoryItem }) => {
  const { language } = useSelector((state) => state.setting);
  const { id, name_eng, name_arb, sub_cat } = categoryItem;

  return (
    <li>
      <Link
        to={`/shop?categoryId=${id}`}
        state={{
          name_eng,
          name_arb,
        }}
        className={` ${language === "eng" ? "dropdown" : "dropdown__arb"}`}
      >
        <span className="menu-icn flaticon-diamond" />
        <span className="menu-title">
          {language === "eng" ? name_eng : name_arb}
        </span>
      </Link>
      <div
        className={`drop-menu ${
          language === "eng" ? "drop-menu__left" : "drop-menu__right"
        }`}
      >
        {sub_cat.map((item, index) => (
          <div
            key={index}
            className={`one-third ${
              language === "eng" ? "one-third__left" : "one-third__right"
            }`}
          >
            <div className="cat-title" style={{ textWrap: " nowrap" }}>
              <Link
                to={`/shop?categoryId=${item.id}&categoryName=${item.name_eng}`}
              >
                {language === "eng" ? item.name_eng : item.name_arb}
              </Link>
            </div>
            <ul className="mb20">
              {item.sub_cat.map((subitem, index) => (
                <li key={index}>
                  <Link
                    to={`/shop?categoryId=${subitem.id}&categoryName=${subitem.name_eng}`}
                  >
                    {language === "eng" ? subitem.name_eng : subitem.name_arb}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </li>
  );
};

export default CategoryMenuItem;
