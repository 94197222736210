import { useState } from "react";
import { useSelector } from "react-redux";
import CustomerDeliveryModalItem from "./CustomerDeliveryModalItem";
import DeliveryRefundForm from "../DeliveryRefundForm";
import { confirmRefundDelivery } from "../../../services/myOrders";
import { deliveryRefundForm } from "../../../constants/localization";

const CustomerDeliveryModal = ({ data }) => {
  const [deliveriesLines, setDeliveriesLines] = useState(data.delivery_lines);
  const [showRefundForm, setShowRefundForm] = useState(false);
  const [isAllItemsRefunded, setIsAllItemsRefunded] = useState(false);
  const { language } = useSelector((state) => state.setting);

  const handleUpdate = (index, quantity) => {
    const allDeliveryLines = [...deliveriesLines];
    const item = allDeliveryLines[index];
    item.quantity = quantity;
    allDeliveryLines[index] = item;
    setDeliveriesLines(allDeliveryLines);
  };

  const handleDelete = (id) => {
    setDeliveriesLines((prevState) =>
      prevState.filter((item) => item.id !== id)
    );
  };

  const handleConfirm = async (d) => {
    delete data.created_at;
    delete data.updated_at;

    const parentId = data.id;

    delete data.id;

    const submitedData = {
      ...data,
      return_reason: d.reason,
      delivery_lines: deliveriesLines.map(
        ({ quantity, uom, product_id, id }) => ({
          quantity,
          uom,
          product_id: product_id.id,
          delivery_line_id: id,
        })
      ),
      images: d.images.map((image) => ({
        image: image.slice(image.indexOf(",") + 1),
      })),
      customer_id: data.customer_id.id,
      vendor_id: data.vendor_id.id,
      delivery_address: data.customer_id.address.id,
      parent_id: parentId,
    };

    const response = await confirmRefundDelivery(submitedData);
  };

  // If parent id not null means it can be refund
  const isRefund = data.parent_id == null ? false : true;

  const handleRefund = () => {
    setShowRefundForm(true);
    const check = data.delivery_lines.every(
      (item) => item.quantity == item.refunded_quantity
    );
    const filterLines = data.delivery_lines.filter(
      (item) => item.quantity !== item.refunded_quantity
    );
    setDeliveriesLines(filterLines);
    setIsAllItemsRefunded(check);
  };

  const lines = showRefundForm ? deliveriesLines : data.delivery_lines;
  return (
    <div
      style={{ width: "80%", margin: "auto" }}
      className="container-fluid bg-white border shadow-sm rounded"
    >
      <div className="row py-4 px-5">
        <div className="col-md-8">
          <h3 className="text-primary mb-3">{data.name}</h3>
          <div className="d-flex align-items-center mb-2">
            <span className="text-muted">
              {deliveryRefundForm.trackingReference[language]}
            </span>
            <span className="ms-2 fw-bold">{data.tracking_reference}</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <span className="text-muted">
              {deliveryRefundForm.scheduleDate[language]}:
            </span>
            <span className="ms-2 fw-bold">{data.schedule_date}</span>
          </div>
        </div>
        <div className="col-md-4 d-flex flex-column align-items-md-end">
          <div className="d-flex align-items-center mb-2">
            <span className="text-muted">
              {deliveryRefundForm.vendor[language]}:
            </span>
            <span className="ms-2 fw-bold">{data.vendor_id.name}</span>
          </div>
          <div className="d-flex align-items-center mb-3">
            <span className="text-muted">
              {deliveryRefundForm.customer[language]}:
            </span>
            <span className="ms-2 fw-bold">{data.customer_id.name}</span>
          </div>
        </div>
      </div>

      {data.parent_id === null && (
        <div className="d-flex justify-content-end py-3">
          {!showRefundForm ? (
            <button onClick={handleRefund} className="btn btn-thm">
              {deliveryRefundForm.createRefund[language]}
            </button>
          ) : (
            <button
              className="btn btn-thm"
              onClick={() => setShowRefundForm(false)}
            >
              {deliveryRefundForm.back[language]}
            </button>
          )}
        </div>
      )}

      <div className="account_user_deails dashboard_page">
        <div className="order_table table-responsive">
          {isAllItemsRefunded && showRefundForm ? (
            <div>
              <h4 className="text-center">
                
              {deliveryRefundForm.allItemsRefunded[language]}
              </h4>
            </div>
          ) : (
            <table className="table">
              <thead className="table-light">
                <tr className="text-center">
                  <th scope="col">{deliveryRefundForm.image[language]}</th>
                  <th scope="col">{deliveryRefundForm.name[language]}</th>
                  <th scope="col">{deliveryRefundForm.productQty[language]}</th>
                  {showRefundForm && (
                    <th scope="col">{deliveryRefundForm.action[language]}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {lines.map((deliveryItem, index) => (
                  <CustomerDeliveryModalItem
                    enabledInput={showRefundForm}
                    key={deliveryItem.id}
                    deliveryItem={deliveryItem}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                    index={index}
                    isRefund={
                      deliveryItem.quantity === deliveryItem.refunded_quantity
                    }
                  />
                ))}
              </tbody>
            </table>
          )}

          <hr />
        </div>
      </div>

      {showRefundForm ? (
        <DeliveryRefundForm
          onConfirm={handleConfirm}
          isRefund={isRefund}
          refund={
            data.parent_id === null
              ? null
              : { reason: data.return_reason, images: data.images }
          }
        />
      ) : (
        <DeliveryRefundForm
          isRefund={isRefund}
          refund={{ reason: data.return_reason, images: data.images }}
        />
      )}
    </div>
  );
};

export default CustomerDeliveryModal;
