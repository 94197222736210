import { useDispatch, useSelector } from "react-redux";
import {
  decrementQuantity,
  incrementQuantity,
} from "../../store/cart/cartSlice";
import { decimalNumberUpto2 } from "../../utils/decimalNumberUpto2";
import { URL_PATH } from "../../../shared/constants/common";

const HiddenShoppingCartItem = ({ cartItem }) => {
  const { language } = useSelector((state) => state.setting);
  const dispatch = useDispatch();

  const handleIncrement = (variant_id, product_id) => {
    dispatch(incrementQuantity({ variant_id, product_id }));
  };
  const handleDecrement = (variant_id, product_id) => {
    dispatch(decrementQuantity({ variant_id, product_id }));
  };

  const getAmountWithDiscount = () => {
    const discountAmount = cartItem.sale_price * (cartItem.discount / 100);
    const itemTotal =
      cartItem.quantity * (cartItem.sale_price - discountAmount);
    return itemTotal;
  };

  return (
    <div className="container-cart-item">
      <div className="cart-item-image">
        <img src={URL_PATH + cartItem.main_image} />
      </div>
      <div>
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            whiteSpace: "nowrap",
          }}
        >
          {language === "eng" ? cartItem.name_eng : cartItem.name_arb}
        </p>
        <div className="cart-buttons-inline">
          <button
            className="cart-badge"
            type="button"
            onClick={() =>
              handleDecrement(cartItem.variant_id, cartItem.product_id)
            }
          >
            <i className="fa-solid fa-minus"></i>
          </button>
          <input
            className="cart-input"
            type="number"
            disabled
            value={cartItem.quantity}
          />
          <button
            className="cart-badge"
            type="button"
            onClick={() =>
              handleIncrement(cartItem.variant_id, cartItem.product_id)
            }
          >
            <i className="fa-solid fa-plus"></i>
          </button>
        </div>
      </div>
      <div
        style={{
          marginTop: "2rem",
        }}
      >
        ${decimalNumberUpto2(getAmountWithDiscount())}
      </div>
    </div>
  );
};

export default HiddenShoppingCartItem;
