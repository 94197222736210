import { Link } from "react-router-dom";
import { useAuth } from "../../../user/context/AuthContext";

const PortalHeader = ({ onToggle }) => {
  const { menus, onLogout } = useAuth();
  const handleLogout = () => {
    onLogout();
  };
  return (
    <header className="dashboard_header">
      <div className="header__container pt20 pb20 pl30 pr30">
        <div className="row justify-between items-center">
          <div className="col-sm-4 col-xl-2">
            <div className="text-center text-lg-start d-flex mb15-520">
              <div onClick={onToggle} className="fz20 me-4">
                <a className="dashboard_sidebar_toggle_icon text-thm1 vam">
                  <i className="fa-sharp fa-solid fa-bars-staggered"></i>
                </a>
              </div>
              <div className="dashboard_header_logo">
                <a className="logo">
                  Mrzzm<span className="text-thm">.</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xl-3 d-none d-md-block">
            <div className="header_search_widget mb15-520">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  aria-label="Recipient's username"
                />
                <div className="input-group-append">
                  <button className="btn" type="button">
                    <span className="fa fa-search"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-5 col-xl-6 offset-xl-1 d-none d-md-block">
            <div className="text-center text-lg-end header_right_widgets">
              <ul className="mb0 d-flex justify-content-center justify-content-sm-end">
                <li>
                  <span
                    className="text-center vendor-logout-btn"
                    onClick={handleLogout}
                  >
                    <span className="flaticon-exit"></span>
                  </span>
                </li>
                <li>
                  <a className="text-center">
                    <span className="flaticon-mail-inbox-app"></span>
                  </a>
                </li>
                <li>
                  <a className="text-center">
                    <span className="flaticon-notification"></span>
                  </a>
                </li>
                <li className="user_setting">
                  <div className="dropdown">
                    <a
                      className="btn dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <img src="/images/resource/user.png" alt="user.png" />
                    </a>
                    <div className="dropdown-menu">
                      <div className="user_setting_content">
                        {menus.map((menu, index) => (
                          <Link to={menu.path} key={index}>
                            <i className={`${menu.icon} mr15`}></i>
                            {menu.title}
                          </Link>
                        ))}
                        <span
                          onClick={handleLogout}
                        >
                          <i className="flaticon-exit mr15"></i>Logout
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default PortalHeader;
