import React from "react";

const TablePagination = ({ currentPage, totalPages, paginate, next, prev }) => {
  return (
    <div className="mbp_pagination mt30 text-center">
      <ul className="page_navigation">
        <li className="page-item">
          <a
            className="page-link"
            onClick={prev}
            aria-disabled={currentPage === 1}
          >
            <span className="fas fa-angle-left"></span>
          </a>
        </li>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <a onClick={() => paginate(number)} className="page-link">
              {number}
            </a>
          </li>
        ))}
        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <a
            className="page-link"
            onClick={() => currentPage < totalPages && next()}
            aria-disabled={currentPage === totalPages}
          >
            <span className="fas fa-angle-right"></span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default TablePagination;
