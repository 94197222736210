import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../user/context/AuthContext";
import { adminLogin, vendorLogin } from "../../vendor/services/auth";

const PortalLogin = ({ isVendorUser }) => {
  const { loader, token, user, onLogin } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response;
      if (isVendorUser) {
        response = await vendorLogin(form);

        if (response.status !== 200) {
          setError(response.message);
        } else {
          const { access, user } = response;
          const data = {
            access,
            user: {
              ...user,
              type: "vendor",
            },
          };
          onLogin(data);
          navigate("/vendor/dashboard");
        }
      } else {
        response = await adminLogin(form);
        const { access, email } = response;
        const data = {
          access,
          user: {
            email,
            type: "admin",
          },
        };
        onLogin(data);
        navigate("/admin/dashboard");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (token && !loader && user.type === "vendor" && isVendorUser)
    return <Navigate to="/vendor/dashboard/" />;

  if (token && !loader && user.type === "admin" && !isVendorUser)
    return <Navigate to="/admin/dashboard/" />;

  return (
    <>
      <header className="site-header">
        <div className="container">
          <img src="/mrzzm-images/logo_img.png" alt="Logo" className="logo" />
        </div>
      </header>
      <section className="our-log-reg bgc-f5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-5 col-xxl-4 m-auto">
              <div className="log_reg_form mt70-992">
                <h2 className="title">
                  Sign-In {isVendorUser ? "Vendor" : "Admin"}
                </h2>
                <div className="login_form">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-2 mr-sm-2">
                      <label className="form-label">Email address</label>
                      <input
                        value={form.email}
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb5">
                      <label className="form-label">Password</label>
                      <input
                        value={form.password}
                        onChange={handleChange}
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                      />
                    </div>

                    {error && <h4 style={{ color: "red" }}>{error}</h4>}

                    <button type="submit" className="btn btn-log btn-thm mt20">
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container bdrt1 pt20 pb20">
        <div className="row">
          <div className="col-lg-6">
            <div className="copyright-widget text-center text-lg-start d-block d-lg-flex mb15-md">
              <p className="me-4">© 2023 Mrzzm. All Rights Reserved</p>
              <p>
                <a href="#">Privacy</a> · <a href="#">Terms</a> ·
                <a href="#">Sitemap</a>
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="footer_bottom_right_widgets text-center text-lg-end">
              <ul className="mb0">
                <li className="list-inline-item mb20-340">
                  <div className="dropdown bootstrap-select show-tick">
                    <select className="selectpicker show-tick">
                      <option>Currency : USD</option>
                      <option>Euro</option>
                      <option>Pound</option>
                    </select>
                    <button
                      type="button"
                      className="btn dropdown-toggle btn-light"
                      data-bs-toggle="dropdown"
                      role="combobox"
                      aria-owns="bs-select-2"
                      aria-haspopup="listbox"
                      aria-expanded="false"
                      title="Currency : USD"
                    >
                      <div className="filter-option">
                        <div className="filter-option-inner">
                          <div className="filter-option-inner-inner">
                            Currency : USD
                          </div>
                        </div>
                      </div>
                    </button>
                    <div className="dropdown-menu ">
                      <div
                        className="inner show"
                        role="listbox"
                        id="bs-select-2"
                      >
                        <ul
                          className="dropdown-menu inner show"
                          role="presentation"
                        ></ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div className="dropdown bootstrap-select show-tick">
                    <select className="selectpicker show-tick">
                      <option>Language: English</option>
                      <option>Frenc</option>
                      <option>Italian</option>
                      <option>Spanish</option>
                      <option>Turkey</option>
                    </select>
                    <button
                      type="button"
                      className="btn dropdown-toggle btn-light"
                      data-bs-toggle="dropdown"
                      role="combobox"
                      aria-owns="bs-select-3"
                      aria-haspopup="listbox"
                      aria-expanded="false"
                      title="Language: English"
                    >
                      <div className="filter-option">
                        <div className="filter-option-inner">
                          <div className="filter-option-inner-inner">
                            Language: English
                          </div>
                        </div>
                      </div>
                    </button>
                    <div className="dropdown-menu ">
                      <div
                        className="inner show"
                        role="listbox"
                        id="bs-select-3"
                      >
                        <ul
                          className="dropdown-menu inner show"
                          role="presentation"
                        ></ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalLogin;
