import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import HeaderMiddle from "./HeaderMiddle";
import HiddenCartSideBar from "./HiddenCartSidebar";
import MainHeaderNav from "./MainHeaderNav";
import SignInHiddenSidebar from "./SignInHiddenSidebar";
import SignUpHiddenSideBar from "./SignUpHiddenSideBar";
import MobileNavBar from "./MobileNavBar";
import { Provider } from "react-redux";
import { store } from "../../store/store";
import ScrollToTopButton from "./ScrollToTopButton";

const UserLayout = () => {
  return (
    <Provider store={store}>
      <HeaderMiddle />
      <MainHeaderNav />
      <div className="hiddenbar-body-ovelay" />
      <SignInHiddenSidebar />
      <HiddenCartSideBar />
      <SignUpHiddenSideBar />
      <MobileNavBar />
      <div className="body_content_wrapper position-relative">
        <Outlet />
        <Footer />
        <ScrollToTopButton />
      </div>
    </Provider>
  );
};

export default UserLayout;
