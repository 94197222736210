import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  deleteProductOrVariant,
  getVendorProductById,
} from "../services/vendorProducts";
import ProductForm from "../components/ProductForm";
import Preloader from "../../shared/components/Preloader";
import { getUOMs, getBrands, getTags } from "../services/common";
import { getCategoriesForProduct } from "../services/categories";
import VariantsListing from "../components/VendorProducts/VariantsListing/VariantsListing";

const UpdateProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [productData, setProductData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [brands, setBrands] = useState([]);
  const [uoms, setUOMs] = useState([]);
  const [variantIndex, setVariantIndex] = useState(-1);
  const [method, setMethod] = useState("");
  const [isShowVariants, setIsShowVariants] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await getVendorProductById(id);
      const categories = await getCategoriesForProduct();
      const tags = await getTags();
      const brands = await getBrands();
      const uoms = await getUOMs();
      setTags(tags);
      setUOMs(uoms);
      setBrands(brands);
      setCategories(categories);

      const product_variants = response.product_variant;
      setProduct({ ...response, variants: product_variants });
      const { variants, ...rest } = response;
      setProductData(rest);
      setLoading(false);
    })();
  }, [id]);

  const handleUpdateVariant = (index) => {
    setVariantIndex(index);
    setMethod("update");
    setProductData(product.variants[index]);
    setIsShowVariants(false);
  };

  // Handling Variant Create form Data i.e. the copy of the Main product.
  const handleCreateVariant = () => {
    const newData = { ...product };
    setVariantIndex(-1);
    setMethod("create");
    setProductData(newData);
    setIsShowVariants(false);
  };

  const handleToggleVariants = () =>
    setIsShowVariants((prevState) => !prevState);

  const handleVariantDelete = async (id) => {
    const { flag, status } = await deleteProductOrVariant(id, true);
    if (flag && status === 200) {
      const newVariantsArr = [...product.variants].filter(
        (variant) => variant.id !== id
      );
      setVariants(newVariantsArr);
    }
  };

  const handleVariantAdd = (variant) => {
    const newVariantsArr = [...product.variants, variant];
    setVariants(newVariantsArr);
    setIsShowVariants(true);
  };

  //
  const handleProductVariantResponse = (updatedVariant) => {
    const newVariantsArr = [...product.variants];
    newVariantsArr[variantIndex] = updatedVariant;
    setVariants(newVariantsArr);
    setIsShowVariants(true);
  };

  const setVariants = (variantsArr) => {
    setProduct((prevState) => ({
      ...prevState,
      variants: variantsArr,
    }));
  };

  if (loading) return <Preloader />;

  return (
    <div
      style={{
        marginTop: "30px",
        backgroundColor: "#F2F1EF",
      }}
    >
      <div className="d-flex justify-content-end mx-3">
        <button
          onClick={handleToggleVariants}
          className="btn btn-new btn-lg btn-thm  mt-4"
        >
          {!isShowVariants ? "View Variants" : "Hide Variants"}
        </button>
      </div>

      {isShowVariants ? (
        <VariantsListing
          variants={product.variants}
          onCreateVariant={handleCreateVariant}
          onDeleteVariant={handleVariantDelete}
          onUpdateVariant={handleUpdateVariant}
        />
      ) : (
        <ProductForm
          onAddVariant={handleVariantAdd}
          onUpdateVariant={handleProductVariantResponse}
          productTitle={`Product ${
            variantIndex === -1
              ? !method
                ? "Main"
                : "New Variant"
              : "Variant " + (variantIndex + 1)
          }`}
          id={id}
          brands={brands}
          tags={tags}
          uoms={uoms}
          categories={categories}
          isEdit={true}
          product={variantIndex === -1 ? product : productData}
          method={method}
          btnTitle={
            variantIndex === -1
              ? !method
                ? "Update Product"
                : "Create Variant"
              : "Update Variant " + (variantIndex + 1)
          }
        />
      )}
    </div>
  );
};

export default UpdateProduct;
