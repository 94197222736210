import { useSelector } from "react-redux";
import { headerMiddleComponent, navBarMenus } from "../../constants/localization";

const HeaderMiddleAdvanceSearch = () => {
  const category = useSelector((state) => state.category);
  const { language } = useSelector((state) => state.setting);

  const { categories } = category;

  return (
    <div className="col-lg-5 col-xxl-6">
      <div className="header_middle_advnc_search">
        <div className="search_form_wrapper">
          <div className="row">
            <div className="col-auto pr0">
              <div className="actegory">
                <div className="dropdown bootstrap-select">
                  <select className="selectpicker">
                    <option value="AllCategory">
                      {navBarMenus.allCategory[language]}
                    </option>
                    {categories.map((item, index) => (
                      <option key={index} value={item.name}>
                        {language === "eng" ? item.name_eng : item.name_arb}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    className="btn dropdown-toggle btn-light"
                    data-bs-toggle="dropdown"
                    role="combobox"
                    aria-owns="bs-select-1"
                    aria-haspopup="listbox"
                    aria-expanded="false"
                    title={navBarMenus.allCategory[language]}
                    data-id="selectbox_alCategory"
                  >
                    <div className="filter-option">
                      <div className="filter-option-inner">
                        <div className="filter-option-inner-inner">
                          {navBarMenus.allCategory[language]}
                        </div>
                      </div>
                    </div>
                  </button>
                  <div className="dropdown-menu ">
                    <div className="inner show" role="listbox" id="bs-select-1">
                      <ul
                        className="dropdown-menu inner show"
                        role="presentation"
                      >
                        {categories.map((item, index) => (
                          <li key={index}>
                            {language === "eng" ? item.name_eng : item.name_arb}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-auto p0">
              <div className="top-search">
                <form
                  action="#"
                  method="get"
                  className="form-search"
                  acceptCharset="utf-8"
                >
                  <div className="box-search pre_line">
                    <input
                      className="form_control"
                      type="text"
                      name="search"
                      placeholder={
                        headerMiddleComponent.searchProducts[language]
                      }
                    />
                    <div className="search-suggestions">
                      <div className="box-suggestions">
                        <ul>
                          <li>
                            <div className="thumb">
                              <img
                                src="/images/listing/sf1.png"
                                alt="sf1.png"
                              />
                            </div>
                            <div className="info-product">
                              <div className="item_title">
                                Sony DJ Headphones 4334205465, Black, Standard
                              </div>
                              <div className="price">
                                <span className="sale">$32.50</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="thumb">
                              <img
                                src="/images/listing/sf2.png"
                                alt="sf2.png"
                              />
                            </div>
                            <div className="info-product">
                              <div className="item_title">
                                Sony E-Mount Full Frame FE 24-70mm f/2.8 GM II G
                                Master
                              </div>
                              <div className="price">
                                <span className="sale">$32.50</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="thumb">
                              <img
                                src="/images/listing/sf3.png"
                                alt="sf3.png"
                              />
                            </div>
                            <div className="info-product">
                              <div className="item_title">
                                TV 55" 4-Series 4K UHD smart TV
                              </div>
                              <div className="price">
                                <span className="sale">$32.50</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="thumb">
                              <img
                                src="/images/listing/sf4.png"
                                alt="sf4.png"
                              />
                            </div>
                            <div className="info-product">
                              <div className="item_title">
                                Hugolog Baby Monitor, 2K Security Camera, PT
                                Cameras for
                              </div>
                              <div className="price">
                                <span className="sale">$32.50</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="thumb">
                              <img
                                src="/images/listing/sf5.png"
                                alt="sf5.png"
                              />
                            </div>
                            <div className="info-product">
                              <div className="item_title">
                                Apple iPhone Retina 6s Plus 64GB
                              </div>
                              <div className="price">
                                <span className="sale">$32.50</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-auto p0">
              <div
                className={`advscrh_frm_btn ${
                  language === "eng"
                    ? "advscrh_frm_btn__right"
                    : "advscrh_frm_btn__left"
                }`}
              >
                <button type="submit" className="btn search-btn">
                  <span className="flaticon-search" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMiddleAdvanceSearch;
