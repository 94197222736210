import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentVendor from "./StripePaymentVendor";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const options = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  appearance: {},
};

const PaymentContainerVendor = ({ isSubmit, amount, onConfirmPayment }) => {
  return (
    <Elements stripe={stripePromise} options={options}>
      <StripePaymentVendor
        isSubmit={isSubmit}
        amount={amount}
        onConfirmPayment={onConfirmPayment}
      />
    </Elements>
  );
};

export default PaymentContainerVendor;
