const stars = [1, 2, 3, 4, 5];

const RatingStar = ({ rating }) => {
  return (
    <span className="d-flex gap-1">
      {stars.map((star, index) => (
        <i
          style={{
            color: star <= rating ? "#f5c34b" : "",
          }}
          key={index}
          className={`${star <= rating ? "fas" : "far"} fa-star`}
        ></i>
      ))}
    </span>
  );
};

export default RatingStar;
