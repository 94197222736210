import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Preloader from "../../shared/components/Preloader";
import { getSaleOrderById } from "../services/saleOrder";
import SaleOrderItem from "../components/saleOrderDetail/saleOrderItem";

const classes = {
  DRAFT: "status-draft",
  SALE: "status-sale",
  DONE: "status-done",
  CANCELLED: "status-cancelled",
};

const SaleOrderDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [sale, setSale] = useState();
  useEffect(() => {
    (async () => {
      const response = await getSaleOrderById(id);
      setSale(response);
      setLoading(false);
    })();
  }, [id]);

  if (loading) return <Preloader />;

  const {
    customer_id,
    order_state,
    order_lines,
    delivery_id,
    delivery_address,
    order_date,
    is_active,
    invoice_amount,
    paid_amount,
    total_with_tax,
    total_without_tax,
    vendor_id,
  } = sale;
  const { name: customerName, phone, address, user } = customer_id;

  const { country, street, zip_code, city } = address;
  const { name: vendorName } = vendor_id;
  const { email } = user;
  return (
    <>
      <div className="order_detail_body">
        <div className="main-title text-center">
          <h2>Sale DETAIL</h2>
        </div>
        <div className="row">
          <div className="col-xl-8">
            <div className="application_statics">
              <div className="d-flex-justify-content-between align-items-center">
                <div>
                  <h4 className="short_code_title mb40">
                    Sale# <span>{sale.id}</span>
                    <span className={classes[order_state]}>{order_state}</span>
                  </h4>
                </div>
              </div>

              <div className="account_user_deails dashboard_page">
                <div className="order_table table-responsive">
                  <table className="table">
                    <thead className="table-light">
                      <tr className="text-center">
                        <th scope="col">Product Id</th>
                        <th scope="col">Active</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Product Qnt</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Tax</th>
                        <th scope="col">Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order_lines.map((saleOrderItem) => (
                        <SaleOrderItem
                          key={saleOrderItem.id}
                          saleOrderItem={saleOrderItem}
                        />
                      ))}
                    </tbody>
                  </table>
                  <hr />
                  <table className="table sub-table">
                    <tbody>
                      <tr>
                        <td className="sub-table-label">Sub Total:</td>
                        <td className="sub-table-value">${total_with_tax}</td>
                      </tr>
                      <tr>
                        <td className="sub-table-label">
                          Discount (VELZON15):
                        </td>
                        <td className="sub-table-value">-$0</td>
                      </tr>
                      <tr>
                        <td className="sub-table-label">Shipping Charge:</td>
                        <td className="sub-table-value">$0</td>
                      </tr>
                      <tr>
                        <td className="sub-table-label">Estimated Tax:</td>
                        <td className="sub-table-value">$0</td>
                      </tr>
                      <tr>
                        <td className="sub-table-label total">Total (USD):</td>
                        <td className="sub-table-value total">
                          ${sale.total_with_tax}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 application_statics">
            <div className="card">
              <div className="card-header">
                <div className="d-flex">
                  <h5 className="card-title flex-grow-1 mb-0">
                    Customer Details
                  </h5>
                </div>
              </div>
              <div className="card-body">
                <ul className="list-unstyled mb-0 vstack gap-3">
                  <li>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="flaticon-customer"></i>
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-14 mb-1">{customerName}</h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <i className="ri-mail-line me-2 align-middle text-muted fs-16 flaticon-mail-inbox-app"></i>
                    {email}
                  </li>
                  <li>
                    <i className="ri-phone-line me-2 align-middle text-muted fs-16 flaticon-phone-call"></i>
                    {phone}
                  </li>
                  <li>
                    <i className="ri-phone-line me-2 align-middle text-muted fs-16 fas fa-location"></i>
                    {`${street}, ${city}, ${zip_code}, ${country}`}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SaleOrderDetail;
