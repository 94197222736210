import React, { createContext, useContext, useEffect, useState } from "react";
import { vendorMenus, adminMenus } from "../../data/menus";

const intial = {
  user: null,
  token: "",
  menus: [],
  onLogin: (data) => {},
  onLogout: () => {},
  loader: true,
};

const AuthContext = createContext(intial);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loader, setLoader] = useState(true);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data) {
      const storedToken = JSON.parse(localStorage.getItem("token"));
      setToken(storedToken);
      setMenus(
        data.type === "vendor"
          ? vendorMenus
          : data.type === "admin"
          ? adminMenus
          : []
      );
      setUser(data);
    }
    setLoader(false);
  }, []);

  const login = (userData) => {
    const token = userData.access;
    const data = userData.user;

    localStorage.removeItem("appUser");
    localStorage.removeItem("token");
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("user", JSON.stringify(data));

    setToken(token);
    setUser(data);
    setMenus(
      data.type === "vendor"
        ? vendorMenus
        : data.type === "admin"
        ? adminMenus
        : []
    );
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    setToken(null);
    setMenus([]);
  };

  return (
    <AuthContext.Provider
      value={{ user, onLogin: login, onLogout: logout, token, loader, menus }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
