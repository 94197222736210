import axiosInstance from "../../interceptor/axiosInstance";

export const getVendorProducts = async () => {
  const response = await axiosInstance.get("/vendor/products/list/");
  return response.data.data || [];
};

export const getVendorProductById = async (id) => {
  const response = await axiosInstance.get("/vendor/products/details?id=" + id);
  return response.data.data[0];
};

export const deleteProductOrVariant = async (id) => {
  const response = await axiosInstance.delete("/products/delete/?id=" + id);
  return response.data;
};

export const createVendorProductOrVariant = async (data) => {
  const response = await axiosInstance.post("/products/create/", { ...data });
  return response.data;
};

export const updateProductOrVariant = async (id, data) => {
  const response = await axiosInstance.patch("/products/update/?id=" + id, {
    ...data,
  });
  return response.data;
};
