import React, { useEffect, useState } from "react";
import { getProductById } from "../services/product";
import { useParams } from "react-router-dom";
import Preloader from "../../shared/components/Preloader";
import DisplayProduct from "../components/ProductDetail/DisplayProduct";
import Error from "../../shared/components/Error";
import Breadcrumb from "../components/Layout/BreadCrumb";
import { useSelector } from "react-redux";

const ProductDetail = () => {
  const [product, setProduct] = useState(null);
  const { productId } = useParams();
  const [loading, setLoading] = useState(true);
  const [variantIndex, setVariantIndex] = useState(null);
  const [productVariants, setProductVariants] = useState([]);
  const { language } = useSelector((state) => state.setting);

  useEffect(() => {
    productId && getProduct();
  }, [productId]);

  const getProduct = async () => {
    const response = await getProductById(productId);

    if (!response) {
      setLoading(false);
    } else {
      // ProductPTA Main Product
      const productPTA = response.product_template_attributes || [];
      // Main Product Variants
      const productPPAs =
        response.product_variant.map((v) => v.product_template_attributes) ||
        [];

      const allPTAS = [
        productPTA.map((pt) => ({
          attribute_name: pt.attribute_name,
          attribute_value: pt.attribute_value,
        })),
        ...productPPAs,
      ];

      // Making Full detail/description of the product from PPA/PTA
      const variantDescriptionArr = allPTAS.map((p) => constructVairant(p));

      // Final is the array consists of image and the description
      const final = variantDescriptionArr.map((v, i) =>
        i === 0
          ? {
              image:
                response.main_image || response.images.length > 0
                  ? response.images[0].images
                  : "",
              description: v,
            }
          : {
              image:
                response.product_variant[i - 1].main_image ||
                response.product_variant[i - 1].images.length > 0
                  ? response.product_variant[i - 1].images[0].images
                  : "",
              description: v,
            }
      );
      setProductVariants(final);
      setProduct(response);
      setLoading(false);
    }
  };

  const handleVariantIndex = (index) => {
    setVariantIndex(index);
  };

  const constructVairant = (arr = []) =>
    arr
      .map((attr) => `${attr.attribute_name} (${attr.attribute_value})`)
      .join(" | ");

  if (!product && loading) return <Preloader />;

  if (!product && !loading) return <Error error="No Product Found" />;

  return (
    <section className="shop-single-content pb80 pt0 ovh">
      {/* <Breadcrumb
        productName={language === "eng" ? product.name_eng : product.name_arb}
      /> */}
      <div className="container">
        <DisplayProduct
          variantIndex={variantIndex}
          onChangeIndex={handleVariantIndex}
          variants={productVariants}
          product={
            variantIndex !== null // If variantIndex null it means the product is main else the product is variant
              ? { ...product.product_variant[variantIndex] }
              : product
          }
        />
      </div>
    </section>
  );
};

export default ProductDetail;
