import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Layout/BreadCrumb";

const orderMessage = {
  eng: "Your Order Has Been PLaced",
  arb: "وقد وضعت طلبك",
};

const shoppingText = {
  eng: "Continue Shopping",
  arb: "مواصلة التسوق",
};

const ThankYou = () => {
  const { language } = useSelector((state) => state.setting);

  return (
    <section className="our-error">
      {/* <Breadcrumb /> */}

      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="animate_content text-center text-xl-start">
              <div className="animate_thumb">
                <img src="/mrzzm-images/order_placed.svg" alt="Thank You" />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="error_page_content mt80 mt50-lg text-center text-xl-start">
              <div className="erro_code">
                <span className="text-thm">{orderMessage[language]}</span>
              </div>
              <Link to={"/shop"} className="btn-thm btn_error">
                {shoppingText[language]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
