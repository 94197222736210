import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { checkOutPage } from "../../constants/localization";
import { useAuth } from "../../context/AuthContext";

const countries = [
  "Turkey",
  "United Kingdom",
  "United States",
  "Ukraine",
  "Uruguay",
  "UK",
  "Uzbekistan",
  "India",
  "China",
  "Brazil",
  "Canada",
  "Australia",
  "Germany",
  "France",
  "Japan",
  "Italy",
  "Spain",
  "Russia",
  "South Korea",
  "Mexico",
  "Indonesia",
  "Netherlands",
  "Saudi Arabia",
  "Switzerland",
  "Sweden",
];

const options = countries.map((country) => ({
  label: country,
  value: country,
}));

const CheckOutForm = ({ userForm, onChange, onSelect }) => {
  const { language } = useSelector((state) => state.setting);
  const { user } = useSelector((state) => state.userAuth);
  return (
    <form className="form2" id="coupon_form" name="contact_form">
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label className="form-label">
              {checkOutPage.firstName[language]}*
            </label>
            <input
              onChange={onChange}
              name="first_name"
              className="form-control form_control"
              type="text"
              placeholder={checkOutPage.firstName[language]}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="form-label">
              {checkOutPage.lastName[language]}*
            </label>
            <input
              onChange={onChange}
              name="last_name"
              className="form-control form_control"
              type="text"
              placeholder={checkOutPage.lastName[language]}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="form-label">
              {checkOutPage.taxId[language]}*
            </label>
            <input
              onChange={onChange}
              name="tax_id"
              className="form-control form_control"
              type="text"
              placeholder={checkOutPage.taxId[language]}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label className="form-label">
              {checkOutPage.countryRegion[language]}*
            </label>
            <ReactSelect
              options={options}
              value={userForm.country}
              onChange={(value) => onSelect("country", value)}
            />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label className="form-label">
              {checkOutPage.address[language]}
            </label>
            <input
              onChange={onChange}
              name="address"
              className="form-control form_control mb10"
              type="text"
              placeholder={checkOutPage.address[language]}
            />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label className="form-label">
              {checkOutPage.street[language]}*
            </label>
            <input
              onChange={onChange}
              name="street"
              className="form-control form_control mb10"
              type="text"
              placeholder={checkOutPage.street[language]}
            />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label className="form-label">{checkOutPage.city[language]}*</label>
            <input
              onChange={onChange}
              name="city"
              className="form-control form_control"
              type="text"
              placeholder={checkOutPage.city[language]}
            />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label className="form-label">
              {checkOutPage.zipCode[language]}*
            </label>
            <input
              onChange={onChange}
              name="zip_code"
              className="form-control form_control"
              type="text"
              placeholder={checkOutPage.zipCode[language]}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="form-group">
            <label className="form-label">
              {checkOutPage.phone[language]}*
            </label>
            <input
              onChange={onChange}
              name="phone"
              className="form-control form_control"
              type="number"
              placeholder={checkOutPage.phone[language]}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="form-label">
              {checkOutPage.email[language]}*
            </label>

            {user && user.email && (
              <input
                value={user.email}
                disabled={true}
                className="form-control form_control email"
                type="email"
                placeholder={checkOutPage.email[language]}
              />
            )}
            {!user && (
              <input
                required
                onChange={onChange}
                name="email"
                className="form-control form_control email"
                type="email"
                placeholder={checkOutPage.email[language]}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default CheckOutForm;
