import { Link } from "react-router-dom";
import CategoriesMenuDropDown from "./CategoriesMenuDropdown";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { navBarMenus } from "../../constants/localization";

const MainHeaderNav = () => {
  const { language } = useSelector((state) => state.setting);
  useEffect(() => {
    const element = document.getElementById("respMenu");
    if (element) {
      const listItems = element.getElementsByTagName("li");
      for (let i = 0; i < listItems.length; i++) {
        listItems[i].style.textAlign = language === "eng" ? "left" : "right";
      }
    }
  }, [language]);

  return (
    <header className="header-nav menu_style_home_one main-menu">
      <nav className="posr">
        <div className="container posr menu_bdrt1">
          <div className="menu-toggle">
            <button type="button" id="menu-btn">
              <span className="icon-bar" /> <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
          </div>
          <div className="posr logo1 home1_style">
            <div id="mega-menu">
              <a className="btn-mega">
                <img
                  className="me-2"
                  src="/images/desktop-nav-menu-white.svg"
                  alt="Desktop Menu Icon"
                />
                <span
                  className={`fw500 fz16 color-white vam ${
                    language === "eng" ? "" : "mx-2"
                  }`}
                >
                  {navBarMenus.browseCategories[language]}
                </span>
              </a>
              <CategoriesMenuDropDown />
            </div>
          </div>
          <ul
            id="respMenu"
            className={`ace-responsive-menu menu_list_custom_code wa ${
              language === "eng"
                ? "ace_responsive_menu__left"
                : "ace_responsive_menu__right"
            }`}
            data-menu-style="horizontal"
          >
            <li>
              <Link to="/">
                <span className="title">{navBarMenus.home[language]}</span>
              </Link>
            </li>
            <li className="megamenu_style">
              <Link to="/shop">
                <span className="title">{navBarMenus.shop[language]}</span>
              </Link>
            </li>
            <li>
              <a>
                <span className={`title ${language === "arb" ? "mx-2" : ""}`}>
                  {navBarMenus.pages[language]}
                </span>
                <span className="arrow"></span>
              </a>
              <ul>
                <li>
                  <a href="page-about.html">{navBarMenus.aboutUs[language]}</a>
                </li>
                <li>
                  <a>
                    {language === "arb" && (
                      <span
                        style={{
                          float: "left",
                        }}
                        className="fa-solid fa-angle-right"
                      ></span>
                    )}
                    <span className="title">
                      {navBarMenus.accounts[language]}
                    </span>
                    {language === "eng" && <span className="arrow"></span>}
                  </a>
                  <ul>
                    <li>
                      <a href="page-account-details.html">
                        {navBarMenus.accountDetails[language]}
                      </a>
                    </li>
                    <li>
                      <a href="page-account-order.html">
                        {navBarMenus.accountOrder[language]}
                      </a>
                    </li>
                    <li>
                      <a href="page-account-address.html">
                        {navBarMenus.accountAddress[language]}
                      </a>
                    </li>
                    <li>
                      <a href="page-account-wishlist.html">
                        {navBarMenus.accountWishList[language]}
                      </a>
                    </li>
                    <li>
                      <a href="page-account-invoice.html">
                        {navBarMenus.accountInvoice[language]}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="page-become-vendor.html">
                    {navBarMenus.becomeVendor[language]}
                  </a>
                </li>
                <li>
                  <a>
                    {language === "arb" && (
                      <span
                        style={{
                          float: "left",
                        }}
                        className="fa-solid fa-angle-right"
                      ></span>
                    )}

                    <span className="title">
                      {navBarMenus.vendorPages[language]}
                    </span>
                    {language === "eng" && <span className="arrow"></span>}
                  </a>
                  <ul>
                    <li>
                      <a>{navBarMenus.vendorList[language]}</a>
                    </li>
                    <li>
                      <a>{navBarMenus.vendorSingle[language]}</a>
                    </li>
                    <li>
                      <a>{navBarMenus.dashboard[language]}</a>
                    </li>
                    <li>
                      <a href="page-dashboard-products.html">
                        {navBarMenus.products[language]}
                      </a>
                    </li>
                    <li>
                      <a href="page-dashboard-order.html">
                        {navBarMenus.order[language]}
                      </a>
                    </li>
                    <li>
                      <a href="page-dashboard-customer.html">
                        {navBarMenus.customer[language]}
                      </a>
                    </li>
                    <li>
                      <a href="page-dashboard-categories.html">
                        {navBarMenus.categories[language]}
                      </a>
                    </li>
                    <li>
                      <a href="page-dashboard-message.html">
                        {navBarMenus.message[language]}
                      </a>
                    </li>
                    <li>
                      <a href="page-dashboard-setting.html">
                        {navBarMenus.settings[language]}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="page-brands.html">{navBarMenus.brands[language]}</a>
                </li>
                <li>
                  <a href="page-contact.html">
                    {navBarMenus.contact[language]}
                  </a>
                </li>
                <li>
                  <a href="page-coming-soon.html">
                    {navBarMenus.comingSoon[language]}
                  </a>
                </li>
                <li>
                  <a href="page-help.html">{navBarMenus.help[language]}</a>
                </li>
                <li>
                  <a href="page-error.html">{navBarMenus.error404[language]}</a>
                </li>
                <li>
                  <a href="page-faq.html">{navBarMenus.faq[language]}</a>
                </li>
                <li>
                  <a href="page-invoices.html">
                    {navBarMenus.invoices[language]}
                  </a>
                </li>
                <li>
                  <a href="page-login.html">{navBarMenus.login[language]}</a>
                </li>
                <li>
                  <a href="page-register.html">
                    {navBarMenus.register[language]}
                  </a>
                </li>
                <li>
                  <a href="page-terms.html">{navBarMenus.terms[language]}</a>
                </li>
                <li>
                  <a href="page-ui-element.html">
                    {navBarMenus.uiElements[language]}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a>
                <span className={`title ${language === "arb" ? "mx-2" : ""}`}>
                  {navBarMenus.blog[language]}
                </span>
                <span className="arrow"></span>
              </a>
              <ul>
                <li>
                  <a href="page-blog-grid.html">
                    {navBarMenus.blogGrid[language]}
                  </a>
                </li>
                <li>
                  <a href="page-blog-grid-sidebar.html">
                    {navBarMenus.blogGridSidebar[language]}
                  </a>
                </li>
                <li>
                  <a href="page-blog-details.html">
                    {navBarMenus.blogDetails[language]}
                  </a>
                </li>
                <li>
                  <a href="page-blog-list.html">
                    {navBarMenus.blogList[language]}
                  </a>
                </li>
                <li>
                  <a href="page-blog-single.html">
                    {navBarMenus.blogSingle[language]}
                  </a>
                </li>
                <li>
                  <a href="page-blog-single2.html">
                    {navBarMenus.blogSingleV2[language]}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul
            id="respMenu2"
            className={`ace-responsive-menu widget_menu_home2 wa  ${
              language === "eng"
                ? "widget_menu_home2__right"
                : "widget_menu_home2__left"
            }`}
            data-menu-style="horizontal"
          >
            <li className="list-inline-item list_c">
              <a>{navBarMenus.dealOfTheDay[language]}</a>
            </li>
            <li className="list-inline-item list_c">
              <a>{navBarMenus.hotDeals[language]}</a>
            </li>
            <li className="list-inline-item list_c">
              <a>{navBarMenus.bestSellers[language]}</a>
            </li>
            <li className="list-inline-item list_c">
              <a>{navBarMenus.newArrivals[language]}</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default MainHeaderNav;
