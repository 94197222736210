import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import OrderFilter from "../components/VendorOrders/OrderFilter";
import VendorOrderTable from "../components/VendorOrders/Table/OrderTable";
import { getVendorAllOrders } from "../services/order";
import { useAuth } from "../../user/context/AuthContext";

const VendorOrders = () => {
  const { loader, token } = useAuth();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    status: "",
    search: "",
  });
  useEffect(() => {
    (async () => {

      const response = await getVendorAllOrders();
      setOrders(response);
      setLoading(false);
    })();
  }, []);

  if (loading || loader) return <Preloader />;

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  if (loading) return <Preloader />;

  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Orders</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <OrderFilter filter={filter} onChangeFilter={handleFilterChange} />
            <VendorOrderTable orders={orders} filter={filter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorOrders;
