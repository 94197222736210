import { useSelector } from "react-redux";
import CategoryCard from "./CategoryCard";
import { categoryListingComponent } from "../../constants/localization";

const CategoryListing = () => {
  const { categories } = useSelector((state) => state.category);
  const { language } = useSelector((state) => state.setting);
  return (
    categories.length > 0 && (
      <section className="top-category">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <div className="main-title">
                  <h2>{categoryListingComponent.shopBycategory[language]}</h2>
                </div>
                <div className="main-title mb-5">
                  <a
                    className="title_more_btn mt10"
                    href="page-shop-list-v2.html"
                  >
                   {categoryListingComponent.viewAllCategories[language]}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row ovh">
            {categories.map((item, index) => (
              <CategoryCard key={index} categoryItem={item} />
            ))}
          </div>
        </div>
      </section>
    )
  );
};

export default CategoryListing;
