import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

export const settingSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    onUserLogin(state, action) {
      const { user, token } = action.payload;
      state.user = user;
      localStorage.removeItem("user");
      localStorage.removeItem("token");

      localStorage.setItem("appUser", JSON.stringify(user));
      localStorage.setItem("token", JSON.stringify(token));
    },
    onUserLogOut(state, action) {
      state.user = null;
      localStorage.removeItem("appUser");
      localStorage.removeItem("token");
    },
    getLoginUser(state, action) {
      const value = localStorage.getItem("appUser");
      state.user = JSON.parse(value) || null;
    },
  },
});

export const { onUserLogin, onUserLogOut, getLoginUser } = settingSlice.actions;

export default settingSlice.reducer;
