import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`scrollToHomeCustum ${showButton ? "show" : ""}`}
    >
      <i className="fas fa-angle-up"></i>
    </button>
  );
};

export default ScrollToTopButton;
