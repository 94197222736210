import { Link } from "react-router-dom";

const NoProductFound = () => {
  return (
    <section className="our-error">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="animate_content text-center text-xl-start">
              <div className="animate_thumb">
                <img
                  src="/images/resource/error-page-img.svg"
                  alt="error-page-img"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="error_page_content mt80 mt50-lg text-center text-xl-start">
              <div className="erro_code">
                No<span className="text-thm">Product Found</span>
              </div>
              <Link to="/" className="btn-thm btn_error">
                Go Back To Homepages
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoProductFound;
