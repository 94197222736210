import InvoiceHeader from "./InvoiceHeader";
import InvoiceTableBody from "./InvoiceTableBody";

import TablePagination from "../../../../shared/components/TablePagination";
import usePagination from "../../../../hooks/usePagination";

const InvoiceTable = ({ filter, invoices, onView }) => {
  const { status, search } = filter;
  let filteredInvoices = invoices;
  if (status && status !== "ALL") {
    filteredInvoices = filteredInvoices.filter(
      (invoice) => invoice.state === status
    );
  }

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredInvoices = filteredInvoices.filter((invoice) =>
      invoice.tracking_reference.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredInvoices, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <InvoiceHeader />
          <InvoiceTableBody onView={onView} invoices={currentData()} />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default InvoiceTable;
