import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import { getDeliveries } from "../services/order";
import VendorDeliveryTable from "../components/Deliveries/Table/VendorDeliveryTable";
import VendorDeliveryFilter from "../components/Deliveries/VendorDeliveryFilter";
import Modal from "../../shared/components/Modal";
import DeliveryDetailItem from "../components/OrderDetail/DeliveryDetailItem";
import DottedLoading from "../../shared/components/DottedLoading";

const VendorDeliveries = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [isViewDelivery, setIsViewDelivery] = useState(false);
  const [dottedLoader, setDottedLoader] = useState(false);
  const [delivery, setDelivery] = useState({
    id: null,
    index: null,
  });
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    status: "",
    search: "",
  });

  const [isRefund, setIsRefund] = useState(false);

  useEffect(() => {
    getDeliveryRequest();
  }, []);

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  const getDeliveryRequest = async () => {
    const response = await getDeliveries(isRefund);
    setDeliveries(response);
    setLoading(false);
  };

  const handleDeliveries = async (is_refund) => {
    setDottedLoader((prevState) => !prevState);
    const response = await getDeliveries(is_refund);
    setIsRefund(is_refund);
    setDeliveries(response);
    setDottedLoader((prevState) => !prevState);
  };

  if (loading) return <Preloader />;

  const handleModalDelivery = (id, index) => {
    setIsViewDelivery(true);
    setDelivery({ id, index });
  };

  const handleClearDeliveryModal = () => {
    setIsViewDelivery(false);
    setDelivery({
      id: null,
      index: null,
    });
  };

  return (
    <>
      <div className="dashboard__content bgc-gmart-gray">
        <div className="row pb50">
          <div className="col-lg-12">
            <div className="dashboard_title_area">
              <h2>Deliveries</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="dashboard_product_list account_user_deails">
              <VendorDeliveryFilter
                filter={filter}
                onChangeFilter={handleFilterChange}
                isRefund={isRefund}
                onRefund={handleDeliveries}
              />
              {dottedLoader ? (
                <DottedLoading />
              ) : (
                <VendorDeliveryTable
                  deliveries={deliveries}
                  onView={handleModalDelivery}
                  filter={filter}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={isViewDelivery} onClose={handleClearDeliveryModal} title="">
        <DeliveryDetailItem delivery={deliveries[delivery.index]} />
      </Modal>
    </>
  );
};

export default VendorDeliveries;
