import DeliveryTableRow from "./DeliveryTableRow";

const DeliveryTableBody = ({ deliveries, onView }) => {
  return (
    <tbody style={{ width: "100%" }}>
      {deliveries.map((deliveryItem, index) => (
        <DeliveryTableRow
          key={index}
          onView={onView}
          deliveryItem={deliveryItem}
          index={index}
        />
      ))}
    </tbody>
  );
};

export default DeliveryTableBody;
