import OrderItem from "./OrderItem";

const OrderListing = ({ recentOrders }) => {
  return (
    <div className="row">
      <div className="col-xl-8">
        <div className="application_statics">
          <h4 className="title pl30 mb30">Recent Orders</h4>
          <div className="account_user_deails dashboard_page">
            <div className="order_table table-responsive">
              <table className="table">
                <thead className="table-light">
                  <tr className="text-center">
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Paid Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Invoice Amount</th>
                    <th scope="col">Total W/Tax</th>
                    <th scope="col">Total W/O Tax</th>
                  </tr>
                </thead>
                <tbody>
                  {recentOrders.map((order) => (
                    <OrderItem key={order.id} order={order} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderListing;
