import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import ShoppingCart from "../pages/ShoppingCart";
import ProductDetail from "../pages/productDetail";
import CheckOut from "../pages/checkout";
import Shop from "../pages/shop";
import SignIn from "../pages/signIn";
import Register from "../pages/register";
import ThankYou from "../pages/thankyou";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MyOrders from "../pages/myorders";
import MyReviews from "../pages/myReviews";
import UserPrivateRoute from "../components/UserPrivateRoute/UserPrivateRoute";
import MyDeliveries from "../pages/myDeliveries";
import MyDeliveryDetail from "../pages/deliveryDetail";
// import UserPrivateRoute from "../components/UserPrivateRoute/UserPrivateRoute";
const UserRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/shopping-cart" element={<ShoppingCart />} />
      <Route path="/product-detail/:productId" element={<ProductDetail />} />
      <Route path="/checkout" element={<CheckOut />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/register" element={<Register />} />
      <Route path="/thankyou" element={<ThankYou />} />
      <Route
        path="/my-orders"
        element={
          <UserPrivateRoute>
            <MyOrders />
          </UserPrivateRoute>
        }
      />
      <Route
        path="/my-reviews"
        element={
          <UserPrivateRoute>
            <MyReviews />
          </UserPrivateRoute>
        }
      />

      <Route
        path="/my-deliveries"
        element={
          <UserPrivateRoute>
            <MyDeliveries />
          </UserPrivateRoute>
        }
      />

      <Route
        path="/my-deliveries/:deliveryId/detail"
        element={
          <UserPrivateRoute>
            <MyDeliveryDetail />
          </UserPrivateRoute>
        }
      />

      {/* <Route element={<UserPrivateRoute />}>
        <Route path="/my-orders" element={<MyOrders />} />
      </Route>
      <Route element={<UserPrivateRoute />}>
        <Route path="/my-reviews" element={<MyReviews />} />
      </Route> */}
    </Routes>
  );
};

export default UserRoutes;
