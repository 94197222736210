import VendorDeliveryTableRow from "./VendorDeliveryTableRow";

const VendorDeliveryTableBody = ({ deliveries, onView }) => {
  return (
    <tbody style={{ width: "100%" }}>
      {deliveries.map((orderItem, index) => (
        <VendorDeliveryTableRow
          key={orderItem.id}
          orderItem={orderItem}
          onView={onView}
          index={index}
        />
      ))}
    </tbody>
  );
};

export default VendorDeliveryTableBody;
