import { useDispatch } from "react-redux";
import {
  decrementQuantity,
  incrementQuantity,
  removeProduct,
} from "../../store/cart/cartSlice";
import { decimalNumberUpto2 } from "../../utils/decimalNumberUpto2";
import { URL_PATH } from "../../../shared/constants/common";

const ShoppingCartItem = ({ item }) => {
  const dispatch = useDispatch();
  const {
    id,
    main_image,
    name_eng,
    name_arb,
    sale_price,
    quantity,
    variant_id,
    product_id,
  } = item;
  const handleIncrement = () => {
    dispatch(incrementQuantity({ variant_id, product_id }));
  };
  const handleDecrement = () => {
    dispatch(decrementQuantity({ variant_id, product_id }));
  };

  const handleRemoveProduct = () => {
    dispatch(removeProduct({ variant_id, product_id }));
  };
  const getAmountWithDiscount = () => {
    const discountAmount = item.sale_price * (item.discount / 100);
    const itemTotal = item.quantity * (item.sale_price - discountAmount);
    return itemTotal;
  };
  return (
    <tr>
      <th scope="row">
        <ul className="cart_list d-block d-xl-flex">
          <li className="ps-1 ps-sm-4 pe-1 pe-sm-4">
            <img
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              src={URL_PATH + main_image}
              alt={name_eng}
            />
          </li>
          <li className="ms-2 ms-md-3">
            <div className="cart_title">{name_eng}</div>
          </li>
        </ul>
      </th>
      <td>${sale_price}</td>
      <td>
        $
        {item.discount
          ? decimalNumberUpto2(
              item.sale_price - item.sale_price * (item.discount / 100)
            )
          : "No Discount"}
      </td>

      <td>
        <div className="cart_btn">
          <div className="quantity-block">
            <button
              onClick={() => handleDecrement()}
              className="quantity-arrow-minus inner_page"
            >
              <span className="fa fa-minus"></span>
            </button>
            <input
              className="quantity-num inner_page"
              type="number"
              value={quantity}
            />
            <button
              onClick={() => handleIncrement()}
              className="quantity-arrow-plus inner_page"
            >
              <span className="fas fa-plus"></span>
            </button>
          </div>
        </div>
      </td>
      <td>{decimalNumberUpto2(getAmountWithDiscount())}</td>
      <td>
        <span
          onClick={() => handleRemoveProduct()}
          className="flaticon-close"
        ></span>
      </td>
    </tr>
  );
};

export default ShoppingCartItem;
