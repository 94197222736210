import { Link } from "react-router-dom";

const PortalFooter = () => {
  return (
    <footer className="dashboard_footer pt30 pb30">
      <div className="container">
        <div className="row items-center justify-content-center justify-content-md-between">
          <div className="col-auto">
            <div className="copyright-widget text-center text-lg-start d-block d-lg-flex mb15-md">
              <p className="me-4">© 2023 Mrzzm. All Rights Reserved</p>
              <p>
                <Link to="/privacy">Privacy</Link> ·
                <Link to="/terms">Terms</Link> ·
                <Link to="/sitemap">Sitemap</Link>
              </p>
            </div>
          </div>
          <div className="col-auto">
            <div className="footer_bottom_right_widgets text-center text-lg-end">
              <ul className="mb0">
                <li className="list-inline-item mb20-340">
                  <select className="selectpicker show-tick">
                    <option>Currency : USD</option>
                    <option>Euro</option>
                    <option>Pound</option>
                  </select>
                </li>
                <li className="list-inline-item">
                  <select className="selectpicker show-tick">
                    <option>Language: English</option>
                    <option>French</option>
                    <option>Italian</option>
                    <option>Spanish</option>
                    <option>Turkey</option>
                  </select>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PortalFooter;
