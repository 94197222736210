import DashboardCard from "./DashboardCard";

const DashboardStatistics = ({ dashboardStat }) => {
  const { all_total_subtotal, all_orders, posted_total_subtotal } =
    dashboardStat;
  return (
    <div className="row">
      <DashboardCard
        icon="flaticon-money-bag"
        title="Total Earnings"
        count={`$${all_total_subtotal}`}
        subtitle={`${Number(
          (all_total_subtotal - posted_total_subtotal).toFixed(3)
        )} New Sales`}
      />
      <DashboardCard
        subtitle={`${all_orders} New Order`}
        icon="flaticon-sent"
        title="Order"
        count={all_orders}
      />
      <DashboardCard
        subtitle="90+ New Customer"
        icon="flaticon-customer"
        title="Customer"
        count={345}
      />
      <DashboardCard
        subtitle={`${posted_total_subtotal} Balance`}
        icon="flaticon-wallet"
        title="My Balance"
        count={`$${posted_total_subtotal}`}
      />
    </div>
  );
};

export default DashboardStatistics;
