import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartProducts: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addProduct(state, action) {
      // check Product Aleady Added or not
      const index = state.cartProducts.findIndex(
        (product) => product.id === action.payload.id
      );
      if (index > -1) {
        state.cartProducts[index].quantity++;
      } else state.cartProducts.push({ ...action.payload, quantity: 1 });
    },
    removeProduct(state, action) {
      const variantId = action.payload.variant_id;
      const productId = action.payload.product_id;
      state.cartProducts = state.cartProducts.filter(
        (product) =>
          product.variant_id !== variantId && product.product_id !== productId
      );
    },

    addProductByQty(state, action) {
      // First Check Item already there or not

      // check variant id null
      const variantId = action.payload.variant_id;
      const productId = action.payload.product_id;

      let index;

      if (variantId === null && productId > -1) {
        index = state.cartProducts.findIndex(
          (product) => product.product_id === productId
        );
      } else if (productId === null && variantId > -1) {
        index = state.cartProducts.findIndex(
          (product) => product.variant_id === variantId
        );
      }

      if (index > -1) {
        const immerProduct = state.cartProducts[index];
        const product = JSON.parse(JSON.stringify(immerProduct));
        const quantity = product.quantity + action.payload.quantity;
        state.cartProducts[index].quantity = quantity;
      } else state.cartProducts.push({ ...action.payload });
    },
    incrementQuantity(state, action) {
      // check variant id null
      const variantId = action.payload.variant_id;
      const productId = action.payload.product_id;

      let index;

      if (variantId === null && productId > -1) {
        index = state.cartProducts.findIndex(
          (product) => product.product_id === productId
        );
      } else if (productId === null && variantId > -1) {
        index = state.cartProducts.findIndex(
          (product) => product.variant_id === variantId
        );
      }
      if (index > -1) {
        state.cartProducts[index].quantity++;
      }
    },
    decrementQuantity(state, action) {
      // check variant id null
      const variantId = action.payload.variant_id;
      const productId = action.payload.product_id;

      let index;

      if (variantId === null && productId > -1) {
        index = state.cartProducts.findIndex(
          (product) => product.product_id === productId
        );
      } else if (productId === null && variantId > -1) {
        index = state.cartProducts.findIndex(
          (product) => product.variant_id === variantId
        );
      }

      const immerProduct = state.cartProducts[index];
      const product = JSON.parse(JSON.stringify(immerProduct));

      if (product.quantity === 1) {
        state.cartProducts = state.cartProducts.filter(
          (product) =>
            product.variant_id !== variantId && product.product_id !== productId
        );
      } else state.cartProducts[index].quantity--;
    },
    clearCart(state) {
      state.cartProducts = [];
    },
  },
});

export const {
  addProduct,
  removeProduct,
  incrementQuantity,
  decrementQuantity,
  clearCart,
  addProductByQty,
} = cartSlice.actions;

// Export the reducer
export default cartSlice.reducer;
