import { useEffect, useState } from "react";
import Preloader from "../../../shared/components/Preloader";
import {
  createDelivery,
  getVendorDeliveriesBySaleOrderId,
} from "../../services/order";
import Modal from "../../../shared/components/Modal";
import DeliveryModal from "./DeliveryModal";
import DeliveryDetailItem from "./DeliveryDetailItem";
import { useNavigate } from "react-router-dom";

const DeliveryView = ({ order, orderId, is_complete_delivery }) => {
  const navigate = useNavigate();
  const [deliveries, setDeliveries] = useState([]);
  const [isCreateDelivery, setIsCreateDelivery] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isViewDelivery, setIsViewDelivery] = useState(false);
  const [delivery, setDelivery] = useState({
    id: null,
    index: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await getVendorDeliveriesBySaleOrderId(orderId);

      setDeliveries(response);
      setIsCreateDelivery(false);
      setLoading(false);
      setIsSubmit(false);
    })();
  }, [order, orderId]);

  if (loading) return <Preloader />;

  const handleModalDelivery = (id, index) => {
    setIsViewDelivery(true);
    setDelivery({ id, index });
  };

  const handleClearDeliveryModal = () => {
    setIsViewDelivery(false);
    setDelivery({
      id: null,
      index: null,
    });
  };

  const handleCreateDelivery = async (data) => {
    setIsSubmit(true);
    const deliveryData = {
      ...data,
      sale_order: +orderId,
      customer_id: order.customer_id.id,
      delivery_address: order.customer_id.address.id,
      vendor_id: order.order_lines[0].vendor_id.id,
      name: "Hard Coded Delivery Name",
      parent_id: null,
      return_reason: null,
      images: [],
    };
    await createDelivery(deliveryData);
    navigate(`/vendor/order/${orderId}/detail?mode=delivery`);
  };

  const handleBack = () => navigate(`/vendor/order/${orderId}/detail`);
  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Deliveries</h2>
          </div>
        </div>
      </div>

      {!isCreateDelivery && (
        <div className="row">
          <div className="col-xl-12">
            <div className="dashboard_product_list account_user_deails">
              <div className="d-flex justify-content-between">
                {!is_complete_delivery && (
                  <button
                    onClick={() => setIsCreateDelivery(true)}
                    className="btn btn-thm"
                  >
                    Create Delivery
                  </button>
                )}
                <button onClick={handleBack} className="btn btn-thm">
                  Back
                </button>
              </div>

              {deliveries.length > 0 ? (
                <div className="order_table table-responsive mt-4">
                  <table className="table">
                    <thead className="table-light order-table-custom">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Schedule Date</th>
                        <th scope="col">Tracking Refernce</th>
                        <th scope="col">Status</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Address</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deliveries.map((item, index) => (
                        <tr key={item.id}>
                          <th scope="row">#{item.id}</th>
                          <td>{item.name}</td>
                          <td>{item.schedule_date}</td>
                          <td>{item.tracking_reference}</td>
                          <td className="status">
                            <span>{item.delivery_state}</span>
                          </td>
                          <td>{item.customer_id.name}</td>
                          <td>{item.customer_id.address.delivery_address}</td>
                          <td className="d-flex gap-2">
                            <button
                              onClick={() =>
                                handleModalDelivery(item.id, index)
                              }
                              className="btn btn-custom"
                            >
                              <i className="fa-regular fa-eye"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <hr />
                </div>
              ) : (
                <h3 className="text-center">No Deliveries Found</h3>
              )}
            </div>
          </div>
        </div>
      )}
      {isCreateDelivery && (
        <DeliveryModal
          data={order.order_lines}
          customer={order.customer_id}
          onSubmit={handleCreateDelivery}
          onBack={() => setIsCreateDelivery(false)}
          orderId={orderId}
          isSubmit={isSubmit}
        />
      )}
      <Modal show={isViewDelivery} onClose={handleClearDeliveryModal} title="">
        <DeliveryDetailItem delivery={deliveries[delivery.index]} />
      </Modal>
    </div>
  );
};

export default DeliveryView;
