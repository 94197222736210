import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearCart } from "../store/cart/cartSlice";
import { useState } from "react";
import { createSaleOrder } from "../services/checkout";
import CheckOutForm from "../components/Checkout/CheckoutForm";
import { checkOutPage } from "../constants/localization";
import { decimalNumberUpto2 } from "../utils/decimalNumberUpto2";
import Breadcrumb from "../components/Layout/BreadCrumb";

const Checkout = () => {
  const { cartProducts } = useSelector((state) => state.cart);
  const [userForm, setUserForm] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    tax_id: "",
    bank_name: "",
    account_number: "",
    iban: "",
    country: "",
    street: "",
    address: "",
    zip_code: "",
    city: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.setting);
  const { user } = useSelector((state) => state.userAuth);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelect = (name, value) => {
    setUserForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getTotal = () =>
    cartProducts.reduce(
      (total, item) => (total = total + item.quantity * +item.sale_price),
      0
    );

  const getShippingCharges = () =>
    cartProducts.reduce(
      (total, item) => (total = total + item.delivery_fee),
      0
    );
  const handlePlaceOrder = async () => {
    const {
      email,
      first_name,
      last_name,
      phone,
      tax_id,
      bank_name,
      account_number,
      iban,
      city,
      country,
      address,
      street,
      zip_code,
    } = userForm;

    const order_lines = cartProducts.map(
      ({
        id,
        uom,
        quantity,
        sale_price,
        vendor_id,
        product_id,
        variant_id,
        discount,
        tax,
        selection,
      }) => ({
        product_id: product_id | variant_id,
        unit_price: +sale_price,
        tax,
        discount,
        quantity,
        subtotal: quantity * +sale_price,
        uom,
        vendor_id,
        selection,
      })
    );

    const data = {
      customer: {
        email: email || user.email,
        first_name,
        last_name,
        phone,
        tax_id,
        address: {
          country: country?.value,
          street,
          address,
          zip_code,
          city,
        },
        bank: {
          name: bank_name,
          account_number,
          iban,
        },
      },
      name: first_name + " " + last_name,
      order_lines,
      total_with_tax: decimalNumberUpto2(
        getTotal() + getShippingCharges() - getTotalDiscount()
      ),
      total_without_tax: decimalNumberUpto2(
        getTotal() + getShippingCharges() - getTotalDiscount()
      ),
      invoice_amount: 0,
      paid_amount: 0,
    };

    const { flag } = await createSaleOrder(data);
    if (flag) {
      dispatch(clearCart());
      navigate("/thankyou");
    }
  };

  const getTotalDiscount = () =>
    cartProducts.reduce(
      (total, item) =>
        (total =
          total +
          item.quantity *
            (+item.sale_price - +item.sale_price * (1 - +item.discount / 100))),
      0
    );

  const getCartItemTotal = (sale_price, discount, quantity) => {
    const discountAmount = sale_price * (discount / 100);
    const itemTotal = quantity * (sale_price - discountAmount);
    return itemTotal;
  };

  return (
    <section className="shop-checkouts pt30">
      <div className="container">
        {/* <Breadcrumb /> */}
        <div className="row">
          <div className="col-sm-6 col-lg-4 m-auto">
            <div className="main-title text-center mb50">
              <h2 className="title">{checkOutPage.pageTitle[language]}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-xl-9">
            <div className="checkout_form style2">
              <div className="main-title mb50">
                <p>
                  {checkOutPage.returningCustomer[language]}

                  <Link to={"/signin"} className="signin-filter-btn">
                    {checkOutPage.clickToLogin[language]}
                  </Link>
                </p>
              </div>
              <h4 className="title mb20">
                {checkOutPage.billingDetails[language]}
              </h4>
              <div className="checkout_coupon ui_kit_button">
                <CheckOutForm
                  userForm={userForm}
                  onSelect={handleSelect}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-3">
            <div className="order_sidebar_widget checkout_page mb30 mb30">
              <h4 className="title">{checkOutPage.yourOrder[language]}</h4>
              <ul>
                {cartProducts.map((cartItem, index) => (
                  <li
                    style={{
                      marginBottom: "10px",
                    }}
                    key={index}
                  >
                    <p className="product_name_qnt">
                      {language === "eng"
                        ? cartItem.name_eng
                        : cartItem.name_arb}
                    </p>
                    <span className="price">
                      $
                      {decimalNumberUpto2(
                        getCartItemTotal(
                          cartItem.sale_price,
                          cartItem.discount,
                          cartItem.quantity
                        )
                      )}
                    </span>
                  </li>
                ))}
                <li className="subtitle mt-10">
                  <p>
                    {checkOutPage.discount[language]}
                    <span className="float-end totals">
                      ${decimalNumberUpto2(getTotalDiscount())}
                    </span>
                  </p>
                </li>
                <li className="subtitle">
                  <p>
                    {checkOutPage.subTotal[language]}
                    <span className="float-end totals">
                      ${decimalNumberUpto2(getTotal() - getTotalDiscount())}
                    </span>
                  </p>
                </li>
                <li className="subtitle">
                  <p>
                    {checkOutPage.shipping[language]}
                    <span className="float-end totals">
                      ${getShippingCharges()}
                    </span>
                  </p>
                </li>
                <li className="subtitle">
                  <p>
                    {checkOutPage.total[language]}
                    <span className="float-end totals">
                      $
                      {decimalNumberUpto2(
                        getTotal() - getTotalDiscount() + getShippingCharges()
                      )}
                    </span>
                  </p>
                </li>
              </ul>
            </div>

            <div className="ui_kit_checkbox checkout_pm">
              <label className="custom_checkbox">
                {checkOutPage.personalDataUsage[language]}
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="ui_kit_button payment_widget_btn">
              <button
                onClick={handlePlaceOrder}
                type="button"
                className="btn btn-thm btn-block mb0"
              >
                {checkOutPage.placeOrder[language]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;
