import { useEffect, useState } from "react";
import { addProductByQty } from "../../store/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import RatingStar from "./RatingStar";
import ReactSelect from "react-select";
import { productDetailPage } from "../../constants/localization";
import { decimalNumberUpto2 } from "../../utils/decimalNumberUpto2";
import ProductReviewsRating from "./ProductReviewsRating";
import { URL_PATH } from "../../../shared/constants/common";
const selectionOptions = [
  {
    label: "Physical",
    value: "physical",
  },
  {
    label: "Digital",
    value: "digital",
  },
  {
    label: "Both",
    value: "both",
  },
];

const DisplayProduct = ({ product, variants, onChangeIndex, variantIndex }) => {
  const dispatch = useDispatch();
  const [productQty, setProductQty] = useState(1);
  const [imageIndex, setImageIndex] = useState(0);
  const [selection, setSelection] = useState({ label: "", value: "" });
  const { language } = useSelector((state) => state.setting);

  useEffect(() => {
    //whenever variant index change set the image index to 0
    setImageIndex(0);
  }, [variantIndex]);

  const handleAddToCart = () => {
    const {
      id,
      name_eng,
      name_arb,
      brand,
      sale_price,
      reviewsCount = 30,
      main_image,
      uom,
      vendor,
      discount,
      selection: productSelection,
      delivery_fee,
      tax,
      images,
    } = product;
    const data = {
      id,
      name_eng,
      name_arb,
      brand,
      sale_price: sale_price,
      reviewsCount,
      main_image: main_image || images[0].images,
      uom: uom.id,
      discount,
      selection: selection.value ? selection.value : productSelection,
      delivery_fee: delivery_fee !== null ? +delivery_fee : 0,
      vendor_id: vendor.id,
      quantity: productQty,
      product_id: variantIndex === null ? +id : null,
      variant_id: variantIndex !== null ? +id : null,
      tax: tax || 0,
    };

    dispatch(addProductByQty(data));
  };
  return (
    <div
      className="row wrap"
      style={{
        marginTop: "50px",
      }}
    >
      <div className="col-xl-7">
        <div className="column">
          <div className="shop_single_natabmenu">
            <div className="d-block d-sm-flex align-items-start posr pb100-xs">
              <div
                className="nav d-flex flex-sm-column nav-pills me-0 me-sm-3 custom_nav_list"
                id="v-pills-tab2"
                role="tablist"
                aria-orientation="vertical"
              >
                {product.images.map((image, index) => (
                  <button
                    onClick={() => setImageIndex(index)}
                    key={index}
                    className={`nav-link me-3 me-sm-0 ${
                      index === imageIndex ? "active" : ""
                    }`}
                    id={"v-pills-home-tab-" + index}
                    data-bs-toggle="pill"
                    data-bs-target={"#v-pills-home-" + index}
                    type="button"
                    role="tab"
                    aria-controls={"v-pills-home-" + index}
                    aria-selected={index === imageIndex ? "true" : "false"}
                  >
                    <img src={URL_PATH + image.images} alt="" />
                  </button>
                ))}
              </div>
              <div
                className="tab-content ms-0 m-xl-auto custom_tab_contents"
                id="v-pills-tabContent"
              >
                {product.images.map((image, index) => (
                  <div
                    key={index}
                    className={`tab-pane fade ${
                      index === imageIndex ? "show active" : ""
                    }`}
                    id={"v-pills-home-" + index}
                    role="tabpanel"
                    aria-labelledby={"v-pills-home-tab-" + index}
                  >
                    <div className="shop_single_navmenu_content justify-content-center">
                      <a
                        className="product_popup popup-img"
                        href={URL_PATH + image.images}
                      >
                        <span className="flaticon-full-screen"></span>
                      </a>
                      <div className="zoomimg_wrapper m-auto hizoom hi2">
                        <img
                          style={{
                            width: "500px",
                            height: "500px",
                            objectFit: "contain",
                            display: "block",
                            margin: "0px auto",
                          }}
                          className="zoom-img"
                          id="zoom_02"
                          src={URL_PATH + image.images}
                          data-zoom-image={URL_PATH + image.images}
                          alt="Shop Single Image"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="shop_single_product_details ps-0 mt-4 d-block d-xl-none">
            <div className="variants-btn-container">
              <div
                className={`variant-button ${
                  variantIndex === null ? "active" : ""
                }`}
                onClick={() => onChangeIndex(null)}
              >
                <span>
                  <img
                    className="zoom-img"
                    id="zoom_03"
                    src={URL_PATH + variants[0].image}
                    width="36px"
                    alt="Shop Single Image"
                  />
                </span>
                <span> {variants[0].description}</span>
              </div>
              {variants.map(
                (v, index) =>
                  index !== 0 && (
                    <div
                      key={index}
                      className={`variant-button ${
                        variantIndex === index - 1 ? "active" : ""
                      }`}
                      onClick={() => onChangeIndex(index - 1)}
                    >
                      <span>
                        <img
                          className="zoom-img"
                          id="zoom_03"
                          src={URL_PATH + v.image}
                          width="36px"
                          alt="Shop Single Image"
                        />
                      </span>
                      <span> {v.description}</span>
                    </div>
                  )
              )}

              {product.selection === "both" && (
                <div className="custom-field">
                  <h5 className="short_code_title">Product Selection</h5>
                  <div className="form-group">
                    <ReactSelect
                      options={selectionOptions}
                      onChange={(value) => setSelection(value)}
                      value={selection}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="sspd_price mt-4 mb25">
              {language === "eng" ? product.name_eng : product.name_arb}
            </div>

            <div className="sspd_price mt-4 mb25">
              $
              {decimalNumberUpto2(
                +product.sale_price * (1 - +product.discount / 100)
              )}
              {+product.discount > 0 && (
                <small>
                  <del className="mr10">${product.sale_price}</del>
                </small>
              )}
            </div>
            <div className="mb-0">
              <ul className="cart_btn_widget shop_single3_style db-767 d-flex mb-0">
                <li className="me-3 mb-2">
                  <div className="cart_btn home_page_sidebar">
                    <div className="quantity-block home_page_sidebar">
                      <button
                        onClick={() =>
                          setProductQty((prevState) =>
                            prevState > 0 ? prevState - 1 : 0
                          )
                        }
                        className="quantity-arrow-minus2 shop_single_page_sidebar"
                      >
                        <img src="/images/icons/minus.svg" alt="" />
                      </button>
                      <input
                        className="quantity-num2 shop_single_page_sidebar"
                        value={productQty}
                        disabled
                      />
                      <button
                        onClick={() =>
                          setProductQty((prevState) => prevState + 1)
                        }
                        className="quantity-arrow-plus2 shop_single_page_sidebar"
                      >
                        <span className="flaticon-close"></span>
                      </button>
                    </div>
                  </div>
                </li>
                <li className="me-0 me-sm-3 mb-3">
                  <button
                    onClick={handleAddToCart}
                    className="btn btn-thm bdrs60"
                  >
                    {productDetailPage.addToCart[language]}
                  </button>
                </li>
                <li className="me-0 me-sm-3 mb-3">
                  <a className="btn btn-white bdr_thm bdrs60">
                    {productDetailPage.buyNow[language]}
                  </a>
                </li>
              </ul>
              <ul className="shop_single_wishlist_area db-400 d-flex align-items-center mb-3">
                <li className="pe-2 ms-2 ms-sm-0">
                  <a>
                    <span
                      className={`flaticon-heart ${
                        language === "eng" ? "me-2" : "ms-2"
                      }`}
                    ></span>

                    {productDetailPage.wishList[language]}
                  </a>
                </li>
                <li className="pe-2 ms-2">
                  <a>
                    <span
                      className={`flaticon-graph ${
                        language === "eng" ? "me-2" : "ms-2"
                      }`}
                    ></span>
                    {productDetailPage.compare[language]}
                  </a>
                </li>
                <li className="pe-2 ms-2">
                  <a>
                    <span
                      className={`flaticon-question ${
                        language === "eng" ? "me-2" : "ms-2"
                      }`}
                    ></span>
                    {productDetailPage.askAQuestion[language]}
                  </a>
                </li>
                <li className="ms-2">
                  <a>
                    <span
                      className={`flaticon-share ${
                        language === "eng" ? "me-2" : "ms-2"
                      }`}
                    ></span>
                    {productDetailPage.share[language]}
                  </a>
                </li>
              </ul>
            </div>
            <hr />
            <div className="vendor_iconbox style2 d-flex mb-1 mt-4">
              <span className="icon fz30 heading-color">
                <span className="flaticon-truck"></span>
              </span>
              <div
                className={`details  mt-0 ${
                  language === "eng" ? "ms-3" : "me-3"
                }`}
              >
                <p className="heading-color">
                  {product.delivery_fee
                    ? `${product.delivery_fee} ${productDetailPage.shippingCharges[language]}`
                    : `${productDetailPage.freeCharges[language]}`}
                </p>
              </div>
            </div>
            <div className="vendor_iconbox style2 d-flex mb-1">
              <span className="icon fz30 heading-color">
                <span className="flaticon-shop"></span>
              </span>
              <div
                className={`details  mt-0 ${
                  language === "eng" ? "ms-3" : "me-3"
                }`}
              >
                <p className="heading-color">
                  {productDetailPage.soldAndShipped[language]}
                  <a className="fw500 heading-color fz16">
                    {productDetailPage.mrzmm[language]}
                  </a>
                </p>
              </div>
            </div>
            <div className="vendor_iconbox style2 d-flex mb-0">
              <span className="icon fz30 heading-color">
                <span className="flaticon-return-box"></span>
              </span>
              <div
                className={`details  mt-0 ${
                  language === "eng" ? "ms-3" : "me-3"
                }`}
              >
                <p className="heading-color">
                  {productDetailPage.free15Days[language]}
                </p>
              </div>
            </div>
            <hr />
          </div>
          <div className="shortcode_widget_accprdons shop_single_accordion px-0 mt-5">
            <div
              className={`faq_according
            ${language === "eng" ? "text-start" : ""}
            
           `}
            >
              <div className="shop_single_description">
                <h4 className="title">
                  {productDetailPage.overview[language]}
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "eng"
                        ? product.overview_eng
                        : product.overview_arb,
                  }}
                />
              </div>
              <hr />
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className={`btn btn-link collapsed  ${
                          language === "eng" ? "text-start" : ""
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {productDetailPage.detailsAndSpec[language]}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          language === "eng"
                            ? product.details_specifications_eng
                            : product.details_specifications_arb,
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div className="accordion" id="vendor_accordin">
                <div className="card">
                  <div className="card-header" id="headingthree">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link collapsed text-start"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapase-vendor"
                        aria-expanded="false"
                        aria-controls="collapase-vendor"
                      >
                        {productDetailPage.vendor[language]}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapase-vendor"
                    className="collapse"
                    aria-labelledby="headingthree"
                    data-parent="#vendor_accordin"
                  >
                    <div className="card-body">
                      <p>{product.vendor.name}</p>
                    </div>
                  </div>
                </div>
              </div>

              <ProductReviewsRating reviews={product.reviews} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 offset-xl-1 d-none d-xl-block z0">
        <div className="column">
          <div className="shop_single_product_details sidebar mb-3 mb-xl-0">
            <ul className="d-flex">
              <li className="border-right">{product.brand?.name}</li>
              <RatingStar />
            </ul>
            <h4 className="title mb15">
              {language === "eng" ? product.name_eng : product.name_arb}
            </h4>
            <div className="variants-btn-container">
              <div
                className={`variant-button ${
                  variantIndex === null ? "active" : ""
                }`}
                onClick={() => onChangeIndex(null)}
              >
                <span>
                  <img
                    className="zoom-img"
                    id="zoom_03"
                    src={URL_PATH + variants[0].image}
                    width="36px"
                    alt="Shop Single Image"
                  />
                </span>
                <span> {variants[0].description}</span>
              </div>
              {variants.map(
                (v, index) =>
                  index !== 0 && (
                    <div
                      key={index}
                      className={`variant-button ${
                        variantIndex === index - 1 ? "active" : ""
                      }`}
                      onClick={() => onChangeIndex(index - 1)}
                    >
                      <span>
                        <img
                          className="zoom-img"
                          id="zoom_03"
                          src={URL_PATH + v.image}
                          width="36px"
                          alt="Shop Single Image"
                        />
                      </span>
                      <span> {v.description}</span>
                    </div>
                  )
              )}
            </div>
            <hr />
            <div className="sspd_price mb25 mt20">
              {+product.discount > 0 ? (
                <>
                  $
                  {decimalNumberUpto2(
                    +product.sale_price * (1 - +product.discount / 100)
                  )}
                  <small>
                    <del className="mr10">${product.sale_price}</del>
                  </small>
                </>
              ) : (
                <>${product.sale_price}</>
              )}
            </div>
            <ul className="cart_btns ui_kit_button mb15 d-flex justify-content-between">
              <li>
                <div className="cart_btn home_page_sidebar">
                  <div className="quantity-block home_page_sidebar">
                    <button
                      onClick={() =>
                        setProductQty((prevState) =>
                          prevState > 0 ? prevState - 1 : 0
                        )
                      }
                      className="quantity-arrow-minus2 shop_single_page_sidebar"
                    >
                      <img src="/images/icons/minus.svg" alt="" />
                    </button>
                    <input
                      className="quantity-num2 shop_single_page_sidebar"
                      value={productQty}
                      disabled
                    />
                    <button
                      onClick={() =>
                        setProductQty((prevState) => prevState + 1)
                      }
                      className="quantity-arrow-plus2 shop_single_page_sidebar"
                    >
                      <span className="flaticon-close"></span>
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <button
                  onClick={handleAddToCart}
                  type="button"
                  className="btn btn-thm"
                >
                  {productDetailPage.addToCart[language]}
                </button>
              </li>
            </ul>
            <div className="d-grid mb-3">
              <a className="btn btn-white bdr_thm ss_cart_btn">
                {productDetailPage.buyNow[language]}
              </a>
            </div>
            <ul className="shop_single_wishlist_area d-block d-sm-flex align-items-center">
              <li className="pe-2 ms-2 ms-sm-0">
                <a>
                  <span
                    className={`flaticon-heart ${
                      language === "eng" ? "me-2" : "ms-2"
                    }`}
                  ></span>
                  {productDetailPage.wishList[language]}
                </a>
              </li>
              <li className="pe-2 ms-2">
                <a>
                  <span
                    className={`flaticon-graph ${
                      language === "eng" ? "me-2" : "ms-2"
                    }`}
                  ></span>
                  {productDetailPage.compare[language]}
                </a>
              </li>
              <li className="pe-2 ms-2">
                <a>
                  <span
                    className={`flaticon-question ${
                      language === "eng" ? "me-2" : "ms-2"
                    }`}
                  ></span>
                  {productDetailPage.askAQuestion[language]}
                </a>
              </li>
              <li className="ms-2">
                <a>
                  <span
                    className={`flaticon-share ${
                      language === "eng" ? "me-2" : "ms-2"
                    }`}
                  ></span>
                  {productDetailPage.askAQuestion[language]}
                </a>
              </li>
            </ul>
            <hr />
            <div className="vendor_iconbox style2 d-block d-sm-flex mb-1">
              <span className="icon fz30 heading-color">
                <span className="flaticon-truck"></span>
              </span>
              <div
                className={`details ms-0  mt-2 mt-sm-0 ${
                  language === "eng" ? "ms-sm-4" : "me-sm-4"
                }`}
              >
                <p className="heading-color">
                  {product.delivery_fee
                    ? `${product.delivery_fee} ${productDetailPage.shippingCharges[language]}`
                    : `${productDetailPage.freeCharges[language]}`}
                </p>
              </div>
            </div>
            <div className="vendor_iconbox style2 d-block d-sm-flex mb-1">
              <span className="icon fz30 heading-color">
                <span className="flaticon-shop"></span>
              </span>
              <div
                className={`details ms-0  mt-2 mt-sm-0 ${
                  language === "eng" ? "ms-sm-4" : "me-sm-4"
                }`}
              >
                <p className="heading-color">
                  {productDetailPage.soldAndShipped[language]}
                </p>
                <h5>{productDetailPage.mrzmm[language]}</h5>
              </div>
            </div>
            <div className="vendor_iconbox style2 d-block d-sm-flex mb-0">
              <span className="icon fz30 heading-color">
                <span className="flaticon-return-box"></span>
              </span>
              <div
                className={`details ms-0  mt-2 mt-sm-0 ${
                  language === "eng" ? "ms-sm-4" : "me-sm-4"
                }`}
              >
                <p className="heading-color">
                  {productDetailPage.mrzmm[language]}
                </p>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayProduct;
