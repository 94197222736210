import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import Modal from "../../shared/components/Modal";
import TagFilter from "../components/tags/TagFilter";
import TagTable from "../components/tags/Table/TagTable";
import TagForm from "../forms/TagForm";
import { deleteTag, getAllTagsListAdmin } from "../services/tag";
import useApi from "../../hooks/useAPI";
import Error from "../../shared/components/Error";

const AdminTags = () => {
  const [tags, setTags] = useState([]);
  const { data, error, loading, request } = useApi(getAllTagsListAdmin);
  const [modalOpen, setModalOpen] = useState(false);
  const [editId, setEditID] = useState(-1);
  const [deleteId, setDeleteID] = useState(-1);
  const [mode, setMode] = useState("none");

  const [filter, setFilter] = useState({
    search: "",
  });

  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setTags(data);
    }
  }, [loading, data]);

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  const handleEdit = (id) => {
    setMode("UPDATE");
    setDeleteID(-1);
    setEditID(id);
    openModal();
  };

  const handleDelete = (id) => {
    setMode("NONE");
    setEditID(-1);
    setDeleteID(id);
    openModal();
  };

  const handleCreate = () => {
    setMode("CREATE");
    setEditID(-1);
    setDeleteID(-1);
    openModal();
  };

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const resetState = () => {
    setEditID(-1);
    setDeleteID(-1);
    closeModal();
  };

  const confirmDelete = async () => {
    //call api to Delete
    const response = await deleteTag(deleteId);
    if (response.flag) {
      setTags((prevState) => prevState.filter((tag) => tag.id !== deleteId));
    }
    resetState();
  };

  const getTagName = (id) => tags.find((tag) => tag.id === id);

  const handleSubmit = (data) => {
    if (editId > -1) {
      setTags((prevState) =>
        prevState.map((item) => (item.id === editId ? { ...data } : item))
      );
    } else {
      setTags((prevState) => [...prevState, { ...data }]);
    }
    resetState();
  };
  if (loading) return <Preloader />;
  if (error) return <Error error={error} />;

  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Tag</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <TagFilter
              onClick={handleCreate}
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
            <TagTable
              onEdit={handleEdit}
              onDelete={handleDelete}
              tags={tags}
              filter={filter}
            />
          </div>
        </div>
      </div>
      <Modal show={modalOpen} onClose={closeModal} title="">
        {(mode == "CREATE" || mode == "UPDATE") && (
          <TagForm
            id={editId}
            tag={tags.find((Tag) => Tag.id === editId)}
            isEdit={mode === "UPDATE" ? true : false}
            onSubmit={handleSubmit}
          />
        )}
        {deleteId > -1 && (
          <>
            <h6>
              Are you sure you want to delete
              <span style={{ fontWeight: "bold", color: "red" }}>
                {" " + getTagName(deleteId).name}
              </span>
              ? This action cannot be undone.
            </h6>
            <div className="d-flex justify-content-end gap-2">
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirmDelete}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AdminTags;
