import MyDeliveryTableItem from "./MyDeliveryTableItem";

const MyDeliveriesTableBody = ({ deliveries, onView }) => {
  return (
    <tbody style={{ width: "100%" }}>
      {deliveries.map((deliveryItem, index) => (
        <MyDeliveryTableItem
          key={deliveryItem.id}
          deliveryItem={deliveryItem}
          onView={onView}
          index={index}
        />
      ))}
    </tbody>
  );
};

export default MyDeliveriesTableBody;
