import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useMobileNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const elements = document.getElementsByClassName("mm-listitem");
    const handleClick = (event) => {
      event.preventDefault();
      const href = event.currentTarget.getAttribute("href");
      if (href) {
        const menuElement = document.getElementById("menu");
        menuElement.classList.remove("mm-menu_opened");
        const htmlElement = document.documentElement;
        htmlElement.classList.remove(
          "mm-wrapper_opened",
          "mm-wrapper_blocking",
          "mm-wrapper_background",
          "mm-wrapper_opening"
        );
        navigate(href);
      }
    };

    Array.from(elements).forEach((element, index) => {
      const anchor = element.querySelector("a");
      if (anchor && index <= 1) {
        anchor.addEventListener("click", handleClick);
      }
    });

    return () => {
      Array.from(elements).forEach((element) => {
        const anchor = element.querySelector("a");
        if (anchor) {
          anchor.removeEventListener("click", handleClick);
        }
      });
    };
  }, [navigate]);
};

export default useMobileNavigation;
