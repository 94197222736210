import { useEffect, useState } from "react";
import { createBanner, updateBanner } from "../services/banner";
import ReactSelect from "react-select";
const baseUrl = process.env.REACT_APP_BASE_URL_LIVE;

const BannerForm = ({
  onSubmit,
  isEdit = false,
  id = null,
  banner = null,
  categories = [],
}) => {
  const [form, setForm] = useState({
    text_eng: "",
    text_arb: "",
    image: "",
    category: {
      label: "",
      value: "",
    },
  });

  useEffect(() => {
    if (banner && isEdit) {
      const { text_eng, text_arb, image, category } = banner;

      setForm({
        text_arb,
        text_eng,
        category: {
          label: category ? category.name_eng : null,
          value: category ? category.id : null,
        },
        image: isEdit
          ? {
              image,
              operation: "DONOTHING",
            }
          : {
              image: "",
              operation: "",
            },
      });
    }
  }, [id, banner, isEdit]);

  const options = categories.map((cat) => ({
    label: cat.name_eng,
    value: cat.id,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      ...form,
      category: form.category.value,
    };

    if (isEdit && id) {
      data = {
        ...data,
        image:
          isEdit && form.image.operation === "DONOTHING"
            ? {
                image: form.image.image,
                operation: form.image.operation,
              }
            : {
                operation: form.image.operation,
                image: form.image.image.slice(
                  form.image.image.indexOf(",") + 1
                ),
              },
      };

      const response = await updateBanner(id, data);
      onSubmit(response);
    } else {
      data = {
        ...data,
        image: form.image.image
          ? form.image.image.slice(form.image.image.indexOf(",") + 1)
          : null,
      };
      const response = await createBanner(data);
      onSubmit(response);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;

    if (name === "image") {
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64File = e.target.result;
          setForm((prevState) => ({
            ...prevState,
            [name]: { image: base64File, operation: "UPDATE" },
          }));
        };
        reader.onerror = (error) => {
          console.error("Error reading file: ", error);
        };
        reader.readAsDataURL(file);
      }
    } else {
      const { value } = e.target;
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <div className="login_form">
      <form
        className="d-flex flex-column justify-content-between"
        onSubmit={handleSubmit}
      >
        <div>
          <div
            style={{
              padding: "20px",
            }}
            className="mb-2 mr-sm-2"
          >
            <label className="form-label">Text (Eng)</label>
            <input
              value={form.text_eng}
              name="text_eng"
              type="text"
              className="form-control"
              placeholder="Category Name Eng"
              onChange={handleChange}
            />
          </div>

          <div className="custom-field">
            <h5 className="short_code_title">Select Category</h5>
            <ReactSelect
              options={options}
              onChange={(value) =>
                setForm((prevState) => ({
                  ...prevState,
                  category: value,
                }))
              }
              value={form.category}
            />
          </div>

          <div
            style={{
              padding: "20px",
            }}
            className="mb-2 mr-sm-2"
          >
            <label className="form-label">Text (Arb)</label>
            <input
              value={form.text_arb}
              name="text_arb"
              style={{
                unicodeBidi: "bidi-override",
                direction: "rtl",
              }}
              type="text"
              className="form-control"
              placeholder="Category Name Arabic"
              onChange={handleChange}
            />
          </div>

          <div className="custom-field">
            <h5 className="short_code_title">Image</h5>
            <div className="form-group">
              <input
                onChange={handleChange}
                name="image"
                type="file"
                className="form-control"
                accept="image/*"
                placeholder="Upload Image"
              />
            </div>
            {form.image && (
              <div className="image-container">
                <img
                  src={
                    form.image.operation === "DONOTHING"
                      ? baseUrl + form.image.image
                      : form.image.image
                  }
                  alt="Preview Image"
                />
              </div>
            )}
          </div>
        </div>

        <div className="self-end">
          <button type="submit" className="btn btn-log btn-thm mt20">
            {isEdit ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BannerForm;
