import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  sendDigitalAttachments,
  createInvoice,
  getOrderById,
} from "../services/order";
import OrderDetailItem from "../components/OrderDetail/OrderDetailItem";
import Preloader from "../../shared/components/Preloader";
import CreateInvoiceModal from "../components/OrderDetail/CreateInvoiceModal";
import InvoiceView from "../components/OrderDetail/InvoiceView";
import DeliveryView from "../components/OrderDetail/DeliveryView";
import CustomerDetail from "../components/CustomerDetail";
import { toast } from "react-toastify";

const classes = {
  DRAFT: "status-draft",
  SALE: "status-sale",
  DONE: "status-done",
  CANCELLED: "status-cancelled",
};

const OrderDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();

  const [openDelivery, setOpenDelivery] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode") || "";
    if (mode) {
      if (mode === "delivery" || mode === "invoice") {
        (async () => {
          const response = await getOrderById(id);
          setOrder(response);
        })();
      }
      if (mode === "delivery") {
        setOpenDelivery(true);
      } else if (mode === "invoice") {
        setOpenInvoice(true);
      }
    } else {
      setOpenDelivery(false);
      setOpenInvoice(false);
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      const response = await getOrderById(id);
      setOrder(response);
      setLoading(false);
    })();
  }, [id]);

  if (loading) return <Preloader />;

  const {
    customer_id,
    order_state,
    invoice_id,
    is_complete_invoice,
    is_complete_delivery,
  } = order;

  const handleCreateInvoice = async (data) => {
    setIsSubmit((prevState) => !prevState);

    const invoiceData = {
      ...data,
      sale_order: +id,
      customer: customer_id.id,
      delivery_address: customer_id.address.id,
      vendor_id: order.order_lines[0].vendor_id.id,
      name: "Hard Coded Delivery Name",
      total_with_tax: order.total_with_tax,
      total_without_tax: order.total_without_tax,
      paid_amount: order.paid_amount,
    };

    const { flag } = await createInvoice(invoiceData);

    if (flag) {
      setOrder((prevState) => ({ ...prevState, is_complete_invoice: true }));
      setIsSubmit((prevState) => !prevState);
      navigate(`/vendor/order/${id}/detail?mode=invoice`);
    }
  };

  const handleAttachements = async () => {
    setIsSending((prevState) => !prevState);
    const response = await sendDigitalAttachments(id);
    if (response.flag) {
      toast.success("Email Sent");
    } else {
      toast.error("There is an error while sending email");
    }
    setIsSending((prevState) => !prevState);
  };
  const handleOpenDelivery = () => {
    navigate(`/vendor/order/${id}/detail?mode=delivery`);
  };

  const handleOpenInvoice = () => {
    navigate(`/vendor/order/${id}/detail?mode=invoice`);
  };

  const handleBack = () => {
    navigate(`/vendor/order/${id}/detail`);
  };
  return (
    <>
      {!openDelivery && !openInvoice && (
        <div className="order_detail_body p-4">
          <div className="main-title text-center">
            <h2>ORDER Detail</h2>
          </div>
          <h4 className="short_code_title">
            ORDER# <span>{order.id}</span>
            <span className={classes[order_state]}>{order_state}</span>
          </h4>

          <CustomerDetail customer={customer_id} />

          <div className="application_statics mt-3">
            <div className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <button
                    disabled={isSending}
                    className="btn btn-new btn-lg btn-thm mx-3"
                    onClick={handleAttachements}
                  >
                    <i className="fa fa-paperclip" aria-hidden="true"></i> Send
                    Attachements
                  </button>
                </div>
                <div>
                  {is_complete_invoice && (
                    <span
                      className="btn btn-new btn-lg btn-thm mx-2"
                      onClick={handleOpenDelivery}
                    >
                      <i className="fas fa-shipping-fast"></i> Delivery
                    </span>
                  )}

                  <span
                    className="btn btn-new btn-lg btn-thm mx-2"
                    onClick={handleOpenInvoice}
                  >
                    <i className="fas fa-edit"></i> Invoice
                  </span>
                </div>
              </div>
            </div>
            <div className="account_user_deails dashboard_page">
              <div className="order_table table-responsive">
                <table className="table">
                  <thead className="table-light">
                    <tr className="text-center">
                      <th scope="col">Product Image</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Qnt</th>
                      <th scope="col">Delivered Qnt</th>
                      <th scope="col">Invoice Qnt</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.order_lines.map((orderItem) => (
                      <OrderDetailItem
                        key={orderItem.id}
                        orderItem={orderItem}
                      />
                    ))}
                  </tbody>
                </table>
                <hr />
                <div className="fix-order-table">
                  <table className="table sub-table d-flex justify-content-end">
                    <tbody>
                      <tr>
                        <td className="sub-table-label">Sub Total:</td>
                        <td className="sub-table-value">
                          ${order.total_with_tax}
                        </td>
                      </tr>
                      <tr>
                        <td className="sub-table-label">
                          Discount (VELZON15):
                        </td>
                        <td className="sub-table-value">-$0</td>
                      </tr>
                      <tr>
                        <td className="sub-table-label">Shipping Charge:</td>
                        <td className="sub-table-value">$0</td>
                      </tr>
                      <tr>
                        <td className="sub-table-label">Estimated Tax:</td>
                        <td className="sub-table-value">$0</td>
                      </tr>
                      <tr>
                        <td className="sub-table-label total">Total (USD):</td>
                        <td className="sub-table-value total">
                          ${order.total_with_tax}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openDelivery && (
        <DeliveryView
          onBack={handleBack}
          orderId={id}
          order={order}
          is_complete_delivery={is_complete_delivery}
        />
      )}
      {openInvoice &&
        (is_complete_invoice ? (
          <InvoiceView invoiceId={id} onBack={handleBack} />
        ) : (
          <CreateInvoiceModal
            isSubmit={isSubmit}
            data={order.order_lines}
            customer={order.customer_id}
            onSubmit={handleCreateInvoice}
            onBack={handleBack}
            orderId={id}
          />
        ))}
    </>
  );
};
export default OrderDetail;
