import VendorTableRow from "./VendorTableRow";

const VendorTableBody = ({ orders }) => {
  return (
    <tbody style={{width:"100%"}}>
      {orders.map((orderItem) => (
        <VendorTableRow key={orderItem.id} orderItem={orderItem} />
      ))}
    </tbody>
  );
};

export default VendorTableBody;
