import InvoiceTableRow from "./InvoiceTableRow";

const InvoiceTableBody = ({ invoices, onView }) => {
  return (
    <tbody style={{ width: "100%" }}>
      {invoices.map((invoiceItem, index) => (
        <InvoiceTableRow
          key={index}
          onView={onView}
          invoiceItem={invoiceItem}
          index={index}
        />
      ))}
    </tbody>
  );
};

export default InvoiceTableBody;
