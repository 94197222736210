import { useState } from "react";
import { giveReview } from "../../services/myReviews";
import { Rating } from "react-simple-star-rating";
const tooltipArray = [
  "Terrible",
  "Terrible+",
  "Bad",
  "Bad+",
  "Average",
  "Average+",
  "Great",
  "Great+",
  "Awesome",
  "Awesome+",
];
const fillColorArray = [
  "#f17a45",
  "#f17a45",
  "#f19745",
  "#f19745",
  "#f1a545",
  "#f1a545",
  "#f1b345",
  "#f1b345",
  "#f1d045",
  "#f1d045",
];
const ReviewForm = ({ product_id, order_line_id, onReview }) => {
  const [reviewForm, setReviewForm] = useState({
    review_text: "",
    rating: "",
    attachment: [],
  });

  const handleChange = (e) => {
    const name = e.target.name;

    if (name === "attachment") {
      const files = e.target.files;

      if (files.length > 0) {
        const fileReaders = [];
        const base64Files = [];

        Array.from(files).forEach((file, index) => {
          const reader = new FileReader();
          fileReaders.push(reader);

          reader.onload = (e) => {
            base64Files.push(e.target.result);

            if (base64Files.length === files.length) {
              setReviewForm((prevState) => ({
                ...prevState,
                [name]: [...prevState[name], ...base64Files],
              }));
            }
          };

          reader.onerror = (error) => {
            console.error("Error reading file: ", error);
          };

          reader.readAsDataURL(file);
        });
      }
    } else {
      const { value } = e.target;
      setReviewForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { attachment, rating, review_text } = reviewForm;

    const data = {
      order_line_id,
      product_id,
      review_text,
      rating,
      attachment: attachment.map((item) => ({
        image: item.slice(item.indexOf(",") + 1),
      })),
    };
    const response = await giveReview(data);
    onReview(response);
  };

  const handleFileDelete = (index) => {
    let allAttachements = [...reviewForm.attachment];
    allAttachements.splice(index, 1);

    setReviewForm((prevState) => ({
      ...prevState,
      attachment: allAttachements,
    }));
  };

  const handleRating = (rating) => {
    const value = Math.ceil(rating);
    setReviewForm((prevState) => ({ ...prevState, rating: value }));
  };
  return (
    <form
      className="d-flex flex-column"
      onSubmit={handleSubmit}
      style={{ padding: "20px", border: "1px solid #ddd", borderRadius: "5px" }}
    >
      <div className="mb-4">
        <label className="form-label" style={{ fontWeight: "bold" }}>
          Review Text
        </label>
        <textarea
          value={reviewForm.review_text}
          name="review_text"
          type="text"
          className="form-control"
          onChange={handleChange}
          placeholder="Write your review here..."
          rows="4"
          style={{ resize: "none" }}
        />
      </div>

      <div className="mb-4">
        <label className="form-label" style={{ fontWeight: "bold" }}>
          Rating
        </label>
        <div>
          <Rating
            onClick={handleRating}
            size={25}
            transition
            allowFraction
            showTooltip
            tooltipArray={tooltipArray}
            fillColorArray={fillColorArray}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="form-label" style={{ fontWeight: "bold" }}>
          Attachments
        </label>
        <input
          multiple
          onChange={handleChange}
          type="file"
          name="attachment"
          className="form-control"
        />
        {reviewForm.attachment.length > 0 && (
          <div className="d-flex flex-wrap mt-3">
            {reviewForm.attachment.map((item, index) => (
              <div key={index} className="position-relative m-2">
                <img
                  src={item}
                  alt="Preview Image"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                />
                <button
                  onClick={() => handleFileDelete(index)}
                  className="btn-close position-absolute top-0 start-100 translate-middle"
                  aria-label="Close"
                ></button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="text-end">
        <button type="submit" className="btn btn-thm">
          Submit Review
        </button>
      </div>
    </form>
  );
};

export default ReviewForm;
