import AdminDeliveryHeader from "./AdminDeliveryHeader";
import AdminDeliveryBody from "./DeliveryTableBody";

import TablePagination from "../../../../shared/components/TablePagination";
import usePagination from "../../../../hooks/usePagination";

const AdminDeliveryTable = ({ filter, deliveries, onView }) => {
  const { status, search } = filter;
  let filteredDeliveries = deliveries;
  if (status && status !== "ALL") {
    filteredDeliveries = filteredDeliveries.filter(
      (delivery) => delivery.delivery_state === status
    );
  }

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredDeliveries = filteredDeliveries.filter((delivery) =>
      delivery.tracking_reference.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredDeliveries, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <AdminDeliveryHeader />
          <AdminDeliveryBody onView={onView} deliveries={currentData()} />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default AdminDeliveryTable;
