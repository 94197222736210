import VendorRegisterForm from "../forms/VendorRegisterForm";

const AdminVendorRegister = () => {
  return (
    <div>
      <h4 className="text-center">Register Vendor</h4>
      <VendorRegisterForm />
    </div>
  );
};

export default AdminVendorRegister;
