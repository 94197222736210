const TableHeader = () => {
  return (
    <thead className="table-light">
      <tr className="text-center">
        <th scope="col">ID</th>
        <th scope="col">Name Eng</th>
        <th scope="col">Name Arabic</th>
        <th scope="col">Brand</th>
        <th scope="col">SKU</th>
        <th scope="col">Stock</th>
        <th scope="col">Total Variants</th>
        <th scope="col">Sale Price</th>
        <th scope="col">Cost Price</th>
        <th scope="col">Categories</th>
        <th scope="col">Date</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
  );
};

export default TableHeader;
