import { Link } from "react-router-dom";

const DeliveryTableRow = ({ deliveryItem }) => {
  const {
    id,
    name,
    customer_id,
    vendor_id,
    delivery_state,
    schedule_date,
    tracking_reference,
  } = deliveryItem;

  const { name: customerName } = customer_id;
  const { name: vendorName } = vendor_id;

  return (
    <tr>
      <th scope="row">#{id}</th>
      <td>{name}</td>
      <td>{customerName}</td>
      <td>{vendorName}</td>
      <td className="status">
        <span>{delivery_state}</span>
      </td>
      <td>{schedule_date}</td>
      <td>{tracking_reference}</td>
    </tr>
  );
};

export default DeliveryTableRow;
