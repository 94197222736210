import VendorTableRow from "./VendorTableRow";

const VendorTableBody = ({ vendors, onEdit }) => {
  return (
    <tbody style={{ width: "100%" }}>
      {vendors.map((vendorItem, index) => (
        <VendorTableRow
          key={index}
          onEdit={onEdit}
          vendorItem={vendorItem}
          index={index}
        />
      ))}
    </tbody>
  );
};

export default VendorTableBody;
