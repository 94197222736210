import { useState } from "react";
import DeliveryItemModal from "./DeliveryItemModal";
import { generateRandomString } from "../../utils/generateRandomString";

const CreateInvoiceModal = ({
  onSubmit,
  data,
  onBack,
  orderId,
  customer,
  isSubmit,
}) => {
  const invoicesLines =
    data.map((item) => ({
      ...item,
      displayQty: item.quantity - item.delivery_qty,
    })) || [];
  const [invoiceForm, setInvoiceForm] = useState({
    tracking_reference: generateRandomString(),
    invoice_date: "",
  });

  const { name, phone, address, user } = customer;
  const { country, street, zip_code, city } = address;
  const { email } = user;
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...invoiceForm,
      invoice_lines: invoicesLines.map(
        ({
          discount,
          subtotal,
          vendor_id,
          product_id,
          uom,
          displayQty,
          unit_price,
          tax,
        }) => ({
          product_id: product_id.id,
          quantity: displayQty,
          uom,
          discount,
          subtotal,
          vendor_id: vendor_id.id,
          unit_price,
          tax,
        })
      ),
    });
  };

  const getTotalDiscount = () =>
    invoicesLines.reduce(
      (total, item) =>
        (total =
          total +
          item.quantity *
            (+item.unit_price - +item.unit_price * (1 - +item.discount / 100))),
      0
    );

  const getTotalAmount = () =>
    invoicesLines.reduce(
      (total, item) => (total = total + item.quantity * +item.unit_price),
      0
    );

  return (
    <div className="order_detail_body invoice_form p-3">
      <h2 className="text-center">Invoice</h2>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h5>Order # {orderId}</h5>
          <button className="btn btn-log btn-thm mt20" onClick={onBack}>
            Back
          </button>
        </div>
        <div style={{ background: "white" }} className="d-flex row mt-3 p-3">
          <div className="col-6 mt-3">
            <h5 className="card-title">Customer Details</h5>
            <ul className="list-unstyled mb-0 vstack gap-3">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <i className="flaticon-customer"></i>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <h6 className="fs-14 mb-1">{name}</h6>
                  </div>
                </div>
              </li>
              <li>
                <i className="ri-mail-line me-2 align-middle text-muted fs-16 flaticon-mail-inbox-app"></i>
                {email}
              </li>
              <li>
                <i className="ri-phone-line me-2 align-middle text-muted fs-16 flaticon-phone-call"></i>
                {phone}
              </li>
              <li>
                <i className="ri-phone-line me-2 align-middle text-muted fs-16 fas fa-location"></i>
                {`${street}, ${city}, ${zip_code}, ${country}`}
              </li>
            </ul>
          </div>

          <div className="col-6">
            <div className="col">
              <h4>Tracking Reference</h4>
              <input
                disabled
                value={invoiceForm.tracking_reference}
                type="text"
                className="form-control"
              />
            </div>
            <div className="col mt-5">
              <h4>Invoice Date</h4>

              <input
                value={invoiceForm.invoice_date}
                type="date"
                className="form-control"
                onChange={(e) =>
                  setInvoiceForm((prevState) => ({
                    ...prevState,
                    invoice_date: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col mt-3">
        <h4>Invoice Lines</h4>
        <div className="order_table table-responsive">
          <table className="table">
            <thead className="table-light">
              <tr className="text-center">
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {invoicesLines.map((item, index) => (
                <DeliveryItemModal
                  isInvoice={true}
                  data={data}
                  item={item}
                  key={index}
                  index={index}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-end">
        <div className="col-3">
          <h4>Sub Total : {getTotalAmount()} </h4>
          <hr />
          <h4>Discount : {getTotalDiscount()} </h4>
          <hr />

          <h4>Total : {getTotalAmount() - getTotalDiscount()} </h4>
          <hr />

          <button
            disabled={isSubmit || !invoiceForm.invoice_date}
            type="button"
            onClick={handleSubmit}
            className="btn btn-log btn-thm mt20"
          >
            Create Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateInvoiceModal;
