import { useSelector } from "react-redux";
import { deliveryTableHeader } from "../../constants/localization";

const MyDeliveriesTableHeader = () => {
  const { language } = useSelector((state) => state.setting);
  return (
    <thead className="table-light order-table-custom">
      <tr>
        <th scope="col">{deliveryTableHeader.id[language]}</th>
        <th scope="col">{deliveryTableHeader.name[language]}</th>
        <th scope="col">{deliveryTableHeader.scheduleDate[language]}</th>
        <th scope="col">{deliveryTableHeader.trackingReference[language]}</th>
        <th scope="col">{deliveryTableHeader.status[language]}</th>
        <th scope="col">{deliveryTableHeader.customerName[language]}</th>
        <th scope="col">{deliveryTableHeader.address[language]}</th>
        <th scope="col">{deliveryTableHeader.action[language]}</th>
      </tr>
    </thead>
  );
};

export default MyDeliveriesTableHeader;
