import { useSelector } from "react-redux";
import { footerData } from "../../constants/localization";

const Footer = () => {
  const { language } = useSelector((state) => state.setting);
  return (
    <section className="footer_one home1 bdrt1">
      <div className="container pb60">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="mailchimp_widget mb30-md text-center">
              <div className="icon float-start">
                <span className="flaticon-email-1" />
              </div>
              <div className="details">
                <h3 className="title">
                  {footerData.subscribeAndDiscount[language]}
                </h3>
              </div>
            </div>
            <div className="footer_social_widget">
              <form className="footer_mailchimp_form">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <input
                      type="email"
                      className="form-control"
                      placeholder={footerData.yourEmailAddress[language]}
                    />
                    <button className="ms-sm-2 btn-thm" type="submit">
                      {footerData.subscribe[language]}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row mt60">
          <div className="col-sm-6 col-md-5 col-lg-3 col-xl-3">
            <div className="footer_contact_widget">
              <h4>{footerData.contactUs[language]}</h4>
              <div className="footer_contact_iconbox d-flex mb-4">
                <div className="icon">
                  <span className="flaticon-phone-call" />
                </div>
                <div
                  className={`details  ${language === "eng" ? "ms-3" : "me-3"}`}
                >
                  <h5 className="title">{footerData.timing[language]}</h5>
                  <a>+(1) 123 456 7890</a>
                </div>
              </div>
              <div className="footer_contact_iconbox d-flex">
                <div className="icon">
                  <span className="flaticon-email" />
                </div>
                <div
                  className={`details  ${language === "eng" ? "ms-3" : "me-3"}`}
                >
                  <h5 className="title">{footerData.needHelp[language]}</h5>
                  <a>support@Mrzzm.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <div className="footer_qlink_widget">
              <h4>{footerData.aboutMrzzm[language]}</h4>
              <ul className="list-unstyled">
                <li>
                  <a>{footerData.trackOrder[language]}</a>
                </li>
                <li>
                  <a>{footerData.productGuides[language]}</a>
                </li>
                <li>
                  <a>{footerData.wishlists[language]}</a>
                </li>
                <li>
                  <a>{footerData.privacyPolicy[language]}</a>
                </li>
                <li>
                  <a>{footerData.storeLocator[language]}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <div className="footer_qlink_widget">
              <h4
                style={{
                  textAlign: language === "eng" ? "left" : "right",
                }}
              >
                {footerData.customerSupport[language]}
              </h4>
              <ul className="list-unstyled">
                <li>
                  <a>{footerData.contactUs[language]}</a>
                </li>
                <li>
                  <a>{footerData.helpCenter[language]}</a>
                </li>
                <li>
                  <a>
                    {footerData.returns[language]}
                    {footerData.and[language]}
                    {footerData.exchanges[language]}
                  </a>
                </li>
                <li>
                  <a>{footerData.bestBuy[language]}</a>
                </li>
                <li>
                  <a>{footerData.bestBuyGift[language]}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <div className="footer_qlink_widget">
              <h4
                style={{
                  textAlign: language === "eng" ? "left" : "right",
                }}
              ></h4>
              <ul className="list-unstyled">
                <li>
                  <a>{footerData.geekSquad[language]}</a>
                </li>
                <li>
                  <a>{footerData.inhomeAdvisor[language]}</a>
                </li>
                <li>
                  <a>{footerData.tradeInProgram[language]}</a>
                </li>
                <li>
                  <a>{footerData.electronicsRecycling[language]}</a>
                </li>
                <li>
                  <a>{footerData.bestBuyHealth[language]}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-8 col-md-5 col-lg-3 col-xl-3">
            <div className="footer_social_widget">
              <h4
                style={{
                  textAlign: language === "eng" ? "left" : "right",
                }}
                className="title"
              >
                {footerData.followUs[language]}
              </h4>
              <div className="social_icon_list mt30">
                <ul className="mb20">
                  <li className="list-inline-item">
                    <a>
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a>
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a>
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a>
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_mobile_app_widget mb25">
              <h4
                style={{
                  textAlign: language === "eng" ? "left" : "right",
                }}
                className="title mb10"
              >
                {footerData.mobileApps[language]}
              </h4>
              <div className="mobile_app_list">
                <ul className="mb0">
                  <li>
                    <a>
                      <span className="flaticon-apple" />
                      {footerData.iosApps[language]}
                    </a>
                  </li>
                  <li>
                    <a>
                      <span className="flaticon-android" />
                      {footerData.androidApp[language]}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_acceped_card_widget">
              <h4
                style={{
                  textAlign: language === "eng" ? "left" : "right",
                }}
                className="title mb20"
              >
                {footerData.weAccept[language]}
              </h4>
              <div className="acceped_card_list">
                <ul className="d-flex mb-0">
                  <li className="me-2">
                    <a>
                      <img
                        src="/images/resource/visa-card.png"
                        alt="visa-card"
                      />
                    </a>
                  </li>
                  <li className="me-2">
                    <a>
                      <img
                        src="/images/resource/master-card.png"
                        alt="master-card"
                      />
                    </a>
                  </li>
                  <li className="me-2">
                    <a>
                      <img
                        src="/images/resource/apple-pay.png"
                        alt="apple-pay"
                      />
                    </a>
                  </li>
                  <li className="me-2">
                    <a>
                      <img
                        src="/images/resource/discover-card.png"
                        alt="discover-card"
                      />
                    </a>
                  </li>
                  <li className="me-2">
                    <a>
                      <img src="/images/resource/paypal.png" alt="paypal" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src="/images/resource/amex-card.png"
                        alt="amex-card"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bdrt1 pt20 pb20">
        <div className="row">
          <div className="col-lg-12">
            <div className="copyright-widget text-center text-lg-start d-block d-lg-flex mb15-md">
              <p className="me-4">© 2023 Mrzzm. All Rights Reserved</p>
              <p>
                <a>Privacy</a>·<a>Terms</a>·<a>Sitemap</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Footer;
