import axiosInstance from "../../interceptor/axiosInstance";
export const getMyReviews = async () => {
  const response = await axiosInstance.get("/customer/review/list/");

  return response.data.data || [];
};

export const giveReview = async (data) => {
  const response = await axiosInstance.post("/customer/review/create/", {
    ...data,
  });
  return response.data;
};