export const shoppingCartPage = {
  product: {
    eng: "PRODUCT",
    arb: "المنتج",
  },
  dicountPrice: {
    eng: "Discount",
    arb: "سعر الخصم",
  },
  price: {
    eng: "PRICE",
    arb: "السعر",
  },
  quantity: {
    eng: "QUANTITY",
    arb: "الكمية",
  },
  total: {
    eng: "TOTAL",
    arb: "الإجمالي",
  },
  remove: {
    eng: "REMOVE",
    arb: "إزالة",
  },
  shoppingCart: {
    eng: "Shopping Cart",
    arb: "سلة التسوق",
  },
  applyCoupon: {
    eng: "Apply Coupon",
    arb: "تطبيق القسيمة",
  },
  couponCode: {
    eng: "Coupon Code",
    arb: "رمز القسيمة",
  },
  continueShopping: {
    eng: "Continue Shopping",
    arb: "مواصلة التسوق",
  },
  updateCart: {
    eng: "Update Cart",
    arb: "تحديث السلة",
  },
  noProduct: {
    eng: "No Product In the cart",
    arb: "لا يوجد منتج في السلة",
  },

  offerText: {
    eng: "Buy $98.00 more to enjoy FREE Shipping",
    arb: "اشترِ 98.00 دولارًا إضافيًا لتستمتع بالشحن المجاني",
  },
  cartTotals: {
    eng: "Cart Totals",
    arb: "إجمالي السلة",
  },
  productSubtotal: {
    eng: "Product Subtotal",
    arb: "الإجمالي الفرعي للمنتجات",
  },
  orderDiscounts: {
    eng: "Order Discounts",
    arb: "خصومات الطلب",
  },
  estimatedShipping: {
    eng: "Shipping Charges",
    arb: "رسوم الشحن",
  },
  proceedToCheckout: {
    eng: "Proceed to checkout",
    arb: "التوجه للدفع",
  },
};

export const checkOutPage = {
  pageTitle: {
    eng: "Checkout",
    arb: "الدفع",
  },
  firstName: {
    eng: "First name",
    arb: "الاسم الأول",
  },
  lastName: {
    eng: "Last name",
    arb: "اسم العائلة",
  },
  taxId: {
    eng: "Tax Id",
    arb: "الرقم الضريبي",
  },
  countryRegion: {
    eng: "Country / Region",
    arb: "البلد / المنطقة",
  },
  address: {
    eng: "Address",
    arb: "عنوان",
  },
  street: {
    eng: "Street",
    arb: "شارع",
  },
  city: {
    eng: "Town / City",
    arb: "المدينة / المدينة",
  },
  zipCode: {
    eng: "Zip Code",
    arb: "الرمز البريدي",
  },
  phone: {
    eng: "Phone",
    arb: "هاتف",
  },
  email: {
    eng: "Your Email",
    arb: "بريدك الالكتروني",
  },
  returningCustomer: {
    eng: "Returning customer:",
    arb: "الزبون العائد؟",
  },
  clickToLogin: {
    eng: "Click here to login",
    arb: "انقر هنا لتسجيل الدخول",
  },
  billingDetails: {
    eng: "Billing Details",
    arb: "تفاصيل الفاتورة",
  },
  yourOrder: {
    eng: "Your Order",
    arb: "طلبك",
  },
  discount: {
    eng: "Discount",
    arb: "تخفيض",
  },
  subTotal: {
    eng: "Sub Total",
    arb: "المجموع الفرعي",
  },
  shipping: {
    eng: "Shipping",
    arb: "شحن",
  },
  total: {
    eng: "Total",
    arb: "المجموع",
  },
  personalDataUsage: {
    eng: "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.",
    arb: "سيتم استخدام بياناتك الشخصية لمعالجة طلبك ودعم تجربتك في جميع أنحاء هذا الموقع، ولأغراض أخرى موضحة في سياسة الخصوصية الخاصة بنا.",
  },
  placeOrder: {
    eng: "Place Order",
    arb: "مكان الامر",
  },
};

export const footerData = {
  subscribeAndDiscount: {
    eng: "Subscribe and get 20% discount",
    arb: "اشترك 20% واحصل على خصم ",
  },
  yourEmailAddress: {
    eng: "Your email address",
    arb: "عنوان بريدك  الإلكتروني",
  },
  subscribe: {
    eng: "Subscribe",
    arb: "يشترك",
  },
  contactUs: {
    eng: "Contact Us",
    arb: "اتصل بنا",
  },
  timing: {
    eng: "Monday-Friday: 08am-9pm",
    arb: "الاثنين-الجمعة: 08 صباحًا - 9 مساءً",
  },
  needHelp: {
    eng: "Need help with your order:",
    arb: "بحاجة الى مساعدة مع طلبك؟ ",
  },
  aboutMrzzm: {
    eng: "About Mrzzm",
    arb: " حول مرزم",
  },
  trackOrder: {
    eng: "Track Your Order",
    arb: "أتبع طلبك",
  },
  productGuides: {
    eng: "Product Guides",
    arb: "أدلة المنتج",
  },
  wishlists: {
    eng: "Wishlists",
    arb: "قوائم الأمنيات",
  },
  privacyPolicy: {
    eng: "Privacy Policy",
    arb: "سياسة الخصوصية",
  },
  storeLocator: {
    eng: "Store Locator",
    arb: "فروعنا",
  },
  customerSupport: {
    eng: "Customer Support",
    arb: "دعم العملاء",
  },
  services: {
    eng: "Services",
    arb: "خدمات",
  },
  returns: {
    eng: "Returns",
    arb: "عائدات",
  },
  exchanges: {
    eng: "Exchanges",
    arb: "التبادلات",
  },
  helpCenter: {
    eng: "Help Center",
    arb: "مركز المساعدة",
  },
  followUs: {
    eng: "Follow us",
    arb: "تابعنا",
  },
  mobileApps: {
    eng: "Mobile Apps",
    arb: "تطبيقات الموبايل",
  },
  weAccept: {
    eng: "We accept",
    arb: "نحن نقبل",
  },
  and: {
    eng: "and",
    arb: "و",
  },
  bestBuy: {
    eng: "Best Buy Financing",
    arb: "أفضل شراء التمويل",
  },
  bestBuyGift: {
    eng: "Best Buy Gift Card",
    arb: "أفضل شراء بطاقة هدية",
  },

  tradeInProgram: {
    eng: "Trade-In Program",
    arb: " برنامج التجارة في",
  },

  geekSquad: {
    eng: "Geek Squad",
    arb: "فرقة المهوس",
  },

  inhomeAdvisor: {
    eng: "In-Home Advisor",
    arb: "  مستشار في المنزل  ",
  },

  copyright: {
    eng: "© 2023 Mrzzm. All Rights Reserved",
    arb: "© 2023 مرزم. كل الحقوق محفوظة",
  },
  privacyPolicy: {
    eng: "Privacy",
    arb: "خصوصية",
  },
  terms: {
    eng: "Terms",
    arb: "شروط",
  },
  sitemap: {
    eng: "Sitemap",
    arb: "خريطة الموقع",
  },
  electronicsRecycling: {
    eng: "Electronics Recycling",
    arb: "إعادة تدوير الإلكترونيات",
  },
  bestBuyHealth: {
    eng: "Best Buy Health",
    arb: " أفضل شراء الصحة ",
  },

  iosApps: {
    eng: " iOS App",
    arb: " تطبيق iOS ",
  },
  androidApp: {
    eng: "  Android App",
    arb: "الروبوت التطبيق  ",
  },
};

export const homePage = {
  bestSeller: {
    eng: "Best seller in the last month",
    arb: "الأكثر مبيعا في الشهر الماضي",
  },
  deliveryText: {
    eng: "Members get free shipping* with no order minimum!*Restrictions apply.Try free 30-day trial",
    arb: "يحصل الأعضاء على شحن مجاني* بدون حد أدنى للطلب!*تطبق القيود.جرّب نسخة تجريبية مجانية مدتها 30 يومًا",
  },
  dealOfTheDay: {
    eng: "Deal of the Day",
    arb: "صفقة اليوم",
  },
  days: {
    eng: "Days ",
    arb: "أيام ",
  },
  hours: {
    eng: "Hours ",
    arb: "ساعات ",
  },
  minutes: {
    eng: "Minutes ",
    arb: "دقائق ",
  },
  seconds: {
    eng: "Seconds ",
    arb: "ثواني ",
  },
  viewAll: {
    eng: "View All",
    arb: "عرض الكل",
  },
  freeShipping: {
    eng: "Free Shipping",
    arb: " ًالشحن مجانا",
  },
  freeShippingOver: {
    eng: "Free Shipping for orders over $200",
    arb: "شحن مجاني للطلبات التي تزيد عن 200 دولار",
  },
  moneyGurantte: {
    eng: "Money Guarantee",
    arb: " ضمان المال",
  },
  withInExchange: {
    eng: "Within 30 days for an exchange.",
    arb: "في غضون 30 يوما للتبادل.",
  },
  onlineSupport: {
    eng: "Online Support",
    arb: " الدعم عبر الإنترنت",
  },
  allhoursWeeks: {
    eng: "24 hours a day, 7 days a week",
    arb: "24 ساعة في اليوم، 7 أيام في الأسبوع",
  },
  flexiblePayments: { eng: "Flexible Payment", arb: "الدفع المرن" },
  paywithMultiples: {
    eng: "Pay with Multiple Credit Cards",
    arb: " الدفع باستخدام بطاقات الائتمان المتعددة",
  },
};

export const productDetailPage = {
  addToCart: {
    eng: "Add to Cart",
    arb: "أضف إلى السلة",
  },
  buyNow: {
    eng: "Buy Now",
    arb: "اشتري الآن",
  },
  wishList: {
    eng: "Wishlist",
    arb: "قائمة الرغبات",
  },
  compare: {
    eng: "Compare",
    arb: "يقارن",
  },
  askAQuestion: {
    eng: "Ask A Question",
    arb: "طرح سؤال",
  },
  share: {
    eng: "Share",
    arb: "يشارك",
  },
  shippingCharges: {
    eng: "shipping charges",
    arb: "رسوم الشحن",
  },
  freeCharges: {
    eng: "Free Shipping",
    arb: "الشحن مجانا",
  },
  returnsText: {
    eng: "Returns Text",
    arb: "إرجاع النص",
  },
  overview: {
    eng: "Overview",
    arb: "ملخص",
  },
  detailsAndSpec: {
    eng: "Details & Specs",
    arb: "التفاصيل والمواصفات",
  },
  vendor: {
    eng: "Vendor",
    arb: "بائع",
  },
  soldAndShipped: {
    eng: "Sold and shipped by",
    arb: "تم بيعها وشحنها بواسطة",
  },
  mrzmm: {
    eng: "mrzzm",
    arb: "مرزم",
  },
  free15Days: {
    eng: "Free 15-Day returns",
    arb: "إرجاع مجاني لمدة 15 يومًا",
  },
  customerReviews: {
    eng: "Customer Reviews",
    arb: "آراء العملاء",
  },
  noReviewsYet: {
    eng: "No review Yet",
    arb: "لا يوجد مراجعة حتى الآن",
  },
  star: {
    eng: "star",
    arb: "نجم",
  },
};

export const categoryCardComponent = {
  items: {
    eng: " Items",
    arb: " أغراض",
  },
};

export const categoryListingComponent = {
  shopBycategory: {
    eng: "Shop by Category",
    arb: "تسوق حسب الاقسام",
  },
  viewAllCategories: {
    eng: "View All Categories",
    arb: "عرض جميع الفئات",
  },
};

export const hiddenCartSidebarComponent = {
  yourCart: {
    eng: "Your Cart",
    arb: "عربتك",
  },
  cartEmpty: {
    eng: "Your cart is empty.",
    arb: "عربة التسوق فارغة",
  },
  total: {
    eng: "Total",
    arb: "المجموع",
  },
  buyMore: {
    eng: "Buy $98.00 more to enjoy FREE Shipping",
    arb: "اشترِ 98.00 دولارًا إضافيًا لتستمتع بالشحن المجاني",
  },
  viewCart: {
    eng: "View Cart",
    arb: "عرض العربة",
  },
  checkout: {
    eng: "Checkout",
    arb: "الدفع",
  },
};

export const vendorListingComponent = {
  shopByVendors: {
    eng: "Shop by Vendors",
    arb: "تسوق حسب البائعين",
  },
  viewAllVendors: {
    eng: "View All Vendors",
    arb: "عرض جميع البائعين",
  },
};

export const productCardComponent = {
  addTOCart: {
    eng: "Add to Cart",
    arb: "أضف إلى السلة",
  },
};

export const menusText = {
  home: {
    eng: "Home",
    arb: "بيت",
  },
  shop: {
    eng: "Shop",
    arb: "محل",
  },
  categories: {
    eng: "Categories",
    arb: "فئات",
  },
};

export const navBarMenus = {
  browseCategories: {
    eng: "Browse Categories",
    arb: "تصفح الفئات",
  },
  home: {
    eng: "Home",
    arb: "الرئيسية",
  },
  shop: {
    eng: "Shop",
    arb: "المتجر",
  },
  pages: {
    eng: "Pages",
    arb: "الصفحات",
  },
  aboutUs: {
    eng: "About Us",
    arb: "معلومات عنا",
  },
  accounts: {
    eng: "Accounts",
    arb: "الحسابات",
  },
  accountDetails: {
    eng: "Account Details",
    arb: "تفاصيل الحساب",
  },
  accountOrder: {
    eng: "Account Order",
    arb: "طلب الحساب",
  },
  accountAddress: {
    eng: "Account Address",
    arb: "عنوان الحساب",
  },
  accountWishList: {
    eng: "Account Wish List",
    arb: "قائمة الرغبات",
  },
  accountInvoice: {
    eng: "Account Invoice",
    arb: "فاتورة الحساب",
  },
  becomeVendor: {
    eng: "Become a Vendor",
    arb: "كن بائعًا",
  },
  vendorPages: {
    eng: "Vendor Pages",
    arb: "صفحات البائع",
  },
  vendorList: {
    eng: "Vendor List",
    arb: "قائمة البائعين",
  },
  vendorSingle: {
    eng: "Vendor Single",
    arb: "بائع فردي",
  },
  dashboard: {
    eng: "Dashboard",
    arb: "لوحة القيادة",
  },
  products: {
    eng: "Products",
    arb: "المنتجات",
  },
  order: {
    eng: "Order",
    arb: "طلب",
  },
  customer: {
    eng: "Customer",
    arb: "زبون",
  },
  categories: {
    eng: "Categories",
    arb: "فئات",
  },
  message: {
    eng: "Message",
    arb: "رسالة",
  },
  settings: {
    eng: "Settings",
    arb: "الإعدادات",
  },
  brands: {
    eng: "Brands",
    arb: "العلامات التجارية",
  },
  contact: {
    eng: "Contact",
    arb: "اتصل",
  },
  comingSoon: {
    eng: "Coming Soon",
    arb: "قريبا",
  },
  help: {
    eng: "Help",
    arb: "مساعدة",
  },
  error404: {
    eng: "404 Page",
    arb: "404 الصفحة",
  },
  faq: {
    eng: "FAQ",
    arb: "الأسئلة الشائعة",
  },
  invoices: {
    eng: "Invoices",
    arb: "الفواتير",
  },
  login: {
    eng: "Login",
    arb: "تسجيل الدخول",
  },
  register: {
    eng: "Register",
    arb: "تسجيل",
  },
  terms: {
    eng: "Terms and Conditions",
    arb: "الأحكام والشروط",
  },
  uiElements: {
    eng: "UI Elements",
    arb: "عناصر واجهة المستخدم",
  },
  blog: {
    eng: "Blog",
    arb: "مدونة",
  },
  blogGrid: {
    eng: "Blog Grid",
    arb: "شبكة المدونة",
  },
  blogGridSidebar: {
    eng: "Blog Grid Sidebar",
    arb: "شبكة المدونة بالشريط الجانبي",
  },
  blogDetails: {
    eng: "Blog Details",
    arb: "تفاصيل المدونة",
  },
  blogList: {
    eng: "Blog List",
    arb: "قائمة المدونة",
  },
  blogSingle: {
    eng: "Blog Single",
    arb: "مدونة فردية",
  },
  blogSingleV2: {
    eng: "Blog Single v2",
    arb: "مدونة فردية v2",
  },
  dealOfTheDay: {
    eng: "Deal of the Day",
    arb: "صفقة اليوم",
  },
  hotDeals: {
    eng: "Hot Deals",
    arb: "صفقات ساخنة",
  },
  bestSellers: {
    eng: "Best Sellers",
    arb: "الأكثر مبيعاً",
  },
  newArrivals: {
    eng: "New Arrivals",
    arb: "وصل حديثاً",
  },
  allCategory: {
    eng: "All Category",
    arb: "جميع الفئات",
  },
};

export const headerMiddleComponent = {
  myItems: {
    arb: "عناصر",
    eng: "Items",
  },
  signIn: {
    arb: "تسجيل الدخول",
    eng: "Sign In",
  },
  account: {
    arb: "حساب",
    eng: "Account",
  },
  total: {
    arb: "مجموع",
    eng: "Total",
  },
  appLogoText: {
    arb: "مِرزّم.",
    eng: "Mrzzm.",
  },
  searchProducts: {
    arb: "البحث عن المنتجات…",
    eng: "Search Products",
  },
};

export const shopHeaderComponent = {
  showing: {
    eng: "Showing ",
    arb: " عرض ",
  },
  of: {
    eng: "of",
    arb: "من ",
  },
  results: {
    eng: " results",
    arb: "نتائج ",
  },
  allFilter: {
    eng: "All Filter",
    arb: "كل التصفية",
  },
};

export const shopPaginationComponent = {
  items: {
    eng: "Items",
    arb: "عناصر",
  },
  of: {
    eng: "of",
    arb: "من",
  },
  productsFound: {
    eng: "Products found",
    arb: "المنتجات الموجودة",
  },
};

export const shopBannerItemComponent = {
  shopNow: {
    arb: "تسوق الآن",
    eng: "Show Now",
  },
  welcomeText: {
    arb: "مرحبًا بكم في متجر مرزم",
    eng: "Welcome to Mrzzm Store",
  },
};

export const shopFilterSideBar = {
  customPriceRange: { eng: "Custom Price Range", arb: "نطاق السعر المخصص" },
  confirm: { eng: "Confirm", arb: "تأكيد" },
  allFilters: {
    eng: "All Filters",
    arb: "جميع الفلاتر",
  },
};

export const productListingTopComponent = {
  newArrivals: {
    arb: "الجديد",
    eng: "New Arrivals",
  },
  bestSellers: {
    arb: "الأكثر مبيعاً",
    eng: "Best Sellers",
  },
  bestRated: {
    arb: "الأعلى تقييماً",
    eng: "Best Rated",
  },
};

export const homeProductListingTop = {
  electronic: {
    arb: "الأجهزة الإلكترونية",
    eng: "Electronics",
  },
  baby: {
    arb: "الأطفال",
    eng: "Baby",
  },
  top20: {
    arb: "أفضل 20",
    eng: "Top 20",
  },
  all: {
    eng: "All",
    arb: "الكل",
  },
};

export const vendorShopCardComponent = {
  products: {
    arb: "منتجات",
    eng: "products",
  },
};

export const authPageData = {
  signIn: {
    eng: "Sign In",
    arb: "تسجيل الدخول",
  },
  createYourAccount: {
    eng: "Create Your Account",
    arb: "أنشئ حسابك",
  },

  firstName: {
    eng: "First Name",
    arb: "الاسم الأول",
  },
  lastName: {
    eng: "Last Name",
    arb: "اسم العائلة",
  },
  userName: {
    eng: "Username",
    arb: "اسم المستخدم",
  },
  yourEmail: {
    eng: "Your Email",
    arb: "بريدك الإلكتروني",
  },
  password: {
    eng: "Password",
    arb: "كلمة المرور",
  },
  createAccount: {
    eng: "Create Account",
    arb: "إنشاء حساب",
  },
  alreadyHaveAnAccount: {
    eng: "Already have an account?",
    arb: "هل لديك حساب بالفعل؟",
  },
  or: {
    eng: "Or",
    arb: "أو",
  },
  login: {
    eng: "Login",
    arb: "تسجيل الدخول",
  },
  lostYourPassword: {
    eng: "Lost your password?",
    arb: "هل فقدت كلمة المرور؟",
  },
  dontHaveAnAccount: {
    eng: "Don't have an account?",
    arb: "ليس لديك حساب؟",
  },
  createAccount: {
    eng: "Create Account",
    arb: "إنشاء حساب",
  },
  rememberMe: {
    eng: "Remember Me",
    arb: "تذكرنى",
  },
};

export const loginUserMenu = {
  myOrders: {
    arb: "طلباتي",
    eng: "My Orders",
  },
  myReviews: {
    arb: "مراجعاتي",
    eng: "My Reviews",
  },
  myDeliveries: {
    eng: "My Deliveries",
    arb: "توصيلاتي",
  },
  logout: {
    arb: "تسجيل الخروج",
    eng: "Logout",
  },
};

export const myOrdersPage = {
  myOrders: {
    eng: "My Orders",
    arb: "طلباتي",
  },
  reviews: {
    eng: "Reviews",
    arb: "المراجعات",
  },
  deliveriesMy: {
    eng: "My Deliveries",
    arb: "توصيلاتي",
  },
  logout: {
    eng: "Logout",
    arb: "تسجيل الخروج",
  },
  order: {
    eng: "Order",
    arb: "طلب",
  },
  giveReview: {
    eng: "Give Review",
    arb: "إعطاء مراجعة",
  },
  pending: {
    eng: "Pending",
    arb: "قيد الانتظار",
  },
  reviewed: {
    eng: "Reviewed",
    arb: "تمت المراجعة",
  },
  qty: {
    eng: "Quantity",
    arb: "الكمية",
  },
  delivered: {
    eng: "Delivered",
    arb: "تم التوصيل",
  },
  noOrderFound: {
    eng: "No Order Found",
    arb: "لم يتم العثور على طلب",
  },
  dashboardNavigation: {
    eng: "Dashboard Navigation",
    arb: "تنقل لوحة القيادة",
  },
};

export const myReviewsPage = {
  myReviews: {
    eng: "My Reviews",
    arb: "مراجعاتي",
  },
};

export const deliveryRefundForm = {
  image: {
    eng: "Image",
    arb: "صورة",
  },
  name: {
    eng: "Name",
    arb: "اسم",
  },
  productQty: {
    eng: "Product Quantity",
    arb: "كمية المنتج",
  },
  action: {
    eng: "Action",
    arb: "عمل",
  },
  description: {
    eng: "Description",
    arb: "وصف",
  },
  confirm: {
    eng: "Confirm",
    arb: "تأكيد",
  },
  trackingReference: {
    eng: "Tracking Reference",
    arb: "رقم تتبع",
  },
  vendor: {
    eng: "Vendor",
    arb: "بائع",
  },
  scheduleDate: {
    eng: "Schedule Date",
    arb: "تاريخ الجدولة",
  },
  customer: {
    eng: "Customer",
    arb: "عميل",
  },
  back: {
    eng: "Back",
    arb: "رجوع",
  },
  createRefund: {
    eng: "Create Refund",
    arb: "إنشاء استرداد",
  },
  allItemsRefunded: {
    eng: "All Items are Refunded",
    arb: "تم استرداد جميع العناصر",
  },
};

export const deliveryTableHeader = {
  id: {
    eng: "ID",
    arb: "معرف",
  },
  name: {
    eng: "Name",
    arb: "اسم",
  },
  scheduleDate: {
    eng: "Schedule Date",
    arb: "تاريخ الجدولة",
  },
  trackingReference: {
    eng: "Tracking Reference",
    arb: "رقم تتبع",
  },
  status: {
    eng: "Status",
    arb: "الحالة",
  },
  customerName: {
    eng: "Customer Name",
    arb: "اسم العميل",
  },
  address: {
    eng: "Address",
    arb: "عنوان",
  },
  action: {
    eng: "Action",
    arb: "عمل",
  },
};
