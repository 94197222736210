import TablePagination from "../../TablePagination";
import usePagination from "../../../hooks/usePagination";
import VendorDeliveryHeader from "./VendorDeliveryHeader";
import VendorDeliveryTableBody from "./VendorDeliveryTableBody";

const VendorDeliveryTable = ({ filter, deliveries, onView }) => {
  const { status, search } = filter;
  let filteredOrders = deliveries;
  if (status && status !== "ALL") {
    filteredOrders = filteredOrders.filter(
      (order) => order.order_state === status
    );
  }

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredOrders = filteredOrders.filter((order) =>
      order.tracking_reference.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredOrders, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <VendorDeliveryHeader />
          <VendorDeliveryTableBody onView={onView} deliveries={currentData()} />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default VendorDeliveryTable;
