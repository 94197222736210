export const generateRandomString = (length = 15) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  let result = "";
  array.forEach((item) => {
    result += characters[item % charactersLength];
  });
  return result;
};
