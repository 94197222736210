const VariantsListing = ({
  variants,
  onCreateVariant,
  onDeleteVariant,
  onUpdateVariant,
}) => {
  return (
    <div className="row mt-5 p-3">
      <div className="col-xl-12">
        <div>
          <button
            className="btn btn-new btn-lg btn-thm"
            onClick={onCreateVariant}
          >
            Create New Variant
          </button>
        </div>
        <div className="dashboard_product_list account_user_deails mt-3">
          <div className="order_table table-responsive">
            <table className="table">
              <thead className="table-light">
                <tr className="text-center">
                  <th scope="col">ID</th>
                  <th scope="col">Name (Eng)</th>
                  <th scope="col">Name (Arb)</th>
                  <th scope="col">Brand</th>
                  <th scope="col">SKU</th>
                  <th scope="col">Stock</th>
                  <th scope="col">Sale Price</th>
                  <th scope="col">Cost Price</th>
                  <th scope="col">Categories</th>
                  <th scope="col">Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {variants.map((variant, index) => (
                  <tr key={index}>
                    <th scope="row">{variant.id}</th>
                    <td>{variant.name_eng}</td>
                    <td>{variant.name_arb}</td>
                    <td>{variant?.brand?.name}</td>

                    <td>{variant.sku}</td>
                    <td className="status">
                      <span className="style4">Stock</span>
                    </td>

                    <td>${variant.sale_price}</td>
                    <td>${variant.cost_price}</td>
                    <td>{variant.category.name_eng}</td>
                    <td className="action">
                      <span>{variant.created_at}</span>
                    </td>
                    <td className="editing_list align-middle">
                      <ul>
                        <li className="list-inline-item mb-1">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => onUpdateVariant(index)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Edit"
                            data-bs-original-title="View"
                            aria-label="View"
                          >
                            <span className="flaticon-pencil"></span>
                          </span>
                        </li>
                        <li className="list-inline-item mb-1">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => onDeleteVariant(variant.id)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Delete"
                            data-bs-original-title="Edit"
                            aria-label="Edit"
                          >
                            <span className="flaticon-delete"></span>
                          </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariantsListing;
