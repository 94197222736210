const DeliveryItem = ({ deliveryItem }) => {
  const { product_id, quantity } = deliveryItem;
  const { name_eng, main_image } = product_id;
  return (
    <tr>
      <td className="body-color">
        <img
          src={process.env.REACT_APP_BASE_URL_LIVE + main_image}
          alt="Product Image"
          style={{
            width: "80px",
            height: "80px",
          }}
        />
      </td>
      <td className="body-color">
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            whiteSpace: "nowrap",
          }}
        >
          {name_eng}
        </p>
      </td>
      <td className="body-color">{quantity}</td>
    </tr>
  );
};

export default DeliveryItem;
