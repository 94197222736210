import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { authPageData } from "../../constants/localization";
import { useState } from "react";
import { userRegister } from "../../services/userAuth";
import { toast } from "react-toastify";

const SignUpHiddenSideBar = () => {
  const { language } = useSelector((state) => state.setting);
  const [error, setError] = useState("");
  const [form, setForm] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const handleHideSignUp = () => {
    document.body.classList.remove("signin-hidden-sidebar-content");
    document.body.classList.remove("singup-hidden-sidebar-content");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, firstName, lastName, password } = form;

    const data = {
      user: {
        email,
        password,
      },
      name: firstName + " " + lastName,
    };
    const response = await userRegister(data);
    if (!response.flag) {
      setError(response.message);
    } else toast.success(response.message);
  };

  return (
    <div className="signup-hidden-sbar">
      <div className="hsidebar-header">
        <div
          onClick={handleHideSignUp}
          className={`sidebar-close-icon ${
            language === "eng"
              ? "sidebar-close-icon__eng"
              : "sidebar-close-icon__arb"
          }`}
        >
          <span className="flaticon-close" />
        </div>
        <h4 className="title">{authPageData.createYourAccount[language]}</h4>
      </div>
      <div className="hsidebar-content">
        <div className="log_reg_form sidebar_area">
          <div className="sign_up_form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="form-label">
                  {authPageData.firstName[language]}
                </label>
                <input
                  onChange={handleChange}
                  name="firstName"
                  type="text"
                  className="form-control"
                  placeholder={authPageData.firstName[language]}
                />
              </div>
              <div className="form-group">
                <label className="form-label">
                  {authPageData.lastName[language]}
                </label>
                <input
                  onChange={handleChange}
                  name="lastName"
                  type="text"
                  className="form-control"
                  placeholder={authPageData.lastName[language]}
                />
              </div>
              <div className="form-group">
                <label className="form-label">
                  {authPageData.yourEmail[language]}
                </label>
                <input
                  onChange={handleChange}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder={authPageData.yourEmail[language]}
                />
              </div>
              <div className="form-group mb20">
                <label className="form-label">
                  {authPageData.password[language]}
                </label>
                <input
                  onChange={handleChange}
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder={authPageData.password[language]}
                />
              </div>
              {error && <h4 style={{ color: "red" }}>{error}</h4>}

              <button type="submit" className="btn btn-signup btn-thm">
                {authPageData.createAccount[language]}
              </button>
              <p className="text-center mb25 mt10">
                {authPageData.alreadyHaveAnAccount[language]}
                <Link to="/signin">{authPageData.signIn[language]}</Link>
              </p>
              <div className="hr_content">
                <hr />
                <span className="hr_top_text">{authPageData.or[language]}</span>
              </div>
              <ul className="login_with_social text-center mt30 mb0">
                <li className="list-inline-item">
                  <i className="fab fa-facebook"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fab fa-google"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fab fa-twitter"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fab fa-apple"></i>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpHiddenSideBar;
