import VendorHeader from "./VendorHeader";
import VendorTableBody from "./VendorTableBody";

import TablePagination from "../../../../shared/components/TablePagination";
import usePagination from "../../../../hooks/usePagination";

const VendorTable = ({ filter, vendors, onEdit }) => {
  const { status, search } = filter;
  let filteredVendors = vendors;
  if (status && status !== "ALL") {
    filteredVendors = filteredVendors.filter(
      (invoice) => invoice.state === status
    );
  }

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredVendors = filteredVendors.filter((invoice) =>
      invoice.tracking_reference.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredVendors, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <VendorHeader />
          <VendorTableBody onEdit={onEdit} vendors={currentData()} />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default VendorTable;
