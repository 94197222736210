import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "",
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    changeLanguage(state, action) {
      const language = action.payload;
      state.language = language;
      localStorage.setItem("language", language);
    },
    getSelectedLanugage(state, action) {
      const value = localStorage.getItem("language") || "eng";
      state.language = value;
    },
  },
});

export const { changeLanguage, getSelectedLanugage } = settingSlice.actions;

export default settingSlice.reducer;
