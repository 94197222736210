import React from "react";
import { useSelector } from "react-redux";

const productType = {
  physical: { eng: "Physical", arb: "مادي" },
  digital: { eng: "Digital", arb: "رقمي" },
  both: { eng: "Both", arb: "كلاهما" },
};
const price = {
  all: { eng: "Any Price", arb: "أي سعر" },
  less_25: { eng: "Under 25", arb: "أقل من 25" },
  between25_50: { eng: "From 25 to 50", arb: "من 25 إلى 50" },
  between50_100: { eng: "From 50 to 100", arb: "من 50 إلى 100" },
  over100: { eng: "Over 100", arb: "أكثر من 100" },
};

const delivery = {
  0: { eng: "Free", arb: "مجاني" },
};

const deliveryLabel = {
  eng: "Delivery",
  arb: "توصيل",
};

const discountLabel = {
  eng: "Discount",
  arb: "خصم",
};
const AppliedFilters = ({ filters, onRemoveFilter }) => {
  const { language } = useSelector((state) => state.setting);
  const renderFilter = (key, value, onRemove) => {
    if (value === null || (typeof value === "object" && !value.isConfirmed)) {
      return null;
    }
    switch (key) {
      case "product_type":
        return (
          <span
            key={key}
            style={{
              padding: "5px 10px",
              backgroundColor: "#f5c34b",
              color: "white",
              borderRadius: "10px",
              fontWeight: "bold",
            }}
            onClick={() => onRemove(key)}
          >
            {productType[value][language]}
            <i className="fa-solid fa-xmark mx-2"></i>
          </span>
        );
      case "delivery_fee":
        return (
          <span
            key={key}
            style={{
              padding: "5px 10px",
              backgroundColor: "#f5c34b",
              color: "white",
              borderRadius: "10px",
              fontWeight: "bold",
            }}
            onClick={() => onRemove(key)}
          >
            {deliveryLabel[language]} {delivery[value][language]}
            <i className="fa-solid fa-xmark mx-2"></i>
          </span>
        );
      case "discount":
        return (
          <span
            key={key}
            style={{
              padding: "5px 10px",
              backgroundColor: "#f5c34b",
              color: "white",
              borderRadius: "10px",
              fontWeight: "bold",
            }}
            onClick={() => onRemove(key)}
          >
            {discountLabel[language]} {value}
            <i className="fa-solid fa-xmark mx-2"></i>
          </span>
        );
      case "customRange":
        return (
          <span
            key={key}
            style={{
              padding: "5px 10px",
              backgroundColor: "#f5c34b",
              color: "white",
              borderRadius: "10px",
              fontWeight: "bold",
            }}
            onClick={() => onRemove(key)}
          >
            {value.min} - {value.max}
            <i className="fa-solid fa-xmark mx-2"></i>
          </span>
        );
      case "priceRange":
        return (
          <span
            key={key}
            style={{
              padding: "5px 10px",
              backgroundColor: "#f5c34b",
              color: "white",
              borderRadius: "10px",
              fontWeight: "bold",
            }}
            onClick={() => onRemove(key)}
          >
            {price[value][language]}
            <i className="fa-solid fa-xmark mx-2"></i>
          </span>
        );
      default:
        return null;
    }
  };

  // Check if there are any active filters
  const hasActiveFilters = Object.values(filters).some(
    (value) =>
      value !== null && (typeof value !== "object" || value.isConfirmed)
  );

  return (
    hasActiveFilters && (
      <div className="d-flex gap-2 flex-wrap">
        {Object.keys(filters).map((key) =>
          renderFilter(key, filters[key], onRemoveFilter)
        )}
      </div>
    )
  );
};

export default AppliedFilters;
