import usePagination from "../../../../hooks/usePagination";
import TablePagination from "../../../../shared/components/TablePagination";
import TagTableBody from "./TagTableBody";
import TagTableHeader from "./TagTableHeader";

const TagTable = ({ filter, tags, onDelete, onEdit }) => {
  const { search } = filter;
  let filteredTags = tags;

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredTags = tags.filter((category) =>
      category.name.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredTags, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <TagTableHeader />
          <TagTableBody
            onDelete={onDelete}
            onEdit={onEdit}
            tags={currentData()}
          />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default TagTable;
