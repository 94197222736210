import React from "react";
import Slider from "react-slick";

import ProductCard from "./ProductCard";
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  dots: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const ProductListingSlider = ({ products, slidesToShow }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="slider-container">
          <Slider
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
            autoplay={false}
            {...settings}
          >
            {products.map((product, index) => (
              <ProductCard key={index} product={product} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ProductListingSlider;
