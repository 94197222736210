import PCAForm from "./PCAForm";

const PCAListing = ({ pcas=[], onDelete, onChange, onCreate }) => {
  return (
    <div>
      <h6>PCAS</h6>
      {pcas.map(
        (pca, index) =>
          pca.operation !== "DELETE" && (
            <div key={index} className="d-flex gap-2">
              <PCAForm index={index} onChange={onChange} pca={pca} />
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onDelete(index)}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete"
                data-bs-original-title="Edit"
                aria-label="Edit"
              >
                <span className="flaticon-delete"></span>
              </span>
            </div>
          )
      )}
      <div
        className="d-flex justify-content-end"
        style={{ borderBottom: "1px solid black" }}
      >
        <span style={{ fontWeight: "bold" }} onClick={onCreate}>
          Add Attributes
        </span>
      </div>
    </div>
  );
};

export default PCAListing;
