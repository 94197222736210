import RatingStar from "./RatingStar";

const ReviewStars = ({ reviewCount, rating }) => {
  return (
    <div className="flex-grow-1 ms-3">
      <RatingStar rating={rating} />
      <div className="total_review">{reviewCount} reviews</div>
    </div>
  );
};

export default ReviewStars;
