import AdminDashboardChart from "../components/Dashboard/AdminDashboardChart";
import AdminDashboardStatistics from "../components/Dashboard/AdminDashboardStatistics";
import AdminDashboardTop from "../components/Dashboard/AdminDashboardTop";

const AdminDashboard = () => {
  return (
    <div className="dashboard__content bgc-gmart-gray">
      <AdminDashboardTop />
      <AdminDashboardStatistics />
      <AdminDashboardChart />
    </div>
  );
};

export default AdminDashboard;
