import { useEffect, useState } from "react";
import { getDeliveryByIdClient } from "../services/myOrders";
import Preloader from "../../shared/components/Preloader";
import { useParams } from "react-router-dom";
import CustomerDeliveryModal from "../components/MyDeliveries/CustomerDeliveryModal/CustomerDeliveryModal";
import CustomerPortalLayout from "../components/CustomerPortal/CustomerPortalLayout";

const MyDeliveryDetail = () => {
  const [delivery, setDelivery] = useState();
  const { deliveryId } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    deliveryId && fetchDelivery();
  }, [deliveryId]);

  const fetchDelivery = async () => {
    const response = await getDeliveryByIdClient(deliveryId);

    console.log(response);
    setDelivery(response);
    setLoading(false);
  };

  if (loading) return <Preloader />;
  return (
    <CustomerPortalLayout>
      <CustomerDeliveryModal data={delivery} />
    </CustomerPortalLayout>
  );
};

export default MyDeliveryDetail;
