import { useSelector } from "react-redux";
import { homePage } from "../../constants/localization";
const Feature = () => {
  const { language } = useSelector((state) => state.setting);
  return (
    <section className="features pt30 pb20">
      <div className="container bb1">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex db-500 justify-content-between">
              <div className="main-title mb0-500 d-block d-lg-flex">
                <h2 className="no">{homePage.dealOfTheDay[language]}</h2>
                <div className="deal_countdown">
                  <ul className="deal_counter ml0-md" id="timer">
                    <li className="list-inline-item days" />
                    <li className="list-inline-item">
                      <span className="title">{homePage.days[language]}</span>
                    </li>
                    <li className="list-inline-item hours" />
                    <li className="list-inline-item">
                      <span className="title">{homePage.hours[language]}</span>
                    </li>
                    <li className="list-inline-item minutes" />
                    <li className="list-inline-item">
                      <span className="title">
                        {homePage.minutes[language]}
                      </span>
                    </li>
                    <li className="list-inline-item seconds" />
                    <li className="list-inline-item">
                      <span className="title">
                        {homePage.seconds[language]}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="main-title mb-5">
                <a
                  className="title_more_btn mt10"
                  href="page-shop-list-v2.html"
                >

                  {homePage.viewAll[language]}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row ovh">
          <div className="col-sm-6 col-xl-3">
            <div
              className="icon_boxes d-flex wow fadeInUp"
              data-wow-duration="1.0s"
            >
              <div
                className={` ${
                  language === "eng" ? "icon__right" : "icon__left"
                }`}
              >
                <span className="flaticon-fast-delivery" />
              </div>
              <div className="details">
                <h5 className="title">{homePage.freeShipping[language]}</h5>
                <p className="para">{homePage.freeShippingOver[language]}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div
              className="icon_boxes d-flex wow fadeInUp"
              data-wow-duration="1.2s"
            >
              <div
                className={` ${
                  language === "eng" ? "icon__right" : "icon__left"
                }`}
              >
                <span className="flaticon-shield" />
              </div>
              <div className="details">
                <h5 className="title">{homePage.moneyGurantte[language]}</h5>
                <p className="para">{homePage.withInExchange[language]}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div
              className="icon_boxes d-flex wow fadeInUp"
              data-wow-duration="1.4s"
            >
              <div
                className={` ${
                  language === "eng" ? "icon__right" : "icon__left"
                }`}
              >
                <span className="flaticon-headphones" />
              </div>
              <div className="details">
                <h5 className="title">{homePage.onlineSupport[language]}</h5>
                <p className="para">{homePage.allhoursWeeks[language]}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div
              className="icon_boxes d-flex wow fadeInUp"
              data-wow-duration="1.6s"
            >
              <div
                className={` ${
                  language === "eng" ? "icon__right" : "icon__left"
                }`}
              >
                <span className="flaticon-credit-card" />
              </div>
              <div className="details">
                <h5 className="title">{homePage.flexiblePayments[language]}</h5>
                <p className="para">{homePage.paywithMultiples[language]}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Feature;
