import { ThreeDots } from "react-loader-spinner";

const DottedLoading = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <ThreeDots color="rgb(245, 195, 75)" />
    </div>
  );
};

export default DottedLoading;
