import axiosInstance from "../../interceptor/axiosInstance";

export const getAllBrandsListAdmin = async () => {
  const response = await axiosInstance.get("/adminpanel/brand/list/");
  return response.data.data;
};

export const createBrand = async (data) => {
  const response = await axiosInstance.post("adminpanel/brand/create/", {
    ...data,
  });
  return response.data.data;
};

export const updateBrand = async (id, data) => {
  const response = await axiosInstance.patch(
    "adminpanel/brand/update/?brd_id=" + id,
    {
      ...data,
    }
  );
  return response.data.data;
};

export const deleteBrand = async (id) => {
  const response = await axiosInstance.delete(
    "/adminpanel/brand/delete/?brd_id=" + id
  );
  return response.data;
};
