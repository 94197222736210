import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Layout from "./user/components/Layout";
import UserRoutes from "./user/routes/userRoutes";
import VendorRoutes from "./vendor/vendorRoutes";
import VendorLogin from "./vendor/pages/Login";
import PrivateRoute from "./shared/components/PrivateRoute";
import PortalLayOut from "./shared/components/PortalLayout";
import AdminLogin from "./admin/pages/Login";
import AdminRoutes from "./admin/adminRoutes";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/*" element={<UserRoutes />} />
          </Route>
          <Route element={<PortalLayOut />}>
            <Route
              element={
                <PrivateRoute
                  roles={["vendor"]}
                  navigateToPath={"/vendor/login"}
                />
              }
            >
              <Route path="/vendor/*" element={<VendorRoutes />} />
            </Route>
          </Route>
          <Route path="/vendor/login" element={<VendorLogin />} />

          <Route element={<PortalLayOut />}>
            <Route
              element={
                <PrivateRoute
                  roles={["admin"]}
                  navigateToPath={"/admin/login"}
                />
              }
            >
              <Route path="/admin/*" element={<AdminRoutes />} />
            </Route>
          </Route>
          <Route path="/admin/login" element={<AdminLogin />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
