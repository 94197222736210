import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { URL_PATH } from "../../../shared/constants/common";

const ShopByCategory = ({ name, categories }) => {
  const { language } = useSelector((state) => state.setting);
  return (
    <div className="row mt60">
      <div className="col-lg-12">
        <div className="main-title">
          <h2 className="title">{name}</h2>
        </div>
      </div>
      {categories.map((category, index) => (
        <div key={index} className="col-6 col-sm-4 col-md-3 col-xl">
          <Link
            to={`/shop?categoryId=${category.id}`}
            state={{
              name_eng: category.name_eng,
              name_arb: category.name_arb,
            }}
          >
            <div className="iconbox">
              <div className="icon">
                <img
                  src={
                    category.image
                      ? URL_PATH + category.image
                      : "/images/no-image.png"
                  }
                  alt={category.name_eng}
                />
              </div>
              <div className="details">
                <h5 className="title">
                  {language === "eng" ? category.name_eng : category.name_arb}
                </h5>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ShopByCategory;
