import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { categoryCardComponent } from "../../constants/localization";
import { URL_PATH } from "../../../shared/constants/common";

const CategoryCard = ({ categoryItem }) => {
  const { id, image, name_eng, name_arb, count } = categoryItem;
  const { language } = useSelector((state) => state.setting);

  return (
    <div className="col-6 col-md-3 col-xl wow fadeInUp" data-wow-duration=".7s">
      <Link
        to={`/shop?categoryId=${id}`}
        state={{
          name_arb,
          name_eng,
        }}
      >
        <div className="iconbox">
          <div className="icon">
            <img
              src={image ? URL_PATH + image : "/images/no-image.png"}
              alt={language === "eng" ? name_eng : name_arb}
            />
          </div>
          <div className="details">
            <h5 className="title">
              {language === "eng" ? name_eng : name_arb}
            </h5>
            <p className="subtitle">
              {count + "" + categoryCardComponent.items[language]}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CategoryCard;
