import { Link } from "react-router-dom";

const VendorInvoiceTableRow = ({ invoiceItem }) => {
  const {
    id,
    name,
    paid_amount,
    state,
    total_without_tax,
    total_with_tax,
    tracking_reference,
    sale_order,
  } = invoiceItem;

  return (
    <>
      <tr>
        <th scope="row">#{id}</th>
        <td>{tracking_reference}</td>
        <td>{name}</td>
        <td>{paid_amount}</td>
        <td className="status">
          <span>{state}</span>
        </td>
        <td>${total_with_tax}</td>
        <td>${total_without_tax}</td>
        <td className="d-flex gap-2">
          <Link
            to={"/vendor/invoice/" + sale_order + "/detail"}
            className="btn btn-custom"
          >
            <i className="fa-regular fa-eye"></i>
          </Link>
        </td>
      </tr>
    </>
  );
};

export default VendorInvoiceTableRow;
