const CustomerDetail = ({ customer }) => {
  const { name, phone, address, user } = customer;

  const { country, street, zip_code, city } = address;
  const { email } = user;

  return (
    <ul
      className="list-unstyled mb-0 vstack gap-3 p-3 mt-3"
      style={{ backgroundColor: "white", borderRadius: "10px" }}
    >
      <li>
        <h5>Customer Details</h5>
      </li>
      <li>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <i className="flaticon-customer"></i>
          </div>

          <div className="flex-grow-1 ms-3">
            <h6 className="fs-14 mb-1">{name}</h6>
          </div>
        </div>
      </li>
      <li>
        <i className="ri-mail-line me-2 align-middle text-muted fs-16 flaticon-mail-inbox-app"></i>
        {email}
      </li>
      <li>
        <i className="ri-phone-line me-2 align-middle text-muted fs-16 flaticon-phone-call"></i>
        {phone}
      </li>
      <li>
        <i className="ri-phone-line me-2 align-middle text-muted fs-16 fas fa-location"></i>
        {`${street}, ${city}, ${zip_code}, ${country}`}
      </li>
    </ul>
  );
};

export default CustomerDetail;
