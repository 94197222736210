import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import useApi from "../../hooks/useAPI";
import Error from "../../shared/components/Error";
import { getAllAdminSaleOrders, getSaleOrderById } from "../services/saleOrder";
import SaleOrderFilter from "../components/saleOrders/SaleOrderFilter";
import SaleOrderTable from "../components/saleOrders/Table/SaleOrderTable";

const AdminSaleOrders = () => {
  const [saleOrders, setSaleOrders] = useState([]);
  const { data, error, loading, request } = useApi(getAllAdminSaleOrders);

  const [filter, setFilter] = useState({
    status: "",
    search: "",
  });

  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setSaleOrders(data);
    }
  }, [loading, data]);

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  const handleView = async (index) => {
    try {
      const response = await getSaleOrderById(saleOrders[index].id);
      
    } catch (error) {}
  };

  if (loading) return <Preloader />;

  if (!loading && error) return <Error error={error} />;

  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Sale Orders</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <SaleOrderFilter
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
            <SaleOrderTable
              onView={handleView}
              saleOrders={saleOrders}
              filter={filter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSaleOrders;
