const baseUrl = process.env.REACT_APP_BASE_URL_LIVE;
const PortalCategoryTableRow = ({
  categoryItem,
  onEdit,
  onDelete,
  role,
  index,
}) => {
  const { id, name_eng, name_arb, image, created_at } = categoryItem;
  return (
    <tr>
      <th scope="row">#{id}</th>
      <td>
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100px",
            whiteSpace: "nowrap",
          }}
        >
          {name_eng}
        </p>
      </td>
      <td
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100px",
          whiteSpace: "nowrap",
        }}
      >
        {name_arb}
      </td>
      <td>
        <img
          src={baseUrl + image}
          alt={name_eng}
          style={{
            width: "150px",
            height: "150px",
            borderRadius: "10px",
            aspectRatio: 3 / 4,
          }}
        />
      </td>
      <td>{created_at}</td>
      {role && role === "admin" && (
        <td className="editing_list align-middle">
          <ul>
            <li className="list-inline-item mb-1">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onEdit(id)}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
                data-bs-original-title="View"
                aria-label="View"
              >
                <span className="flaticon-pencil"></span>
              </span>
            </li>
            <li className="list-inline-item mb-1">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onDelete(id)}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete"
                data-bs-original-title="Edit"
                aria-label="Edit"
              >
                <span className="flaticon-delete"></span>
              </span>
            </li>
          </ul>
        </td>
      )}
    </tr>
  );
};

export default PortalCategoryTableRow;
