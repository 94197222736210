import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { onUserLogOut } from "../../store/userAuthSlice/userAuthSlice";
import { myOrdersPage } from "../../constants/localization";

const CustomerPortalLayout = ({ children }) => {
  const { language } = useSelector((state) => state.setting);
  const [toggleMenus, setToggleMenus] = useState(false);
  const { user } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(onUserLogOut());
    navigate("/");
  };
  return (
    <section className="our-dashbord dashbord pb80">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-xl-2 dn-md">
            <div className="users_account_details extra-dashboard-menu">
              <div className="account_details_user d-flex pb10 bb1 mb10">
                <img
                  className="me-3"
                  src="images/team/ad-thumb.png"
                  alt="Generic placeholder image"
                />
                <div className="content_details text-start">
                  <h5
                    style={{
                      width: "15ch",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    className="title"
                  >
                    {user.name}
                  </h5>
                  <p
                    style={{
                      width: "15ch",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    className="stitle"
                  >
                    {user.email}
                  </p>
                </div>
              </div>
              <div className="ed_menu_list">
                <ul>
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/my-orders"
                    >
                      <span
                        className={`${
                          language === "eng" ? "" : "icon__arb"
                        } flaticon-checked-box`}
                      ></span>
                      {myOrdersPage.myOrders[language]}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/my-reviews"
                    >
                      <span
                        className={`${
                          language === "eng" ? "" : "icon__arb"
                        } fa-solid fa-comments`}
                      ></span>
                      {myOrdersPage.reviews[language]}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/my-deliveries"
                    >
                      <span
                        className={`${
                          language === "eng" ? "" : "icon__arb"
                        } fa-solid fa-truck`}
                      ></span>
                      {myOrdersPage.deliveriesMy[language]}
                    </NavLink>
                  </li>
                  <li
                    style={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                    onClick={handleLogout}
                  >
                    <span
                      style={
                        language === "eng"
                          ? { paddingRight: "10px" }
                          : { paddingLeft: "10px" }
                      }
                      className={"flaticon-exit"}
                    ></span>
                    {myOrdersPage.logout[language]}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-xl-10">
            <div className="row">
              <div className="col-lg-12">
                <div className="dashboard_navigationbar dn db-md mt50">
                  <div className="dropdown">
                    <button
                      onClick={() => setToggleMenus((prevState) => !prevState)}
                      className="dropbtn"
                    >
                      <i className="fas fa-bars pr10"></i>
                      {myOrdersPage.dashboardNavigation[language]}
                    </button>
                    <ul
                      id="myDropdown"
                      className={`dropdown-content ${
                        toggleMenus ? "show" : ""
                      }`}
                    >
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to="/my-orders"
                        >
                          <span
                            className={`${
                              language === "eng" ? "" : "icon__arb"
                            } fa-solid fa-shop`}
                          ></span>

                          {myOrdersPage.myOrders[language]}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to="/my-reviews"
                        >
                          <span
                            className={`${
                              language === "eng" ? "" : "icon__arb"
                            } flaticon-checked-box`}
                          ></span>
                          {myOrdersPage.reviews[language]}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to="/my-deliveries"
                        >
                          <span
                            className={`${
                              language === "eng" ? "" : "icon__arb"
                            } flaticon-checked-box`}
                          ></span>
                          {myOrdersPage.reviews[language]}
                        </NavLink>
                      </li>
                      <li
                        style={{
                          color: "white",
                          padding: "10px 30px",
                          textDecoration: "none",
                          display: "block",
                          fontSize: "14px",
                        }}
                        onClick={handleLogout}
                      >
                        <span
                          style={
                            language === "eng"
                              ? { paddingRight: "10px" }
                              : { paddingLeft: "10px" }
                          }
                          className={"flaticon-exit"}
                        ></span>
                        {myOrdersPage.logout[language]}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerPortalLayout;
