import AdminDashboardCard from "./AdminDashboardCard";

const AdminDashboardStatistics = () => {
  return (
    <div className="row">
      <AdminDashboardCard
        icon="flaticon-money-bag"
        title="Total Earnings"
        count="1500"
        subtitle={"50 New Earnings"}
      />
      <AdminDashboardCard
        subtitle={`5 New Vendor`}
        icon="flaticon-sent"
        title="Vendors"
        count="20"
      />
      <AdminDashboardCard
        subtitle="90+ New Products"
        icon="flaticon-customer"
        title="Customer"
        count={345}
      />
      <AdminDashboardCard
        subtitle={`500 Balance`}
        icon="flaticon-wallet"
        title="My Balance"
        count={`$1000`}
      />
    </div>
  );
};

export default AdminDashboardStatistics;
