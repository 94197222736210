import TablePagination from "../../TablePagination";
import VendorOrderHeader from "./VendorOrderHeader";
import VendorTableBody from "./VendorTableBody";
import usePagination from "../../../hooks/usePagination";

const VendorOrderTable = ({ filter, orders }) => {
  const { status, search } = filter;
  let filteredOrders = orders;
  if (status && status !== "ALL") {
    filteredOrders = filteredOrders.filter(
      (order) => order.order_state === status
    );
  }

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredOrders = filteredOrders.filter((order) =>
      order.name.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredOrders, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <VendorOrderHeader />
          <VendorTableBody orders={currentData()} />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default VendorOrderTable;
