const InvoiceTableHeader = () => {
  return (
    <thead className="table-light order-table-custom">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Delivery Name</th>
        <th scope="col">Delivery Address</th>
        <th scope="col">Customer</th>
        <th scope="col">Vendor</th>
        <th scope="col">Status</th>
        <th scope="col">Invoice Date</th>
        <th scope="col">Tracking Reference</th>
        <th scope="col">Total with Tax</th>
        <th scope="col">Total with Without Tax</th>
        {/* <th scope="col">Action</th> */}
      </tr>
    </thead>
  );
};

export default InvoiceTableHeader;
