import CategoryTableRow from "./PortalCategoryTableRow";

const PortalCategoryTableBody = ({
  categories,
  role = "",
  onEdit,
  onDelete,
}) => {
  return (
    <tbody style={{ width: "100%" }}>
      {categories.map((categoryItem, index) => (
        <CategoryTableRow
          index={index}
          onEdit={onEdit}
          onDelete={onDelete}
          role={role}
          key={categoryItem.id}
          categoryItem={categoryItem}
        />
      ))}
    </tbody>
  );
};

export default PortalCategoryTableBody;
