import MyDeliveriesTableBody from "./MyDeliveriesTableBody";
import MyDeliveriesTableHeader from "./MyDeliveriesTableHeader";

const MyDeliveriesTable = ({ deliveries, onView }) => {
  return (
    <div className="order_table table-responsive">
      <table className="table">
        <MyDeliveriesTableHeader />
        <MyDeliveriesTableBody onView={onView} deliveries={deliveries} />
      </table>
    </div>
  );
};

export default MyDeliveriesTable;
