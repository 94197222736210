import { useState } from "react";
import DeliveryItemModal from "./DeliveryItemModal";
import { generateRandomString } from "../../utils/generateRandomString";

const DeliveryModal = ({
  onSubmit,
  data,
  onBack,
  customer,
  orderId,
  isSubmit,
}) => {
  const [deliveryLines, setDeliveryLines] = useState(
    () =>
      data.map((item) => ({
        ...item,
        displayQty: item.quantity - item.delivery_qty,
      })) || []
  );
  const [deliveryForm, setDeliveryForm] = useState({
    tracking_reference: generateRandomString(),
    schedule_date: "",
  });

  const { name, phone, address, user } = customer;
  const { country, street, zip_code, city } = address;
  const { email } = user;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...deliveryForm,
      delivery_lines: deliveryLines.map(({ product_id, uom, displayQty }) => ({
        product_id: product_id.id,
        quantity: displayQty,
        uom,
      })),
    });
  };

  const handleQuantityChange = (index, value) => {
    const allDeliveryLines = [...deliveryLines];
    let item = allDeliveryLines[index];
    item = {
      ...item,
      displayQty:
        value > item.quantity - item.delivery_qty
          ? item.quantity - item.delivery_qty
          : value,
    };
    allDeliveryLines[index] = item;
    setDeliveryLines(allDeliveryLines);
  };
  return (
    <div className="order_detail_body invoice_form p-3">
      <h2 className="text-center">Create Delivery</h2>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="modal-title">Order # {orderId}</h5>
          <button className="btn btn-log btn-thm mt20" onClick={onBack}>
            Back
          </button>
        </div>
        <div style={{ background: "white" }} className="d-flex row mt-3">
          <div className="col-6 mt-3">
            <h5>Customer</h5>
            <ul className="list-unstyled mb-0 vstack gap-3">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <i className="flaticon-customer"></i>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <h6 className="fs-14 mb-1">{name}</h6>
                  </div>
                </div>
              </li>
              <li>
                <i className="ri-mail-line me-2 align-middle text-muted fs-16 flaticon-mail-inbox-app"></i>
                {email}
              </li>
              <li>
                <i className="ri-phone-line me-2 align-middle text-muted fs-16 flaticon-phone-call"></i>
                {phone}
              </li>
              <li>
                <i className="ri-phone-line me-2 align-middle text-muted fs-16 fas fa-location"></i>
                {`${street}, ${city}, ${zip_code}, ${country}`}
              </li>
            </ul>
          </div>
          <div className="col-6 mt-3">
            <div className="d-flex align-items-center gap-2">
              <div className="flex-grow-1">
                <h4>Tracking Reference</h4>
              </div>
              <div className="flex-grow-1">
                <input
                  disabled
                  value={deliveryForm.tracking_reference}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 mt-3">
              <div className="flex-grow-1">
                <h4>Schedule Date</h4>
              </div>
              <div className="flex-grow-1">
                <input
                  value={deliveryForm.schedule_date}
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setDeliveryForm((prevState) => ({
                      ...prevState,
                      schedule_date: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col mt-5">
        <h4>Delivery Lines</h4>
        <div className="order_table table-responsive">
          <table className="table">
            <thead className="table-light">
              <tr className="text-center">
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {deliveryLines.map((item, index) => (
                <DeliveryItemModal
                  data={data}
                  item={item}
                  key={index}
                  index={index}
                  onChangeQty={handleQuantityChange}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <button
        disabled={!deliveryForm.schedule_date || isSubmit}
        type="button"
        onClick={handleSubmit}
        className="btn btn-log btn-thm mt20"
      >
        Create Delivery
      </button>
    </div>
  );
};

export default DeliveryModal;
