import { useEffect } from "react";

const useResponsiveQuery = () => {
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 992px)");

    const handleMediaChange = (e) => {
      const element = document.getElementById("menu");
      if (element) {
        if (e.matches) {
          const htmlElement = document.documentElement;
          htmlElement.classList.remove(
            "mm-wrapper_opened",
            "mm-wrapper_blocking",
            "mm-wrapper_background",
            "mm-wrapper_opening"
          );
          element.classList.remove("mm-menu_opened");
        }
      }
    };

    handleMediaChange(mediaQuery);

    mediaQuery.addEventListener("change", handleMediaChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
};

export default useResponsiveQuery;
