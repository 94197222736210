const SaleOrderHeader = () => {
  return (
    <thead className="table-light order-table-custom">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Sale Order Name</th>
        <th scope="col">Customer</th>
        <th scope="col">Vendor</th>
        <th scope="col">Status</th>
        <th scope="col">Invoice Amount</th>
        <th scope="col">Paid Amount</th>
        <th scope="col">Total With Tax</th>
        <th scope="col">Total With Without Tax</th>
        {/* <th scope="col">Action</th> */}
      </tr>
    </thead>
  );
};

export default SaleOrderHeader;
