import { Route, Routes } from "react-router-dom";
import AdminCategories from "./pages/Categories";
import AdminBrands from "./pages/Brands";
import AdminUOMs from "./pages/UOMs";
import AdminTags from "./pages/Tags";
import AdminDeliveries from "./pages/Deliveries";
import AdminInvoices from "./pages/Invoices";
import AdminVendors from "./pages/Vendors";
import AdminVendorRegister from "./pages/VendorRegister";
import AdminDashboard from "./pages/Dashboard";
import AdminSaleOrders from "./pages/SaleOrders";
import SaleOrderDetail from "./pages/SaleOrderDetail";
import InvoiceDetail from "./pages/InvoiceDetail";
import DeliveryDetail from "./pages/DeliveryDetail";
import Policy from "./pages/Policy";
import AdminBanners from "./pages/Banners";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/categories" element={<AdminCategories />} />
      <Route path="/brands" element={<AdminBrands />} />
      <Route path="/uoms" element={<AdminUOMs />} />
      <Route path="/tags" element={<AdminTags />} />
      <Route path="/deliveries" element={<AdminDeliveries />} />
      <Route path="/invoices" element={<AdminInvoices />} />
      <Route path="/vendors" element={<AdminVendors />} />
      <Route path="/sale-orders" element={<AdminSaleOrders />} />
      <Route path="/vendor/create" element={<AdminVendorRegister />} />
      <Route path="/sale-order/:id/detail" element={<SaleOrderDetail />} />
      <Route path="/invoice/:id/detail" element={<InvoiceDetail />} />
      <Route path="/delivery/:id/detail" element={<DeliveryDetail />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/banners" element={<AdminBanners />} />
    </Routes>
  );
};

export default AdminRoutes;
