import { useSelector } from "react-redux";
import HiddenSideBarFilter from "./HiddenSideBarFilter";
import { shopHeaderComponent } from "../../constants/localization";

const ShopHeader = ({
  currentPage,
  totalItems,
  limit,
  onChangeLimit,
  onFilter,
  customRange,
  filters,
  onRemoveFilter,
  productTypes,
}) => {
  const limits = [10, 20, 30];
  const { language } = useSelector((state) => state.setting);
  const handleShowHiddenFilterSideBar = () => {
    document.body.classList.add("allfilter-hidden-sidebar-content");
  };
  return (
    <div className="d-flex justify-content-between">
      <div className="col-lg-7">
        <div className="filter_components">
          <ul className="mb0 align-items-center text-center text-lg-start">
            <li className="list-inline-item me-2 mb-3">
              <p className="pagination_page_count">
                {shopHeaderComponent.showing[language]} 1–{currentPage * limit}
                {shopHeaderComponent.of[language]}
                {totalItems} {shopHeaderComponent.results[language]}
              </p>
            </li>

            {limits.map((value) => (
              <li
                key={value}
                onClick={() => onChangeLimit(value)}
                style={{ fontWeight: value === limit ? "bold" : "normal" }}
                className="list-inline-item me-2 list mb-3 pl10"
              >
                {value}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="filter_components text-center text-lg-end ">
          <ul className="mb-2 mb-md-0">
            <li className="list-inline-item d-lg-none me-2 mb-3">
              <button
                onClick={handleShowHiddenFilterSideBar}
                className="btn btn-log btn-thm"
              >
                <span className="flaticon-sort me-2"></span>
                {shopHeaderComponent.allFilter[language]}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <HiddenSideBarFilter
        onFilter={onFilter}
        productTypes={productTypes}
        customRange={customRange}
        filters={filters}
        onRemoveFilter={onRemoveFilter}
      />
    </div>
  );
};

export default ShopHeader;
