import { useSelector } from "react-redux";
import ProductListingSlider from "../ProductListing/ProductListingSlider";
import ProductListingTop from "./ProductListingTop";

const ProductListingContainer = ({
  name_eng,
  name_arb,
  products,
  hideTopRight = false,
}) => {
  const { language } = useSelector((state) => state.setting);

  if (!products.length) return;
  return (
    <section className="featured-product pt0">
      <div className="container">
        {products && products.length && (
          <ProductListingTop
            hideTopRight={hideTopRight}
            title={language === "eng" ? name_eng : name_arb}
          />
        )}
        <ProductListingSlider slidesToShow={2} products={products} />
      </div>
    </section>
  );
};

export default ProductListingContainer;
