import { URL_PATH } from "../../../../shared/constants/common";

const CustomerDeliveryModalItem = ({
  deliveryItem,
  index,
  onUpdate,
  onDelete,
  isRefund,
  enabledInput,
}) => {
  const { product_id, quantity, id } = deliveryItem;
  const { name_eng, main_image } = product_id;
  return (
    <tr>
      <td className="body-color">
        <img
          src={URL_PATH + main_image}
          alt="Product Image"
          style={{
            width: "80px",
            height: "80px",
          }}
        />
      </td>
      <td className="body-color">
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            whiteSpace: "nowrap",
          }}
        >
          {name_eng}
        </p>
      </td>
      <td className="body-color">
        <input
          disabled={!enabledInput}
          value={quantity}
          onChange={(e) => onUpdate(index, e.target.value)}
        />
      </td>
      {enabledInput && (
        <td className="body-color">
          <button onClick={() => onDelete(id)} className="btn btn-custom">
            <i className="fa fa-trash"></i>
          </button>
        </td>
      )}
    </tr>
  );
};

export default CustomerDeliveryModalItem;
