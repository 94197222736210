import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import { getVendorInvoices } from "../services/order";
import InvoiceFilter from "../components/Invoices/InvoiceFilter";
import VendorInvoiceTable from "../components/Invoices/Table/InvoiceTable";

const VendorInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filter, setFilter] = useState({
    status: "",
    search: "",
  });
  useEffect(() => {
    (async () => {
      const response = await getVendorInvoices();
      setInvoices(response);
      setLoading(false);
    })();
  }, []);

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  if (loading) return <Preloader />;

  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Invoices</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <InvoiceFilter
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
            <VendorInvoiceTable invoices={invoices} filter={filter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorInvoices;
