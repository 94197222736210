import ReactSelect from "react-select";

const statusOptions = [
  { value: "ALL", label: "ALL" },
  { value: "DRAFT", label: "DRAFT" },
  { value: "SALE", label: "SALE" },
  { value: "DONE", label: "DONE" },
  { value: "CANCELLED", label: "CANCELLED" },
];

const SaleOrderFilter = ({ onChangeFilter, filter }) => {
  const { status, search } = filter;
  const value = statusOptions.find((op) => op.value === status);

  return (
    <div className="row d-block d-sm-flex justify-content-center justify-content-sm-between">
      <div className="col-sm-6 col-lg-3">
        <div className="dashboard_page header_search_widget mb30 mb15-520">
          <div className="input-group">
            <input
              value={search}
              onChange={(e) => onChangeFilter("search", e.target.value)}
              type="text"
              className="form-control"
              placeholder="Tracking Reference"
              aria-label="Recipient's username"
            />
            <div className="input-group-append">
              <button className="btn" type="button">
                <span className="fa fa-search"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-lg-3">
        <div className="dashboard_page sidebar_location_filter mb30 mb5-520">
          <ReactSelect
            onChange={({ value: newValue }) =>
              onChangeFilter("status", newValue)
            }
            value={value}
            options={statusOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default SaleOrderFilter;
