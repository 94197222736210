import { NavLink } from "react-router-dom";
import { useAuth } from "../../../user/context/AuthContext";
const PortalHiddenSideBar = () => {
  const { menus, onLogout } = useAuth();
  const getClassName = (isActive, isPending) => {
    return `items-center ${
      isPending ? "pending" : isActive ? "-is-active" : ""
    }`;
  };

  const handleLogout = () => {
    onLogout();
  };
  return (
    <div className="dashboard__sidebar">
      <div className="dashboard_sidebar_list">
        {menus.map((menu, index) => (
          <div key={index} className="sidebar_list_item">
            <NavLink
              to={menu.path}
              className={({ isActive, isPending }) =>
                getClassName(isActive, isPending)
              }
            >
              <i className={`${menu.icon} mr15`}></i>
              {menu.title}
            </NavLink>
          </div>
        ))}

        <div className="sidebar_list_item">
          <span
            style={{
              cursor: "pointer",
            }}
            className="vendor-side-hidden-logout"
            onClick={handleLogout}
          >
            <i className="flaticon-exit mr15"></i>Logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default PortalHiddenSideBar;
