import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { vendorShopCardComponent } from "../../constants/localization";
import { URL_PATH } from "../../../shared/constants/common";

const VendorCard = ({ vendorItem }) => {
  const { id, shop_name, shop_img, product_count = 0 } = vendorItem;
  const { language } = useSelector((state) => state.setting);
  return (
    <div className="col-6 col-md-3 col-xl wow fadeInUp" data-wow-duration=".7s">
      <Link to={`/shop?vendorId=${id}&vendorName=${shop_name}`}>
        <div className="iconbox">
          <div className="icon">
            <img
              src={shop_img ? URL_PATH + shop_img : "/images/no-image.png"}
              alt={shop_name}
            />
          </div>
          <div className="details">
            <h5 className="title">{shop_name}</h5>
            <p className="subtitle">
              {product_count} {vendorShopCardComponent.products[language]}{" "}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default VendorCard;
