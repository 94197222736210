export const vendorMenus = [
  {
    path: "/vendor/dashboard",
    icon: "flaticon-house mr15",
    title: "Dashboard",
  },
  {
    path: "/vendor/products",
    icon: "flaticon-cash-on-delivery mr15",
    title: "Products",
  },
  {
    path: "/vendor/orders",
    icon: "flaticon-checked-box mr15",
    title: "Sale Orders",
  },
  {
    path: "/vendor/invoices",
    icon: "flaticon-checked-box mr15",
    title: "Invoices",
  },
  {
    path: "/vendor/deliveries",
    icon: "flaticon-checked-box mr15",
    title: "Deliveries",
  },
  {
    path: "/vendor/categories",
    icon: "flaticon-folder mr15",
    title: "Categories",
  },
];

export const adminMenus = [
  {
    path: "/admin/dashboard",
    icon: "flaticon-house mr15",
    title: "Dashboard",
  },
  {
    path: "/admin/categories",
    icon: "flaticon-folder mr15",
    title: "Categories",
  },
  {
    path: "/admin/uoms",
    icon: "flaticon-cash-on-delivery mr15",
    title: "UOM",
  },
  {
    path: "/admin/brands",
    icon: "flaticon-cash-on-delivery mr15",
    title: "Brands",
  },
  {
    path: "/admin/policy",
    icon: "flaticon-cash-on-delivery mr15",
    title: "Policy",
  },
  {
    path: "/admin/tags",
    icon: "flaticon-cash-on-delivery mr15",
    title: "Tags",
  },
  {
    path: "/admin/deliveries",
    icon: "flaticon-delivery-truck mr15",
    title: "Deliveries",
  },
  {
    path: "/admin/banners",
    icon: "flaticon-delivery-truck mr15",
    title: "Banners",
  },
  {
    path: "/admin/invoices",
    icon: "flaticon-cash-on-delivery mr15",
    title: "Invoices",
  },
  {
    path: "/admin/vendors",
    icon: "flaticon-cash-on-delivery mr15",
    title: "Vendors",
  },
  {
    path: "/admin/sale-orders",
    icon: "flaticon-cash-on-delivery mr15",
    title: "Sale Orders",
  },
];
