import { Link, Navigate, useNavigate } from "react-router-dom";
import { authPageData } from "../constants/localization";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { userLogin } from "../services/userAuth";
import { onUserLogin } from "../store/userAuthSlice/userAuthSlice";

const SignIn = () => {
  const { language } = useSelector((state) => state.setting);
  const { loading } = useSelector((state) => state.category);
  const { commonLoading } = useSelector((state) => state.common);
  const { user } = useSelector((state) => state.userAuth);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await userLogin(form);

    if (response.flag) {
      const { user, access } = response;
      const data = {
        user,
        token: access,
      };
      dispatch(onUserLogin(data));
      navigate("/");
    } else {
      setError(response.message);
    }
  };

  if (!loading && !commonLoading && user) return <Navigate to="/" />;

  return (
    <section className="our-log-reg bgc-f5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-5 col-xxl-4 m-auto">
            <div className="log_reg_form mt70-992">
              <h2 className="title">{authPageData.signIn[language]}</h2>
              <div className="login_form">
                <form onSubmit={handleSubmit}>
                  <div className="mb-2 mr-sm-2">
                    <label className="form-label">
                      {authPageData.yourEmail[language]}
                    </label>
                    <input
                      onChange={handleChange}
                      value={form.email}
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group mb5">
                    <label className="form-label">
                      {authPageData.password[language]}
                    </label>
                    <input
                      value={form.password}
                      onChange={handleChange}
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                  {error && <h4 style={{ color: "red" }}>{error}</h4>}

                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="exampleCheck3"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="exampleCheck3"
                    >
                      {authPageData.rememberMe[language]}
                    </label>
                    <a
                      className={`btn-fpswd ${
                        language === "eng" ? "float-end" : "float-start"
                      }`}
                    >
                      {authPageData.lostYourPassword[language]}
                    </a>
                  </div>
                  <button type="submit" className="btn btn-log btn-thm mt20">
                    {authPageData.login[language]}
                  </button>
                  <p className="text-center mb25 mt10">
                    {authPageData.dontHaveAnAccount[language]}
                    <Link to="/register">
                      {authPageData.createAccount[language]}
                    </Link>
                  </p>
                  <div className="hr_content">
                    <hr />
                    <span className="hr_top_text">
                      {authPageData.or[language]}
                    </span>
                  </div>
                  <ul className="login_with_social text-center mt30 mb0">
                    <li className="list-inline-item">
                      <a>
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a>
                        <i className="fab fa-google"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a>
                        <i className="fab fa-apple"></i>
                      </a>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
