import usePagination from "../../../../hooks/usePagination";
import TablePagination from "../../../../shared/components/TablePagination";
import BannerTableBody from "./BannerTableBody";
import BannerTableHeader from "./BannerTableHeader";

const BannerTable = ({ filter, banners, onDelete, onEdit }) => {
  const { search } = filter;
  let filteredBanners = banners;

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredBanners = banners.filter((banner) =>
      banner.text_eng.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredBanners, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <BannerTableHeader />
          <BannerTableBody
            onDelete={onDelete}
            onEdit={onEdit}
            banners={currentData()}
          />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default BannerTable;
