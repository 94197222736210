import axiosInstance from "../../interceptor/axiosInstance";

export const userLogin = async (data) => {
  const response = await axiosInstance.post("/user/login/", {
    ...data,
  });
  return response.data;
};

export const userRegister = async (data) => {
  const response = await axiosInstance.post("/user/register/", {
    ...data,
  });
  return response.data;
};
