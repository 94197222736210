import axiosInstance from "../../interceptor/axiosInstance";

export const vendorLogin = async (data) => {
  const response = await axiosInstance.post("/vendor/login/", {
    ...data,
  });
  return response.data;
};

export const adminLogin = async (data) => {
  const response = await axiosInstance.post("/adminpanel/admin/login/", {
    ...data,
  });

  return response.data.data;
};
