import { useEffect } from "react";

const Modal = ({ show, onClose, title, children }) => {
  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "unset";
  }, [show]);
  if (!show) {
    return null;
  }
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains("modal")) {
      onClose();
    }
  };

  return (
    <div
      className="modal show d-block"
      tabIndex="-1"
      onClick={handleBackdropClick}
    >
      <div
        style={{
          width: "70%",
          margin: "0 auto",
        }}
        className="modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="modal-header">
            {title && <h5 className="modal-title">{title}</h5>}

            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </div>
  );
};

export default Modal;
