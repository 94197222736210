export const deliveryOptions = [
  { label: { eng: "Free", arb: "مجاني" }, value: 0, checked: false },
];

export const productTypeOptions = [
  {
    label: { eng: "Physical", arb: "مادي" },
    value: "physical",
    checked: false,
  },
  { label: { eng: "Digital", arb: "رقمي" }, value: "digital", checked: false },
  { label: { eng: "Both", arb: "كلاهما" }, value: "both", checked: false },
];

export const customPriceOptions = [
  { label: { eng: "Any Price", arb: "أي سعر" }, value: "all", checked: false },
  {
    label: { eng: "Under 25", arb: "أقل من 25" },
    value: "less_25",
    checked: false,
  },
  {
    label: { eng: "From 25 to 50", arb: "من 25 إلى 50" },
    value: "between25_50",
    checked: false,
  },
  {
    label: { eng: "From 50 to 100", arb: "من 50 إلى 100" },
    value: "between50_100",
    checked: false,
  },
  {
    label: { eng: "Over 100", arb: "أكثر من 100" },
    value: "over100",
    checked: false,
  },
];

export const discountOptions = [
  { label: { eng: "Above 5%", arb: "أعلى من 5%" }, value: 5, checked: false },
  {
    label: { eng: "Above 15%", arb: "أعلى من 15%" },
    value: 15,
    checked: false,
  },
  {
    label: { eng: "Above 25%", arb: "أعلى من 25%" },
    value: 25,
    checked: false,
  },
  {
    label: { eng: "Above 50%", arb: "أعلى من 50%" },
    value: 50,
    checked: false,
  },
];

export const filterText = {
  productType: {
    eng: "Product Type",
    arb: "نوع المنتج",
  },
  price: {
    eng: "Price",
    arb: "سعر",
  },
  discount: {
    eng: "Discount",
    arb: "تخفيض",
  },
  delivery: {
    eng: "Delivery",
    arb: "توصيل",
  },
};



export const hiddenSideBar ={
    allFilters:{
        eng:"",
        arb:""
    }
}
