import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MobileNavCategoryItem = ({ categoryItem, index }) => {
  const { language } = useSelector((state) => state.setting);
  const { id, name_eng, name_arb } = categoryItem;
  const mmRef = "mm-" + (5 + index);
  return (
    <li className="mm-listitem">
      <a
        aria-owns={mmRef}
        className={`mm-btn mm-btn_next ${
          language === "eng" ? "" : "mm-btn_next__arb"
        } mm-listitem__btn mm-listitem__text`}
        href={"#" + mmRef}
        aria-haspopup={true}
      >
        <i
          className={`flaticon-cooking ${language === "eng" ? "mr20" : "ml20"}`}
        ></i>
        {language === "eng" ? name_eng : name_arb}
        <span className="mm-sronly">Open submenu</span>
      </a>
    </li>
  );
};

export default MobileNavCategoryItem;
