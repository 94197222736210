const PortalCategoryHeader = ({ role = "" }) => {
  return (
    <thead className="table-light">
      <tr className="text-center">
        <th scope="col">ID</th>
        <th scope="col">Category Name (Eng)</th>
        <th scope="col">Category Name (Arabic)</th>

        <th scope="col">Image</th>
        <th scope="col">Created At</th>
        {role && role === "admin" && <th scope="col"> Action</th>}
      </tr>
    </thead>
  );
};

export default PortalCategoryHeader;
