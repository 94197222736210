import React from "react";
import { useSelector } from "react-redux";
import ShoppingCartItem from "../components/ShoppingCart/ShoppingCartItem";
import { Link } from "react-router-dom";
import { shoppingCartPage } from "../constants/localization";
import { decimalNumberUpto2 } from "../utils/decimalNumberUpto2";

function ShoppingCart() {
  const { cartProducts } = useSelector((state) => state.cart);
  const { language } = useSelector((state) => state.setting);

  const getTotalDiscount = () =>
    cartProducts.reduce(
      (total, item) =>
        (total =
          total +
          item.quantity *
            (+item.sale_price - +item.sale_price * (1 - +item.discount / 100))),
      0
    );

  const getTotalWithoutDiscount = () =>
    cartProducts.reduce(
      (total, item) => (total = total + item.quantity * +item.sale_price),
      0
    );

  const getShippingCharges = () =>
    cartProducts.reduce(
      (total, item) => (total = total + item.delivery_fee),
      0
    );

  return (
    <section className="shop-cart pt30">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-4 m-auto">
            <div className="main-title text-center mb50">
              <h2 className="title">
                {shoppingCartPage.shoppingCart[language]}
              </h2>
            </div>
            <div className="text-center">
              <div className="list_last_content">
                <div className="lc">
                  <p className="para">{shoppingCartPage.offerText[language]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt15">
          <div className="col-lg-8 col-xl-9">
            <div className="shopping_cart_table table-responsive overflow-hidden ">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">{shoppingCartPage.product[language]}</th>
                    <th scope="col">{shoppingCartPage.price[language]}</th>
                    <th scope="col">
                      {shoppingCartPage.dicountPrice[language]}
                    </th>
                    <th scope="col">{shoppingCartPage.quantity[language]}</th>
                    <th scope="col">{shoppingCartPage.total[language]}</th>
                    <th scope="col">{shoppingCartPage.remove[language]}</th>
                  </tr>
                </thead>
                {cartProducts.length > 0 && (
                  <tbody className="table_body">
                    {cartProducts.map((cartItem, index) => (
                      <ShoppingCartItem key={index} item={cartItem} />
                    ))}
                  </tbody>
                )}
                {!cartProducts.length && (
                  <tbody>
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        {shoppingCartPage.noProduct[language]}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              <div className="checkout_form mt30">
                <div className="checkout_coupon posr d-block d-xl-flex justify-content-between">
                  <form className="form_one posr mb10-lg">
                    <input
                      className="form-control coupon_input"
                      type="search"
                      placeholder={shoppingCartPage.couponCode[language]}
                      aria-label="Search"
                    />
                    <a
                      className={`btn apply_count_btn ${
                        language === "eng"
                          ? "apply_count_btn__eng"
                          : "apply_count_btn__arb"
                      }`}
                    >
                      {shoppingCartPage.applyCoupon[language]}
                    </a>
                  </form>
                  <form>
                    <Link
                      to="/shop"
                      className="btn btn_shopping btn-white me-3"
                    >
                      {shoppingCartPage.continueShopping[language]}
                    </Link>
                    <button type="button" className="btn btn_cart btn3 btn-thm">
                      {shoppingCartPage.updateCart[language]}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-3">
            <div className="order_sidebar_widget style2">
              <h4 className="title">{shoppingCartPage.cartTotals[language]}</h4>
              <ul>
                <li className="subtitle">
                  <p>
                    {shoppingCartPage.productSubtotal[language]}
                    <span
                      className={
                        language === "eng" ? "float-end" : "float-start"
                      }
                    >
                      ${decimalNumberUpto2(getTotalWithoutDiscount())}
                    </span>
                  </p>
                </li>
                <li className="subtitle">
                  <p>
                    {shoppingCartPage.orderDiscounts[language]}
                    <span
                      className={
                        language === "eng" ? "float-end" : "float-start"
                      }
                    >
                      ${decimalNumberUpto2(getTotalDiscount())}
                    </span>
                  </p>
                </li>
                <li className="subtitle">
                  <p>
                    {shoppingCartPage.estimatedShipping[language]}
                    <span
                      className={
                        language === "eng" ? "float-end" : "float-start"
                      }
                    >
                      ${getShippingCharges()}
                    </span>
                  </p>
                </li>

                <li className="subtitle">
                  <hr />
                </li>
                <li className="subtitle totals">
                  <p>
                    {shoppingCartPage.total[language]}
                    <span
                      className={
                        language === "eng" ? "float-end" : "float-start"
                      }
                    >
                      $
                      {decimalNumberUpto2(
                        getTotalWithoutDiscount() -
                          getTotalDiscount() +
                          getShippingCharges() || 0
                      )}
                    </span>
                  </p>
                </li>
              </ul>
              <div className="ui_kit_button payment_widget_btn">
                <Link to={"/checkout"} className="btn btn-thm btn-block">
                  {shoppingCartPage.proceedToCheckout[language]}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShoppingCart;
