const BrandTableHeader = () => {
  return (
    <thead className="table-light">
      <tr className="text-center">
        <th scope="col">ID</th>
        <th scope="col">Category</th>
        <th scope="col">Image</th>
        <th scope="col">Text Eng</th>
        <th scope="col">Text Arb</th>
        <th scope="col"> Action</th>
      </tr>
    </thead>
  );
};

export default BrandTableHeader;
