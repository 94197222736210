import { useEffect, useRef } from "react";

function useDynamicPanels(categoriesWithLevel, language) {
  const navContainerRef = useRef(null);

  useEffect(() => {
    if (categoriesWithLevel.length > 0 && navContainerRef.current) {
      const panelContainer = navContainerRef.current.firstChild;
      if (language !== "eng") {
        navContainerRef.current.classList.add("mm-menu_offcanvas__right");
      }
      for (let i = 0; i < categoriesWithLevel.length; i++) {
        const divElement = document.createElement("div");
        const mmRef = "mm-" + (i + 5);
        divElement.id = mmRef;
        divElement.className = "mm-panel mm-hidden mm-panel_has-navbar";
        divElement.setAttribute("aria-hidden", "true");
        divElement.innerHTML = `
        <div class="mm-navbar">
          <a class="mm-btn mm-btn_prev ${
            language === "eng" ? "" : "mm-btn_prev__arb"
          } mm-navbar__btn" href="#mm-0" aria-owns="mm-0" aria-haspopup="true">
            <span class="mm-sronly">Close submenu</span>
          </a>
          <a class="mm-navbar__title" href="#${mmRef}">${
          language === "eng"
            ? categoriesWithLevel[i].name_eng
            : categoriesWithLevel[i].name_arb
        }</a>
        </div>
        <ul class="mm-listview">
          ${categoriesWithLevel[i].sub_cat
            .map(
              (subLevel) => `
            <li class="mm-listitem">
              <a onclick="{
                var selectionFired = new CustomEvent('selectionFired', {
                    'detail': {'id':'${subLevel.id}','name':'${
                subLevel.name_eng
              }' }
                  });
                
                  document.dispatchEvent(selectionFired);
              }" class="mm-listitem__text">${
                language === "eng" ? subLevel.name_eng : subLevel.name_arb
              }</a>
            </li>
          `
            )
            .join("")} 
        </ul>
      `;
        panelContainer.append(divElement);
      }
    }
  }, [categoriesWithLevel, language]);

  return navContainerRef;
}

export default useDynamicPanels;
