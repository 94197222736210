import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllBanners } from "../../../admin/services/banner";
import { getAllVendors } from "../../services/vendorShop";

const initialState = {
  banners: [],
  vendors: [],
  commonLoading: true,
};

export const getCommonData = createAsyncThunk(
  "common/banners/list",
  async (thunkAPI) => {
    try {
      const banners = await getAllBanners();
      const vendors = await getAllVendors();
      return { banners, vendors };
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommonData.pending, (state) => {
        state.commonLoading = true;
      })
      .addCase(getCommonData.fulfilled, (state, action) => {
        state.commonLoading = false;
        state.banners = action.payload.banners;
        state.vendors = action.payload.vendors;
      })
      .addCase(getCommonData.rejected, (state) => {
        state.commonLoading = false;
      });
  },
});

export default commonSlice.reducer;
