import axiosInstance from "../../interceptor/axiosInstance";

export const getAllAdminVendors = async () => {
  const response = await axiosInstance.get("/adminpanel/vendor/list/");
  return response.data.data || [];
};

export const createVendor = async (data) => {
  const response = await axiosInstance.post("vendor/register/", {
    ...data,
  });
  return response.data.data;
};

export const updateVendorStatus = async (vendorId, status) => {
  const response = await axiosInstance.patch(
    "adminpanel/vendor/status/?status=" + status + "&ven_id=" + vendorId
  );
  return response.data;
};
