import { Link } from "react-router-dom";

const SaleOrderRow = ({ deliveryItem }) => {
  const {
    id,
    name,
    customer_id,
    vendor_id,
    order_state,
    total_with_tax,
    total_without_tax,
    paid_amount,
    invoice_amount,
  } = deliveryItem;

  const { name: customerName } = customer_id;
  const { name: vendorName } = vendor_id;

  return (
    <tr>
      <th scope="row">#{id}</th>
      <td>{name}</td>
      <td>{customerName}</td>
      <td>{vendorName}</td>
      <td className="status">
        <span>{order_state}</span>
      </td>
      <td>{invoice_amount}</td>
      <td>{paid_amount}</td>

      <td>{total_with_tax}</td>
      <td>{total_without_tax}</td>
    </tr>
  );
};

export default SaleOrderRow;
