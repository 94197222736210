import axiosInstance from "../../interceptor/axiosInstance";

export const getVendorCategories = async () => {
  const response = await axiosInstance.get("/all/category");
  return response.data.data || [];
};

export const getCategoriesForProduct = async () => {
  const response = await axiosInstance.get("/product_category");
  return response.data.data || [];
};
