const InvoiceSaleOrderItem = ({ saleOrderItem }) => {
  const {
    product_id,
    variant_id,
    quantity,
    unit_price,
    subtotal,
    tax,
    discount,
  } = saleOrderItem;
  const product = product_id ? product_id : variant_id;
  const { name_eng, main_image } = product;
  return (
    <tr>
      <td className="body-color">
        <img
          src={process.env.REACT_APP_BASE_URL_LIVE + main_image}
          alt="Product Image"
          style={{
            width: "60px",
            height: "60px",
          }}
        />
      </td>
      <td className="body-color">
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            whiteSpace: "nowrap",
          }}
        >
          {name_eng}
        </p>
      </td>
      <td className="body-color">{quantity}</td>
      <td className="body-color">{unit_price}</td>
      <td className="body-color">{tax}</td>
      <td className="body-color">{discount}</td>
      <td className="body-color">{subtotal}</td>
    </tr>
  );
};

export default InvoiceSaleOrderItem;
