import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";

import Modal from "../../shared/components/Modal";
import useApi from "../../hooks/useAPI";
import Error from "../../shared/components/Error";
import { getAllAdminVendors, updateVendorStatus } from "../services/vendor";
import VendorFilter from "../components/vendors/VendorFilter";
import VendorTable from "../components/vendors/Table/VendorTable";

const AdminVendors = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [vendorId, setVendorId] = useState(-1);
  const [vendors, setVendors] = useState([]);

  const { data, error, loading, request } = useApi(getAllAdminVendors);

  const [filter, setFilter] = useState({
    search: "",
  });
  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setVendors(data);
    }
  }, [loading, data]);

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  const handleEdit = (id) => {
    setVendorId(id);
    openModal();
  };

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const resetState = () => {
    setVendorId(-1);
    closeModal();
  };

  const confirmStatusUpdate = async (status) => {
    const response = await updateVendorStatus(vendorId, status);
    if (response.flag) {
      setVendors((prevState) =>
        prevState.map((vendor) =>
          vendor.id === vendorId
            ? { ...vendor, is_active: status === "active" ? true : false }
            : vendor
        )
      );
    }
    resetState();
  };

  const getCategoryName = (id) => vendors.find((vendor) => vendor.id === id);

  if (loading) return <Preloader />;

  if (!loading && error) return <Error error={error} />;

  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>vendors</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <VendorFilter filter={filter} onChangeFilter={handleFilterChange} />
            <VendorTable
              onEdit={handleEdit}
              vendors={vendors}
              filter={filter}
            />
          </div>
        </div>
      </div>
      <Modal show={modalOpen} onClose={closeModal} title="">
        {vendorId > -1 && (
          <>
            <h6>
              Are you sure you want to change the status of the
              <span style={{ fontWeight: "bold", color: "red" }}>
                {" " + getCategoryName(vendorId).name}
              </span>
              ?
            </h6>
            <div className="d-flex justify-content-end gap-2">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => confirmStatusUpdate("inactive")}
              >
                In Active
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => confirmStatusUpdate("active")}
              >
                Active
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AdminVendors;
