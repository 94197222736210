import { Route, Routes } from "react-router-dom";
import VendorProducts from "./pages/Products";
import UpdateProduct from "./pages/UpdateProduct";
import CreateProduct from "./pages/CreateProduct";
import VendorDashboard from "./pages/Dashboard";
import VendorOrders from "./pages/Orders";
import VendorCategories from "./pages/Categories";
import OrderDetail from "./pages/OrderDetail";
import VendorInvoices from "./pages/Invocies";
import VendorDeliveries from "./pages/VendorDeliveries";
import VendorInvoiceDetail from "./pages/InvoiceDetail";
import VendorDeliveryDetail from "./pages/VendorDeliveryDetail";

const VendorRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<VendorDashboard />} />
      <Route path="/products" element={<VendorProducts />} />
      <Route path="/products/create" element={<CreateProduct />} />
      <Route path="/products/:id/edit" element={<UpdateProduct />} />
      <Route path="/orders" element={<VendorOrders />} />
      <Route path="/categories" element={<VendorCategories />} />
      <Route path="/invoices" element={<VendorInvoices />} />
      <Route path="/deliveries" element={<VendorDeliveries />} />
      <Route path="/order/:id/detail" element={<OrderDetail />} />
      <Route path="/invoice/:id/detail" element={<VendorInvoiceDetail />} />
      <Route path="/delivery/:id/detail" element={<VendorDeliveryDetail />} />
    </Routes>
  );
};

export default VendorRoutes;
