import { useEffect, useState } from "react";
import { getMyReviews } from "../services/myReviews";
import Preloader from "../../shared/components/Preloader";
import { useSelector } from "react-redux";
import CustomerPortalLayout from "../components/CustomerPortal/CustomerPortalLayout";
import RatingStar from "../components/ProductDetail/RatingStar";
import { URL_PATH } from "../../shared/constants/common";
import { myReviewsPage } from "../constants/localization";

const MyReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const { loading: loader } = useSelector((state) => state.category);
  const { language } = useSelector((state) => state.setting);

  useEffect(() => {
    if (!loader)
      (async () => {
        const response = await getMyReviews();
        setReviews(response);
        setLoading(false);
      })();
  }, [loader]);

  if (loading) return <Preloader />;
  return (
    <CustomerPortalLayout>
      <div className="container mt-4">
        <div className="row mb-4">
          <div className="col">
            <h2>{myReviewsPage.myReviews[language]}</h2>
          </div>
        </div>

        <div className="column">
          {reviews.length > 0 ? (
            reviews.map((review) => (
              <div
                key={review.id}
                className="d-flex shadow-sm"
                style={{ marginBottom: "30px" }}
              >
                <div style={{ width: "30%", padding: "20px 10px" }}>
                  <img
                    src={
                      review.product_id_main_image
                        ? URL_PATH + review.product_id_main_image
                        : "/images/no-image.png"
                    }
                    alt="test"
                  />
                </div>
                <div className="card">
                  <div
                    className="card-header"
                    style={{
                      backgroundColor: "transparent",
                      borderBottom: "none",
                    }}
                  >
                    <h5 className="card-title mb-0">
                      {language === "eng"
                        ? review.product_id.name_eng
                        : review.product_id.name_arb}
                    </h5>
                    <small className="text-muted">
                      by {review.userprofile.name}
                    </small>
                  </div>

                  <div className="card-body">
                    <p className="card-text">{review.review_text}</p>
                    <div className="mb-2">
                      <RatingStar rating={review.rating} />
                    </div>
                    {review.images.length > 0 && (
                      <div className="d-flex flex-wrap">
                        {review.images.map((image) => (
                          <div key={image.id} className="me-2 mb-2">
                            <img
                              src={URL_PATH + image.images}
                              alt="Review Image"
                              className="img-thumbnail"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12 text-center">
              <p>No reviews available.</p>
            </div>
          )}
        </div>
      </div>
    </CustomerPortalLayout>
  );
};

export default MyReviews;
