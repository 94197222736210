import axiosInstance from "../../interceptor/axiosInstance";

export const getAllTagsListAdmin = async () => {
  const response = await axiosInstance.get("/adminpanel/tag/list/");
  return response.data.data;
};

export const createTag = async (data) => {
  const response = await axiosInstance.post("adminpanel/tag/create/", {
    ...data,
  });
  return response.data.data;
};

export const updateTag = async (id, data) => {
  const response = await axiosInstance.patch(
    "adminpanel/tag/update/?tag_id=" + id,
    {
      ...data,
    }
  );
  return response.data.data;
};

export const deleteTag = async (id) => {
  const response = await axiosInstance.delete(
    "/adminpanel/tag/delete/?tag_id=" + id
  );
  return response.data;
};
