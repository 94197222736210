import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getVendorDeliveriesBySaleOrderId } from "../services/order";
import Preloader from "../../shared/components/Preloader";

import DeliveryItem from "../components/Deliveries/DeliveryDetail/DeliveryItem";

const classes = {
  DRAFT: "status-draft",
  SALE: "status-sale",
  DONE: "status-done",
  CANCELLED: "status-cancelled",
};

const VendorDeliveryDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [delivery, setDelivery] = useState();

  useEffect(() => {
    (async () => {
      const response = await getVendorDeliveriesBySaleOrderId(id);
      setDelivery(response);
      setLoading(false);
    })();
  }, [id]);

  if (loading) return <Preloader />;

  const { customer_id, order_state, delivery_address } = delivery;
  const { name, phone, address, user } = customer_id;

  const { country, street, zip_code, city } = address;

  const { email } = user;

  return (
    <div className="order_detail_body invoice_form p-3">
      <div className="order_detail_body">
        <div className="main-title text-center">
          <h2>Delivery Detail</h2>
        </div>

        <div className="application_statics p-3">
          <h6 className="short_code_title">
            Sale Order # <span>{id}</span>
            <span className={classes[order_state]}>{order_state}</span>
          </h6>
          <h6 className="short_code_title">
            Tracking Reference # {delivery.tracking_reference}
          </h6>
          <h6 className="short_code_title">
            Schedule Date {delivery.schedule_date}
          </h6>
          <h6>
            Address
            {` ${street}, ${city}, ${zip_code}, ${country}`}
          </h6>

          <div className="account_user_deails dashboard_page">
            <div className="order_table table-responsive">
              <table className="table">
                <thead className="table-light">
                  <tr className="text-center">
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {delivery.delivery_lines.map((deliveryItem) => (
                    <DeliveryItem
                      key={deliveryItem.id}
                      deliveryItem={deliveryItem}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VendorDeliveryDetail;
