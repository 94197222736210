import axiosInstance from "../../interceptor/axiosInstance";

export const getAllParentCategories = async () => {
  const response = await axiosInstance.get("/all/category/");
  return response.data.data || [];
};

export const getAllCategoriesAdmin = async () => {
  const response = await axiosInstance.get("/adminpanel/category/list/");
  return response.data.data || [];
};

export const createCategory = async (data) => {
  const response = await axiosInstance.post("adminpanel/category/create/", {
    ...data,
  });
  return response.data.data;
};

export const updateCategory = async (id, data) => {
  const response = await axiosInstance.patch(
    "adminpanel/category/update/?id=" + id,
    {
      ...data,
    }
  );
  return response.data.data;
};

export const deleteCategory = async (id) => {
  const response = await axiosInstance.delete(
    "/adminpanel/category/delete/?cat_id=" + id
  );
  return response.data;
};
