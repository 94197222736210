import DashboardStatistics from "../components/Dashboard/EarningStatistics";
import DashboardTop from "../components/Dashboard/DashboardTop";
import EarningStatistics from "../components/Dashboard/DashboardChart";
import OrderListing from "../components/Dashboard/Orders/OrderListing";
import { useEffect, useState } from "react";
import { getDashboardOrderStat, getRecentOrders } from "../services/dashboard";
import Preloader from "../../shared/components/Preloader";

const VendorDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [recentOrders, setRecentOrders] = useState([]);
  const [dashboardStat, setDashboardStat] = useState();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getRecentOrders();
      const responseStat = await getDashboardOrderStat();
      setDashboardStat(responseStat);
      setRecentOrders(response);
      setLoading(false);
    })();
  }, []);

  if (loading) return <Preloader />;
  return (
    <div className="dashboard__content bgc-gmart-gray">
      <DashboardTop />
      {dashboardStat && <DashboardStatistics dashboardStat={dashboardStat} />}
      <EarningStatistics />
      <OrderListing recentOrders={recentOrders} />
    </div>
  );
};

export default VendorDashboard;
