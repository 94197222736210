const VendorDeliveryTableRow = ({ orderItem, onView, index }) => {
  const {
    id,
    name,
    schedule_date,
    tracking_reference,
    delivery_state,
    customer_id,
    delivery_address,
    sale_order,
  } = orderItem;

  return (
    <>
      <tr>
        <th scope="row">#{id}</th>
        <td>{name}</td>
        <td>{schedule_date}</td>
        <td>{tracking_reference}</td>
        <td className="status">
          <span>{delivery_state}</span>
        </td>
        <td>{customer_id.name}</td>
        <td>{customer_id.address.address}</td>
        <td className="d-flex gap-2">
          {sale_order && (
            <button
              onClick={() => onView(id, index)}
              className="btn btn-custom"
            >
              <i className="fa-regular fa-eye"></i>
            </button>
          )}
        </td>
      </tr>
    </>
  );
};

export default VendorDeliveryTableRow;
