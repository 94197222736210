import { useRef, useState } from "react";
import ReactSelect from "react-select";
import { createVendor } from "../services/vendor";
import { useNavigate } from "react-router-dom";

const initialState = {
  email: null,
  password: null,
  name: null,
  shop_name: null,
  shop_img: null,
  company: null,
  phone: null,
  tax_id: "",
  bank_name: null,
  account_number: null,
  iban: null,
  country: null,
  street: null,
  address: null,
  zip_code: null,
  city: null,
};

const countries = [
  "Turkey",
  "United Kingdom",
  "United States",
  "Ukraine",
  "Uruguay",
  "UK",
  "Uzbekistan",
  "India",
  "China",
  "Brazil",
  "Canada",
  "Australia",
  "Germany",
  "France",
  "Japan",
  "Italy",
  "Spain",
  "Russia",
  "South Korea",
  "Mexico",
  "Indonesia",
  "Netherlands",
  "Saudi Arabia",
  "Switzerland",
  "Sweden",
];

const options = countries.map((country) => ({
  label: country,
  value: country,
}));

const VendorRegisterForm = () => {
  const shopImageRef = useRef(null);
  const [userForm, setUserForm] = useState(initialState);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    if (name === "shop_img") {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64File = e.target.result;
          setUserForm((prevState) => ({
            ...prevState,
            [name]: base64File,
          }));
        };
        reader.onerror = (error) => {
          console.error("Error reading file: ", error);
        };
        reader.readAsDataURL(file);
      }
    } else {
      const { value } = e.target;
      setUserForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelect = (value) => {
    setUserForm((prevState) => ({
      ...prevState,
      country: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      email,
      password,
      name,
      address,
      account_number,
      bank_name,
      company,
      country,
      iban,
      phone,
      shop_img,
      shop_name,
      street,
      tax_id,
      zip_code,
      city,
    } = userForm;

    const data = {
      user: {
        email,
        password,
      },
      address: {
        street,
        zip_code,
        country: country?.value || null,
        address,
        city,
      },
      name,
      bank_id: {
        name: bank_name,
        account_number,
        iban,
      },
      tax_id,
      phone,
      shop_img: shop_img ? shop_img.slice(shop_img.indexOf(",") + 1) : null,
      shop_name,
      company,
    };

    await createVendor(data);
    navigate("/admin/vendors");
  };

  const handleFileDelete = () => {
    setUserForm((prevState) => ({
      ...prevState,
      shop_img: "",
    }));

    if (shopImageRef.current) {
      shopImageRef.current.value = "";
    }
  };
  return (
    <div
      style={{
        marginTop: "50px",
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <div className="col-9">
        <form onSubmit={handleSubmit} className="form2">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Email *</label>
                <input
                  onChange={handleChange}
                  name="email"
                  className="form-control form_control"
                  type="email"
                  placeholder="Email"
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Password *</label>
                <input
                  onChange={handleChange}
                  name="password"
                  className="form-control form_control"
                  type="password"
                  placeholder="Password"
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Vendor Name *</label>
                <input
                  onChange={handleChange}
                  name="name"
                  className="form-control form_control"
                  type="text"
                  placeholder="Vendor Name"
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Shop Name *</label>
                <input
                  onChange={handleChange}
                  name="shop_name"
                  className="form-control form_control"
                  type="text"
                  placeholder="Shop Name"
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Tax Id *</label>
                <input
                  onChange={handleChange}
                  name="tax_id"
                  className="form-control form_control"
                  type="text"
                  placeholder="Tax Id"
                  required
                />
              </div>
            </div>{" "}
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Company *</label>
                <input
                  onChange={handleChange}
                  name="company"
                  className="form-control form_control"
                  type="text"
                  placeholder="Company Name"
                  required
                />
              </div>
            </div>
            <div className="custom-field">
              <h5 className="short_code_title">Shop Image</h5>
              <div className="form-group">
                <input
                  onChange={handleChange}
                  name="shop_img"
                  type="file"
                  className="form-control"
                  accept="image/*"
                  placeholder="Upload Image"
                  ref={shopImageRef}
                />
              </div>
              {userForm.shop_img && (
                <div className="image-container">
                  <img src={userForm.shop_img} alt="Preview Image" />
                  <div
                    onClick={() => handleFileDelete()}
                    className="close-icon"
                  >
                    &times;
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label">Country / Region *</label>
                <ReactSelect
                  required
                  options={options}
                  value={userForm.country}
                  onChange={(value) => handleSelect(value)}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">Address *</label>
                <input
                  onChange={handleChange}
                  name="address"
                  className="form-control form_control mb10"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">Street *</label>
                <input
                  onChange={handleChange}
                  name="street"
                  className="form-control form_control mb10"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">Town / City *</label>
                <input
                  onChange={handleChange}
                  name="city"
                  className="form-control form_control"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">ZIP *</label>
                <input
                  onChange={handleChange}
                  name="zip_code"
                  className="form-control form_control"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">Bank Name *</label>
                <input
                  onChange={handleChange}
                  name="bank_name"
                  className="form-control form_control"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">Account Number *</label>
                <input
                  onChange={handleChange}
                  name="account_number"
                  className="form-control form_control"
                  type="number"
                  required
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">IBan *</label>
                <input
                  onChange={handleChange}
                  name="iban"
                  className="form-control form_control"
                  type="number"
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Phone *</label>
                <input
                  onChange={handleChange}
                  name="phone"
                  className="form-control form_control"
                  type="number"
                  required
                />
              </div>
            </div>
            <button type="submit" className="btn btn-log btn-thm mt20">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VendorRegisterForm;
