import usePagination from "../../../../hooks/usePagination";
import TablePagination from "../../../../shared/components/TablePagination";
import BrandTableBody from "./BrandTableBody";
import BrandTableHeader from "./BrandTableHeader";

const BrandTable = ({ filter, brands, onDelete, onEdit }) => {
  const { search } = filter;
  let filteredBrands = brands;

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredBrands = brands.filter((category) =>
      category.name.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredBrands, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <BrandTableHeader />
          <BrandTableBody
            onDelete={onDelete}
            onEdit={onEdit}
            brands={currentData()}
          />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default BrandTable;
