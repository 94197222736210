import axiosInstance from "../../interceptor/axiosInstance";

export const createPaymentIntent = async (data) => {
  try {
    const response = await axiosInstance.post("/create-payment-intent/", {
      ...data,
    });
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const createSaleOrder = async (data) => {
  try {
    const response = await axiosInstance.post("/order/create/", {
      ...data,
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};
