import axiosInstance from "../../interceptor/axiosInstance";

export const getPolicyAdmin = async () => {
  const response = await axiosInstance.get("/products/policy/");
  return response.data.data[0] || { id: null, policy: "" };
};

export const updatePolicy = async (id, data) => {
  const response = await axiosInstance.patch(
    "/products/policy/update/?id=" + id,
    {
      ...data,
    }
  );
  return response.data.data;
};
