import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UserPrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state.userAuth);
  const { loading } = useSelector((state) => state.category);
  const { commonLoading } = useSelector((state) => state.common);

  if (!loading && !commonLoading && user) {
    return children;
  }

  if (!loading && !commonLoading && !user) {
    return <Navigate to="/signin" />;
  }
};

export default UserPrivateRoute;
