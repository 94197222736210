import { useDispatch, useSelector } from "react-redux";
import HeaderMiddleAdvanceSearch from "./HeaderMiddleAdvanceSearch";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Preloader from "../../../shared/components/Preloader";
import LanguageSelector from "./LanguageSelector";
import { decimalNumberUpto2 } from "../../utils/decimalNumberUpto2";
import {
  headerMiddleComponent,
  loginUserMenu,
} from "../../constants/localization";
import { onUserLogOut } from "../../store/userAuthSlice/userAuthSlice";
import { getLoginUserName } from "../../utils/common";

const HeaderMiddle = () => {
  const { cartProducts } = useSelector((state) => state.cart);
  const { loading } = useSelector((state) => state.category);
  const { user } = useSelector((state) => state.userAuth);
  const { language } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isShowUserMenus, setIsShowUserMenus] = useState(false);

  useEffect(() => {
    if (!loading) {
      const rootContainer = document.getElementById("root");
      if (language === "eng") {
        rootContainer.classList.remove("mm-slideout-arb");
        rootContainer.classList.add("mm-slideout");
      } else {
        rootContainer.classList.remove("mm-slideout");
        rootContainer.classList.add("mm-slideout-arb");
      }
    }
  }, [loading]);

  useEffect(() => {
    if (language === "eng") {
      document.body.setAttribute("dir", "ltr");
    } else if (language === "arb") {
      document.body.setAttribute("dir", "rtl");
    }
  }, [language]);

  const handleShowCart = () => {
    document.body.classList.add("cart-hidden-sidebar-content");
  };

  const getTotal = () =>
    cartProducts.reduce((total, item) => {
      const discountAmount = item.sale_price * (item.discount / 100);
      const itemTotal = item.quantity * (item.sale_price - discountAmount);
      return total + itemTotal;
    }, 0);

  const handleShowSignIn = () =>
    document.body.classList.add("signin-hidden-sidebar-content");

  if (loading) return <Preloader />;

  const handleLogout = () => {
    dispatch(onUserLogOut());
    navigate("/");
  };

  return (
    <div className="header_middle pt20 pb20 dn-992 d-flex">
      <div className="container">
        <div className="row">
          <div className="col-lg-1 col-xxl-1">
            <div className="header_top_logo_home1">
              <div className="logo">
                <Link className="text-thm" to="/">
                  {headerMiddleComponent.appLogoText[language]}
                </Link>
              </div>
            </div>
          </div>

          <HeaderMiddleAdvanceSearch />
          <div className="col-lg-1 col-xxl-1 pr0-lg language-container">
            <LanguageSelector />
          </div>
          <div className="col-lg-5 col-xxl-4 pr0-lg">
            <ul className="d-flex align-items-center">
              <li className="list-inline-item">
                <a className="header_top_iconbox">
                  <div className="d-block d-md-flex align-items-center">
                    <div
                      className={`icon ${
                        language === "eng" ? "icon__right" : "icon__left"
                      }`}
                    >
                      <span className="flaticon-heart" />
                    </div>
                    <div
                      style={{
                        paddingLeft: "10px",
                      }}
                      className="details"
                    >
                      <p className="subtitle"></p>
                      <h5 className="title">
                        {headerMiddleComponent.myItems[language]}
                      </h5>
                    </div>
                  </div>
                </a>
              </li>
              {user ? (
                <li
                  onClick={() => setIsShowUserMenus((prevState) => !prevState)}
                  className="list-inline-item login__user__container"
                >
                  <div className="header_top_iconbox signin-filter-btn">
                    <div className="d-flex align-items-center">
                      <div
                        className={`icon ${
                          language === "eng" ? "icon__right" : "icon__left"
                        }`}
                      >
                        <span>{getLoginUserName(user.name)}</span>
                      </div>
                    </div>
                  </div>
                  {isShowUserMenus && (
                    <div className="login__user__menus">
                      <Link className="user__menu__item" to="/my-orders">
                        {loginUserMenu.myOrders[language]}
                      </Link>
                      <Link className="user__menu__item" to="/my-reviews">
                        {loginUserMenu.myReviews[language]}
                      </Link>
                      <Link className="user__menu__item" to="/my-deliveries">
                        {loginUserMenu.myDeliveries[language]}
                      </Link>
                      <span onClick={handleLogout} className="user__menu__item">
                        {loginUserMenu.logout[language]}
                      </span>
                    </div>
                  )}
                </li>
              ) : (
                <li className="list-inline-item">
                  <a className="header_top_iconbox signin-filter-btn">
                    <div
                      onClick={handleShowSignIn}
                      className="d-block d-md-flex align-items-center"
                    >
                      <div
                        className={`icon ${
                          language === "eng" ? "icon__right" : "icon__left"
                        }`}
                      >
                        <span className="flaticon-profile" />
                      </div>
                      <div
                        style={{
                          paddingLeft: "10px",
                        }}
                        className="details"
                      >
                        <p className="subtitle">
                          {headerMiddleComponent.signIn[language]}
                        </p>
                        <h5 className="title">
                          {headerMiddleComponent.account[language]}
                        </h5>
                      </div>
                    </div>
                  </a>
                </li>
              )}
              <li className="list-inline-item">
                <a className="header_top_iconbox cart-filter-btn">
                  <div className="d-block d-md-flex align-items-center">
                    <div
                      className={`icon ${
                        language === "eng" ? "icon__right" : "icon__left"
                      }`}
                      onClick={handleShowCart}
                    >
                      <span>
                        <img src="/images/icons/flaticon-shopping-cart-white.svg" />
                      </span>
                      <span className="badge">{cartProducts.length}</span>
                    </div>
                    <div
                      style={{
                        paddingLeft: "10px",
                      }}
                      className="details"
                    >
                      <p className="subtitle">
                        ${decimalNumberUpto2(getTotal())}
                      </p>
                      <h5 className="title">
                        {headerMiddleComponent.total[language]}
                      </h5>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMiddle;
