import React, { useState } from "react";
import AppliedFilters from "./AppliedFilters";
import { useSelector } from "react-redux";
import { shopFilterSideBar } from "../../constants/localization";
import {
  deliveryOptions,
  customPriceOptions,
  discountOptions,
  filterText,
  productTypeOptions
} from "../../constants/filtersOptions";

const ShopFilters = ({ onFilter, customRange, filters, onRemoveFilter }) => {
  const [openPanel, setOpenPanel] = useState(null);
  const { language } = useSelector((state) => state.setting);

  const handleSelection = (name, checked, value) => {
    if (checked) {
      onFilter(name, value);
    }
  };

  const togglePanel = (panel) => {
    setOpenPanel(openPanel === panel ? null : panel);
  };

  return (
    <div className="col-lg-3 col-xl-2 d-none d-lg-block">
      <div className="sidebar_accordion_widget">
        <div className="faq_according text-start">
          <div className="accordion" id="accordionExample">
            <div className="card">
              <AppliedFilters
                filters={filters}
                onRemoveFilter={onRemoveFilter}
              />
            </div>
            <div className="card">
              <div className="card-header" id="headingZero">
                <h4>
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    onClick={() => togglePanel("zero")}
                    aria-expanded={openPanel === "zero"}
                  >
                    {filterText.productType[language]}
                  </button>
                </h4>
              </div>
              <div
                id="collapseZero"
                className={`collapse ${openPanel === "zero" ? "show" : ""}`}
                aria-labelledby="headingZero"
              >
                <div className="card-body">
                  <div className="ui_kit_checkbox pb30">
                    {productTypeOptions.map((item, index) => (
                      <label
                        className={`custom_radio_field ${
                          language === "eng" ? "" : "text-end"
                        }`}
                        key={index}
                      >
                        <input
                          onChange={(e) =>
                            handleSelection(
                              "product_type",
                              e.target.checked,
                              item.value
                            )
                          }
                          checked={filters.product_type === item.value}
                          type="radio"
                        />
                        <span className="mx-2">{item.label[language]}</span>
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Price */}
            <div className="card">
              <div className="card-header" id="headingTwo">
                <h4>
                  <button
                    className="btn btn-link text-start"
                    type="button"
                    onClick={() => togglePanel("two")}
                    aria-expanded={openPanel === "two"}
                  >
                    {filterText.price[language]}
                  </button>
                </h4>
              </div>
              <div
                id="collapseTwo"
                className={`collapse ${openPanel === "two" ? "show" : ""}`}
                aria-labelledby="headingTwo"
              >
                <div>
                  {customPriceOptions.map((item, index) => (
                    <label
                      className={`custom_radio_field ${
                        language === "eng" ? "" : "text-end"
                      }`}
                      key={index}
                    >
                      <input
                        onChange={(e) =>
                          handleSelection(
                            "priceRange",
                            e.target.checked,
                            item.value
                          )
                        }
                        checked={filters.priceRange === item.value}
                        type="radio"
                      />
                      <span className="mx-2">{item.label[language]}</span>
                      <span className="checkmark"></span>
                    </label>
                  ))}
                </div>
                <h4 className={language === "eng" ? "" : "text-end"}>
                  {shopFilterSideBar.customPriceRange[language]}
                </h4>
                <div className="d-flex gap-1">
                  <input
                    type="number"
                    className="amount"
                    placeholder="Min"
                    value={customRange.min}
                    onChange={(e) =>
                      onFilter("customRange", {
                        ...customRange,
                        min: +e.target.value,
                      })
                    }
                  />
                  <input
                    type="number"
                    className="amount"
                    placeholder="Max"
                    value={customRange.max}
                    onChange={(e) =>
                      onFilter("customRange", {
                        ...customRange,
                        max: +e.target.value,
                      })
                    }
                  />
                </div>

                <button
                  onClick={() =>
                    onFilter("customRange", {
                      ...customRange,
                      isConfirmed: true,
                    })
                  }
                  className={`btn btn-thm mt-2 ${
                    language === "eng" ? "" : "float-end"
                  }`}
                >
                  {shopFilterSideBar.confirm[language]}
                </button>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingThree">
                <h4>
                  <button
                    className="btn btn-link text-start"
                    type="button"
                    onClick={() => togglePanel("three")}
                    aria-expanded={openPanel === "three"}
                  >
                    {filterText.discount[language]}
                  </button>
                </h4>
              </div>
              <div
                id="collapseThree"
                className={`collapse ${openPanel === "three" ? "show" : ""}`}
                aria-labelledby="headingThree"
              >
                <div className="card-body">
                  <div className="ui_kit_checkbox pb30">
                    {discountOptions.map((item, index) => (
                      <label
                        className={`custom_radio_field ${
                          language === "eng" ? "" : "text-end"
                        }`}
                        key={index}
                      >
                        <input
                          onChange={(e) =>
                            handleSelection(
                              "discount",
                              e.target.checked,
                              item.value
                            )
                          }
                          checked={filters.discount === item.value}
                          type="radio"
                        />
                        <span className="mx-2">{item.label[language]}</span>
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFour">
                <h4>
                  <button
                    className="btn btn-link text-start"
                    type="button"
                    onClick={() => togglePanel("four")}
                    aria-expanded={openPanel === "four"}
                  >
                    {filterText.delivery[language]}
                  </button>
                </h4>
              </div>
              <div
                id="collapseThree"
                className={`collapse ${openPanel === "four" ? "show" : ""}`}
                aria-labelledby="headingThree"
              >
                <div className="card-body">
                  <div className="ui_kit_checkbox pb30">
                    {deliveryOptions.map((item, index) => (
                      <label
                        className={`custom_radio_field ${
                          language === "eng" ? "" : "text-end"
                        }`}
                        key={index}
                      >
                        <input
                          onChange={(e) =>
                            handleSelection(
                              "delivery_fee",
                              e.target.checked,
                              item.value
                            )
                          }
                          checked={filters.delivery_fee === item.value}
                          type="radio"
                        />
                        <span className="mx-2">{item.label[language]}</span>
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopFilters;
