import { useEffect, useState } from "react";
import Preloader from "../../shared/components/Preloader";
import PortalCategoryFilter from "../../shared/components/Categories/CategoryFilter";
import PortalCategoryTable from "../../shared/components/Categories/Table/PortalCategoryTable";
import { getAllCategoriesAdmin } from "../services/category";
import Modal from "../../shared/components/Modal";
import CategoryForm from "../forms/CategoryForm";
import { deleteCategory } from "../services/category";
import useApi from "../../hooks/useAPI";
import Error from "../../shared/components/Error";

const AdminCategories = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editId, setEditID] = useState(-1);
  const [deleteId, setDeleteID] = useState(-1);
  const [mode, setMode] = useState("none");
  const [categories, setCategories] = useState([]);

  const { data, error, loading, request } = useApi(getAllCategoriesAdmin);

  const [filter, setFilter] = useState({
    search: "",
  });
  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setCategories(data);
    }
  }, [loading, data]);

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  const handleEdit = (id) => {
    setMode("UPDATE");
    setDeleteID(-1);
    setEditID(id);
    openModal();
  };

  const handleDelete = (id) => {
    setMode("NONE");
    setEditID(-1);
    setDeleteID(id);
    openModal();
  };

  const handleCreate = () => {
    setMode("CREATE");
    setEditID(-1);
    setDeleteID(-1);
    openModal();
  };

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const resetState = () => {
    setEditID(-1);
    setDeleteID(-1);
    closeModal();
  };

  const confirmDelete = async () => {
    //call api to Delete
    const response = await deleteCategory(deleteId);
    if (response.flag) {
      setCategories((prevState) =>
        prevState.filter((cat) => cat.id !== deleteId)
      );
    }
    resetState();
  };

  const getCategoryName = (id) => categories.find((cat) => cat.id === id);

  const handleSubmit = (data) => {
    if (editId > -1) {
      setCategories((prevState) =>
        prevState.map((item) => (item.id === editId ? { ...data } : item))
      );
    } else {
      setCategories((prevState) => [...prevState, { ...data }]);
    }
    resetState();
  };

  if (loading) return <Preloader />;

  if (!loading && error) return <Error error={error} />;

  return (
    <div className="dashboard__content bgc-gmart-gray">
      <div className="row pb50">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>Categories</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard_product_list account_user_deails">
            <PortalCategoryFilter
              onClick={handleCreate}
              role="admin"
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
            <PortalCategoryTable
              onEdit={handleEdit}
              onDelete={handleDelete}
              role="admin"
              categories={categories}
              filter={filter}
            />
          </div>
        </div>
      </div>
      <Modal show={modalOpen} onClose={closeModal} title="">
        {(mode == "CREATE" || mode == "UPDATE") && (
          <CategoryForm
            id={editId}
            category={categories.find((cat) => cat.id === editId)}
            isEdit={mode === "UPDATE" ? true : false}
            onSubmit={handleSubmit}
            categories={categories}
          />
        )}
        {deleteId > -1 && (
          <>
            <h6>
              Are you sure you want to delete
              <span style={{ fontWeight: "bold", color: "red" }}>
                {" " + getCategoryName(deleteId).name}
              </span>
              ? This action cannot be undone.
            </h6>
            <div className="d-flex justify-content-end gap-2">
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirmDelete}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AdminCategories;
