import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { shopBannerItemComponent } from "../../constants/localization";
import { URL_PATH } from "../../../shared/constants/common";

const ShopBannerItem = ({ banner }) => {
  const { language } = useSelector((state) => state.setting);
  const { image, text_eng, text_arb, category } = banner;
  const { id, name_eng, name_arb } = category;
  return (
    <div className={`banner_one_large bdrs6 mt10 px-4 px-md-0`}>
      <div className="row resp-row">
        <div
          className={`col-lg-5 align-self-center ${
            language === "eng" ? "offset-lg-1" : "shop__banner__content__arb"
          } `}
        >
          <div
            className={`apple_widget_home1 mb-4 mb-lg-0 ${
              language === "eng" ? "" : "banner__title__arb"
            }`}
          >
            <h1 className="title">
              {language === "eng" ? text_eng : text_arb}
            </h1>
            <Link
              to={`/shop?categoryId=${id}`}
              state={{
                name_arb,
                name_eng,
              }}
              className="btn banner-btn btn-thm"
            >
              {shopBannerItemComponent.shopNow[language]}
            </Link>
          </div>
        </div>
        <div
          className={`col-lg-6 align-self-center 
             ${language === "eng" ? "" : "shop__banner__image__wrapper"}
          `}
        >
          <div className="apple_widget_home1 animate_content">
            <div
              style={{
                transform:
                  "perspective(500px) translateZ(0px) rotateX(-1.65866deg) rotateY(2.49306deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <img
                src={URL_PATH + image}
                alt="Banner Image"
                width="600"
                height="400"
                className="banner__image__mbl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopBannerItem;
