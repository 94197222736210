import axios from "axios";
import axiosInstance from "../../interceptor/axiosInstance";


export const getAllAdminInvoices = async () => {
  const response = await axiosInstance.get("/adminpanel/invoice/list/");
  return response.data.data || [];
};

export const getInvoiceById = async (id) => {
  const response = await axios.get(
    "http://localhost:3001/adminpanel/invocies/details"
  );
  return response.data;
};
