const VendorOrderHeader = () => {
  return (
    <thead className="table-light order-table-custom">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Name</th>
        <th scope="col">Date</th>
        <th scope="col">Paid Amount</th>
        <th scope="col">Status</th>
        <th scope="col">Invoice Amount</th>
        <th scope="col">Total W/Tax</th>
        <th scope="col">Total W/O Tax</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
  );
};

export default VendorOrderHeader;
