import { Link } from "react-router-dom";
import { confirmDelivery, confirmInvoice } from "../../../services/order";
import { useState } from "react";

const VendorTableRow = ({ orderItem }) => {
  const {
    id,
    name,
    order_date,
    paid_amount,
    order_state,
    invoice_amount,
    total_without_tax,
    total_with_tax,
  } = orderItem;
  const [invoiceAmount, setInvoiceAmount] = useState(invoice_amount);
  const [paidAmount, setPaidAmount] = useState(paid_amount);

  const [orderStatus, setOrderStatus] = useState(order_state);

  const handleDelivery = async () => {
    const response = await confirmDelivery(id);
    setOrderStatus("SALE");
    setInvoiceAmount(total_with_tax);
  };
  const handleInvoice = async () => {
    const response = await confirmInvoice(id);
    setOrderStatus("DONE");
    setPaidAmount(total_with_tax);
  };

  return (
    <>
      <tr>
        <th scope="row">#{id}</th>
        <td>{name}</td>
        <td>{order_date}</td>
        <td>{paidAmount}</td>
        <td className="status">
          <span>{orderStatus}</span>
        </td>
        <td>${invoiceAmount}</td>
        <td>${total_with_tax}</td>
        <td>${total_without_tax}</td>
        <td className="d-flex gap-2">
          <Link to={"/vendor/order/" + id + "/detail"}>
            <button className="btn btn-custom">
              <i className="fa-regular fa-eye"></i>
            </button>
          </Link>
          {/* {orderStatus === "DRAFT" && (
            <button onClick={handleDelivery} className="btn btn-custom">
              <i class="far fa-shipping-fast"></i>
            </button>
          )}

          {orderStatus === "SALE" && (
            <button onClick={handleInvoice} className="btn btn-custom">
              <i class="fa-light fa-file-invoice"></i>
            </button>
          )}
          {orderStatus === "DONE" && (
            <button className="btn btn-success">Done</button>
          )} */}
        </td>
      </tr>
    </>
  );
};

export default VendorTableRow;
