import { useEffect, useState } from "react";
import ProductForm from "../components/ProductForm";
import {
  getUOMs,
  getBrands,
  getTags,
  getPCAsByCategoryId,
  getPolicy,
} from "../services/common";
import Preloader from "../../shared/components/Preloader";
import { getCategoriesForProduct } from "../services/categories";

const CreateProduct = () => {
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [brands, setBrands] = useState([]);
  const [uoms, setUOMs] = useState([]);
  const [allPCAs, setAllPCAs] = useState([]);
  const [isFetchedPCA, setIsFetchedPCA] = useState(false);
  const [policyText, setPolicyText] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const categories = await getCategoriesForProduct();
      const policyData = await getPolicy();
      const tags = await getTags();
      const brands = await getBrands();
      const uoms = await getUOMs();
      setTags(tags);
      setUOMs(uoms);
      setBrands(brands);
      setCategories(categories);
      setPolicyText(policyData ? policyData.policy : "Policy Text");
      setLoading(false);
    })();
  }, []);
  if (loading) return <Preloader />;

  const getPCAs = async (id) => {
    const response = await getPCAsByCategoryId(id);
    setAllPCAs(response);
    setIsFetchedPCA(true);
  };

  const handleFetch = (value) => setIsFetchedPCA(value);
  return (
    <ProductForm
      brands={brands}
      tags={tags}
      uoms={uoms}
      categories={categories}
      isEdit={false}
      allPCAs={allPCAs}
      onChangeCategory={getPCAs}
      onFetchPCA={handleFetch}
      isFetchedPCA={isFetchedPCA}
      policyText={policyText}
    />
  );
};

export default CreateProduct;
