import TablePagination from "../../TablePagination";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceTableBody from "./InvoiceTableBody";
import usePagination from "../../../hooks/usePagination";

const VendorInvoiceTable = ({ filter, invoices }) => {
  const { status, search } = filter;
  let filteredInvocies = invoices;
  if (status && status !== "ALL") {
    filteredInvocies = filteredInvocies.filter(
      (order) => order.order_state === status
    );
  }

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredInvocies = filteredInvocies.filter((order) =>
      order.tracking_reference.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredInvocies, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <InvoiceHeader />
          <InvoiceTableBody invoices={currentData()} />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default VendorInvoiceTable;
