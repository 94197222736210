import CustomerDetail from "../CustomerDetail";
import DeliveryItem from "../Deliveries/DeliveryDetail/DeliveryItem";

const DeliveryDetailItem = ({ delivery }) => {
  const { customer_id, delivery_lines } = delivery;

  return (
    <>
      <div className="order_detail_body p-3">
        <div className="main-title text-center">
          <h2>Delivery Detail</h2>
        </div>
        <CustomerDetail customer={customer_id} />

        <div className="application_statics mt-3">
          <div className="account_user_deails dashboard_page">
            <div className="order_table table-responsive">
              <table className="table">
                <thead className="table-light">
                  <tr className="text-center">
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">Product Qnt</th>
                  </tr>
                </thead>
                <tbody>
                  {delivery_lines.map((deliveryItem) => (
                    <DeliveryItem
                      key={deliveryItem.id}
                      deliveryItem={deliveryItem}
                    />
                  ))}
                </tbody>
              </table>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryDetailItem;
