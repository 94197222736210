import TablePagination from "../../../../shared/components/TablePagination";
import usePagination from "../../../../hooks/usePagination";
import SaleOrderHeader from "./SaleOrderHeader";
import SaleOrderTableBody from "./SaleOrderTableBody";

const SaleOrderTable = ({ filter, saleOrders, onView }) => {
  const { status, search } = filter;
  let filteredSaleOrders = saleOrders;
  if (status && status !== "ALL") {
    filteredSaleOrders = filteredSaleOrders.filter(
      (delivery) => delivery.order_state === status
    );
  }

  if (search) {
    const normalizedSearchText = search.toLowerCase();
    filteredSaleOrders = filteredSaleOrders.filter((delivery) =>
      delivery.name.toLowerCase().includes(normalizedSearchText)
    );
  }

  const { currentData, currentPage, totalPages, next, prev, jump } =
    usePagination(filteredSaleOrders, 10);

  return (
    <>
      <div className="order_table table-responsive">
        <table className="table">
          <SaleOrderHeader />
          <SaleOrderTableBody onView={onView} saleOrders={currentData()} />
        </table>
      </div>
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={jump}
        next={next}
        prev={prev}
      />
    </>
  );
};

export default SaleOrderTable;
