import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BannerCarousel from "../components/Shop/ShopBannerCarousel";
import ProductListingWithSideBar from "../components/Shop/ProductListingWithSidebar";
import ShopCategory from "../components/Shop/shopCategory";

import { getProductsByFilters } from "../services/product";
import { useSelector } from "react-redux";
import ShopByCategory from "../components/Shop/shopByCategoryListing";
import { getCategorySubCat } from "../services/categories";
import Preloader from "../../shared/components/Preloader";
import VendorListing from "../components/VendorListing/VendorListing";
import Breadcrumb from "../components/Layout/BreadCrumb";

const pricingValue = {
  all: 0,
  less_25: 25,
  "between25_50": [25, 50],
  "between50_100": [50, 100],
  over100: 100,
};

const pricingKey = {
  all: "gte",
  less_25: "lte",
  "between25_50": "range",
  "between50_100": "range",
  over100: "gte",
};

const initialLimit = 10;
const defaultFilters = {
  product_type: null,
  delivery_fee: null,
  discount: null,
  customRange: {
    min: 0,
    max: 100000,
    isConfirmed: false,
  },
  priceRange: null,
};
const Shop = () => {
  const {
    categories,
    allCategories,
    categoriesKeyBased,
    loading: loader,
  } = useSelector((state) => state.category);

  const { language } = useSelector((state) => state.setting);

  const { banners, vendors } = useSelector((state) => state.common);
  const [subCategory, setSubCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(initialLimit);
  const [count, setCount] = useState(0);
  const [category, setCategory] = useState(null);

  const [filters, setFilters] = useState(defaultFilters);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("categoryId") || "";

  const vendorId = queryParams.get("vendorId") || "";

  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (vendorId || id) {
      setIsFiltered(true);
      setFilters(defaultFilters);
      setCurrentPage(1);
    } else if (location.pathname === "/shop" && !vendorId && !id) {
      setCurrentPage(1);
    }
  }, [vendorId, id, location.pathname]);

  useEffect(() => {
    if (!loader) {
      if (id) {
        const category = allCategories.find((item) => item.id === +id);
        setCategory(category);
        getSubCategoryData(id);
      }
      getFilteredProducts();

      setLoading(false);
    }
  }, [id, vendorId, loader, currentPage, filters]);

  const handleFiltered = (name, value) => {
    setIsFiltered(true);
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const getFilteredProducts = async () => {
    const appliedFilters = {
      parent_product: null,
    };
    if (id) {
      const allCategories = categoriesKeyBased[id] || [];
      const nestedCategories = [
        +id,
        ...allCategories.flatMap(
          (cat) => [cat, ...categoriesKeyBased[cat]] || []
        ),
      ];

      const flatArray = nestedCategories.reduce((acc, val) => {
        return acc.concat(Array.isArray(val) ? val : [val]);
      }, []);
      appliedFilters.category__id__in = flatArray;
    }
    if (vendorId) {
      appliedFilters.vendor__id = +vendorId;
    }
    if (filters.delivery_fee === 0) {
      appliedFilters.delivery_fee__exact = filters.delivery_fee;
    }
    if (filters.discount) {
      appliedFilters.discount__gte = filters.discount;
    }
    if (filters.product_type) {
      appliedFilters.selection = filters.product_type;
    }
    if (
      filters.customRange.isConfirmed &&
      filters.customRange.min > -1 &&
      filters.customRange.max > 0
    ) {
      appliedFilters.sale_price__range = [
        filters.customRange.min,
        filters.customRange.max,
      ];
    }
    if (filters.priceRange) {
      const value = pricingValue[filters.priceRange];
      const key = pricingKey[filters.priceRange];
      appliedFilters["sale_price__" + key] = value;
    }
    const response = await getProductsByFilters(
      appliedFilters,
      (currentPage - 1) * limit,
      limit
    );
    setProducts(response.data);
    setCount(response.count);
    setIsFiltered(false);
  };

  const handleChangeLimit = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
  };

  const getSubCategoryData = async () => {
    const response = await getCategorySubCat(id);
    setSubCategory(response);
  };

  const changePage = (page) => {
    if (page > 0 && page <= count) {
      setCurrentPage(page);
    }
  };

  const handleRemoveFilter = (key) => {
    if (key === "customRange") {
      setFilters((prevState) => ({
        ...prevState,
        customRange: {
          min: 0,
          max: 100000,
          isConfirmed: false,
        },
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        [key]: null,
      }));
    }
    setIsFiltered(true);
  };

  if (loading || loader) return <Preloader />;
  return (
    <>
      <ShopCategory />
      <section className="our-listing pt0">
        <div className="container">
          {/* <Breadcrumb /> */}
          {banners.length > 0 && <BannerCarousel banners={banners} />}
          {id && category && subCategory.length > 0 && (
            <ShopByCategory
              categories={subCategory}
              name={
                language === "eng"
                  ? "Shop by " + category.name_eng
                  : " تسوق حسب" + category.name_arb
              }
            />
          )}

          {!id && categories.length > 0 && (
            <ShopByCategory
              categories={categories}
              name={language === "eng" ? "All Categories" : "جميع الفئات"}
            />
          )}
          {!id && vendors.length > 0 && <VendorListing vendors={vendors} />}

          <ProductListingWithSideBar
            currentPage={currentPage}
            totalItems={count}
            onChangeLimit={handleChangeLimit}
            onChangePage={changePage}
            products={products}
            limit={limit}
            totalPages={Math.ceil(count / limit)}
            onFilter={handleFiltered}
            customRange={filters.customRange}
            filters={filters}
            onRemoveFilter={handleRemoveFilter}
            isFiltered={isFiltered}
          />
        </div>
      </section>
    </>
  );
};

export default Shop;
