const InvoiceItem = ({ invoiceItem }) => {
  const {
    product_id,
    quantity,
    unit_price,
    subtotal,
    tax,
    discount,
    is_active,
  } = invoiceItem;
  return (
    <tr>
      <td className="body-color">{product_id}</td>
      <td className="body-color">{is_active ? "Active" : "InActive"}</td>
      <td className="body-color">{unit_price}</td>
      <td className="body-color">{quantity}</td>
      <td className="body-color">{tax}</td>
      <td className="body-color">{discount}</td>
      <td className="body-color">{subtotal}</td>
    </tr>
  );
};

export default InvoiceItem;
