const AdminDashboardCard = ({
  title,
  count,
  imgUrl = null,
  subtitle,
  icon,
}) => {
  return (
    <div className="col-lg-6 col-xxl-3">
      <div className="d-flex justify-content-between statistics_funfact">
        <div className="details">
          <div className="subtitle1">{title}</div>
          <div className="title">
            {count}
            {imgUrl && (
              <span className="badge style1 text-center">
                <img className="pr5" src={imgUrl} alt="chart-up" />
                2.2%
              </span>
            )}
          </div>
          {subtitle && <div className="subtitle2">{subtitle}</div>}
        </div>
        <div className="icon text-center mt-4">
          <i className={icon}></i>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardCard;
