import { useState } from "react";
import Modal from "../../../shared/components/Modal";
import { handleUploadFile } from "../../services/dashboard";
import { toast } from "react-toastify";

const DashboardTop = () => {
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState(null);
  const handleClose = () => {
    setFile(null);
    setOpenModal(false);
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    const response = await handleUploadFile(formData);

    toast.success("Products Uploaded Succesfully");
  };
  return (
    <div className="row pb50">
      <div className="col-lg-12">
        <div className="d-flex justify-content-between">
          <div className="dashboard_title_area ">
            <h2>Dashboard</h2>
          </div>
          <div>
            <button
              type="button"
              onClick={() => setOpenModal(true)}
              className="btn btn-log btn-thm"
            >
              Upload Product File
            </button>
          </div>
        </div>
      </div>
      <Modal show={openModal} onClose={handleClose}>
        <form
          className="d-flex flex-column justify-content-between"
          onSubmit={handleSubmit}
        >
          <div className="custom-field">
            <h5 className="short_code_title">Select File</h5>
            <div className="form-group">
              <input
                onChange={handleFile}
                type="file"
                className="form-control"
                placeholder="Upload Image"
              />
            </div>
          </div>

          <button type="submit" className="btn btn-log btn-thm mt20">
            Upload
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default DashboardTop;
