import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import HiddenShoppingCartItem from "../ShoppingCart/HiddenShoppingCartItem";
import { hiddenCartSidebarComponent } from "../../constants/localization";
import { decimalNumberUpto2 } from "../../utils/decimalNumberUpto2";

const HiddenCartSideBar = () => {
  const { cartProducts } = useSelector((state) => state.cart);
  const { language } = useSelector((state) => state.setting);
  const navigate = useNavigate();
  const handleHideCart = () =>
    document.body.classList.remove("cart-hidden-sidebar-content");

  const handleNavigate = (to) => {
    handleHideCart();
    navigate(to);
  };

  const getTotal = () =>
    cartProducts.reduce((total, item) => {
      const discountAmount = item.sale_price * (item.discount / 100);
      const itemTotal = item.quantity * (item.sale_price - discountAmount);
      return total + itemTotal;
    }, 0);

  return (
    <div className="cart-hidden-sbar">
      <div className="hsidebar-header">
        <div
          onClick={handleHideCart}
          className={`sidebar-close-icon ${
            language === "eng"
              ? "sidebar-close-icon__eng"
              : "sidebar-close-icon__arb"
          }`}
        >
          <span className="flaticon-close" />
        </div>
        <h4 className="title">
          {hiddenCartSidebarComponent.yourCart[language]}
        </h4>
      </div>
      <div className="hsidebar-content">
        <div className="log_fav_cart_widget hsidebar_home_page">
          <div className="wrapper">
            <ul className="cart">
              <li className="list-inline-item">
                <ul className="dropdown_content">
                  {cartProducts.length === 0 && (
                    <h5
                      className="title"
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {hiddenCartSidebarComponent.cartEmpty[language]}
                    </h5>
                  )}
                  {cartProducts.length > 0 &&
                    cartProducts.map((cartItem) => (
                      <HiddenShoppingCartItem cartItem={cartItem} />
                    ))}
                  <li className="list_content_total_price">
                    <h5
                      className={`title ${
                        language === "eng" ? "" : "text__left__arb"
                      }`}
                    >
                      {hiddenCartSidebarComponent.total[language]}

                      <span className="total_price float-end">
                        {cartProducts.length > 0
                          ? decimalNumberUpto2(getTotal())
                          : 0}
                      </span>
                    </h5>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="hsidebar_footer_content">
        <div className="list_last_content">
          <div className="lc">
            <p className="para">
              {hiddenCartSidebarComponent.buyMore[language]}
            </p>

            <div
              onClick={() => handleNavigate("/shopping-cart")}
              className="cart_btns btn btn-white"
            >
              {hiddenCartSidebarComponent.viewCart[language]}
            </div>
            <div
              onClick={() => handleNavigate("/checkout")}
              className="checkout_btns btn btn-thm"
            >
              {hiddenCartSidebarComponent.checkout[language]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HiddenCartSideBar;
