const VendorTableHeader = () => {
  return (
    <thead className="table-light order-table-custom">
      <tr>
        <th scope="col">#ID</th>
        <th scope="col">Name</th>
        <th scope="col">Company</th>
        <th scope="col">Shop Name</th>
        <th scope="col">Shop Image</th>
        <th scope="col">Brand Name</th>
        <th scope="col">Bank Name</th>
        <th scope="col">IBAN</th>
        <th scope="col">Account Number</th>
        <th scope="col">Tax ID</th>
        <th scope="col">Phone</th>
        <th scope="col">User Name</th>
        <th scope="col">Active Status</th>
        <th scope="col">Email</th>
        <th scope="col">Address</th>
        <th scope="col">Country</th>
        <th scope="col">Street</th>
        <th scope="col">Zip Code</th>
        <th scope="col">City</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
  );
};

export default VendorTableHeader;
