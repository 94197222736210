import { useEffect, useState } from "react";
import { createUom, updateUom } from "../services/uom";

const UOMForm = ({ onSubmit, isEdit = false, id, uom = null }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    if (uom && isEdit) {
      const { name } = uom;
      setName(name);
    }
  }, [id, uom, isEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEdit && id) {
      const response = await updateUom(id, { name });
      onSubmit(response);
    } else {
      const response = await createUom({ name });
      onSubmit(response);
    }
  };

  return (
    <div className="login_form">
      <form onSubmit={handleSubmit}>
        <div className="mb-2 mr-sm-2">
          <label className="form-label">UOM Name</label>
          <input
            value={name}
            name="name"
            type="text"
            className="form-control"
            placeholder="UOM Name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-log btn-thm mt20">
          {isEdit ? "Update" : "Create"}
        </button>
      </form>
    </div>
  );
};

export default UOMForm;
