import { configureStore } from "@reduxjs/toolkit";
import categorySlice, { getAllCategories } from "./categorySlice";
import cartSlice from "./cart/cartSlice";
import settingSlice, { getSelectedLanugage } from "./setting/settingSlice";
import commonSlice, { getCommonData } from "./common/commonSlice";
import userAuthSlice, { getLoginUser } from "./userAuthSlice/userAuthSlice";
export const store = configureStore({
  reducer: {
    category: categorySlice,
    cart: cartSlice,
    setting: settingSlice,
    common: commonSlice,
    userAuth: userAuthSlice,
  },
});
store.dispatch(getAllCategories());
store.dispatch(getCommonData());
store.dispatch(getLoginUser());
store.dispatch(getSelectedLanugage());
