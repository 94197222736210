import { useEffect, useState } from "react";
import { getMyOrders } from "../services/myOrders";
import Preloader from "../../shared/components/Preloader";
import Modal from "../../shared/components/Modal";
import ReviewForm from "../components/Reviews/reviewForm";
import MyOrderItem from "../components/MyOrders/MyOrderItem";
import { useSelector } from "react-redux";
import CustomerPortalLayout from "../components/CustomerPortal/CustomerPortalLayout";
import { myOrdersPage } from "../constants/localization";

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderlineIndex, setOrderLineIndex] = useState(null);
  const [productId, setProductId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { loading: loader } = useSelector((state) => state.category);
  const { language } = useSelector((state) => state.setting);

  useEffect(() => {
    if (!loader)
      (async () => {
        const response = await getMyOrders();
        setOrders(response);
        setLoading(false);
      })();
  }, [loader]);

  if (loading) return <Preloader />;

  const handleOrderLine = (index, productId) => {
    setOrderLineIndex(index);
    setProductId(productId);
    setShowModal(true);
  };

  const handleClose = () => {
    setOrderLineIndex(null);
    setShowModal(false);
  };

  const handleReview = (data) => {
    handleClose();
  };
  return (
    <CustomerPortalLayout>
      <div className="container mt-4">
        <div className="column">
          {orders.length > 0 ? (
            orders.map((order, index) => (
              <MyOrderItem
                key={index}
                onReview={handleOrderLine}
                order={order}
              />
            ))
          ) : (
            <div className="col-12 text-center">
              <p>{myOrdersPage.noOrderFound[language]}</p>
            </div>
          )}
        </div>

        <Modal show={showModal} onClose={handleClose}>
          <ReviewForm
            order_line_id={orderlineIndex}
            product_id={productId}
            onReview={handleReview}
          />
        </Modal>
      </div>
    </CustomerPortalLayout>
  );
};

export default MyOrders;
