import axiosInstance from "../../interceptor/axiosInstance";

export const getVendorOrders = async () => {
  const response = await axiosInstance.get("/all/order/");
  return response.data.data || [];
};

export const getVendorAllOrders = async () => {
  const response = await axiosInstance.get("/vendor/orders");
  return response.data.data || [];
};

export const getOrderById = async (id) => {
  const response = await axiosInstance.get(
    "/vendor/order/details?order_id=" + id
  );
  return response.data.data[0] || [];
};

export const confirmDelivery = async (id) => {
  const response = await axiosInstance.get("/confirm_delivery/?order_id=" + id);
  return response.data.data[0] || [];
};

export const confirmInvoice = async (id) => {
  const response = await axiosInstance.get("/confirm_invoice/?order_id=" + id);
  return response.data.data[0] || [];
};

export const createDelivery = async (data) => {
  const response = await axiosInstance.post("/delivery/create/", { ...data });
  return response.data.data;
};

export const createInvoice = async (data) => {
  const response = await axiosInstance.post("/invoice/create/", { ...data });
  return response.data;
};

export const getVendorInvoices = async () => {
  const response = await axiosInstance.get("/adminpanel/invoice/list/");
  return response.data.data || [];
};

export const getDeliveries = async (isRefund) => {
  const response = await axiosInstance.get(
    "/adminpanel/delivery/list/?is_return=" + isRefund
  );
  return response.data.data || [];
};

// send Attachements
export const sendDigitalAttachments = async (id) => {
  const response = await axiosInstance.get(
    "/send/digital_attachments/?order_id=" + id
  );
  return response.data;
};

export const getVendorInvoiceBySaleId = async (saleOrderId) => {
  const response = await axiosInstance.get(
    "/adminpanel/invoice/ord_id/?ord_id=" + saleOrderId
  );
  return response.data.data[0];
};

export const getVendorDeliveriesBySaleOrderId = async (saleOrderId) => {
  const response = await axiosInstance.get(
    "/adminpanel/delivery/ord_id/?ord_id=" + saleOrderId
  );
  return response.data.data || [];
};
