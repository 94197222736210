import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { productCardComponent } from "../../constants/localization";
import { URL_PATH } from "../../../shared/constants/common";

const ProductCard = ({ product }) => {
  const {
    id,
    name_eng,
    name_arb,
    brand,
    sale_price,
    reviewsCount = 30,
    main_image,
    uom,
    vendor,
  } = product;

  const { language } = useSelector((state) => state.setting);
  return (
    <div className="owl-item active" style={{ width: "312px", margin: "0px" }}>
      <div className="item ovh">
        <div
          className="shop_item bdrtrb1 px-2 px-sm-3 wow fadeIn"
          style={{
            visibility: "visible",
            animationDuration: "1.1s",
            animationName: "fadeIn",
          }}
          data-wow-duration="1.1s"
        >
          <div className="thumb pb30">
            <img
              style={{
                aspectRatio: 4 / 3,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={main_image ? URL_PATH + main_image : "/images/no-image.png"}
              alt={language === "eng" ? name_eng : name_arb}
            />

            <div className="shop_item_cart_btn d-grid">
              <Link className="btn btn-thm" to={"/product-detail/" + id}>
                {productCardComponent.addTOCart[language]}
              </Link>
            </div>
          </div>
          <div className="details">
            <div className="sub_title">{brand.name}</div>
            <div className="title">
              <Link to={"/product-detail/" + id}>
                {language === "eng" ? name_eng : name_arb}
              </Link>
            </div>
            <div className="review d-flex db-500">
              <ul className="mb0 me-2">
                <li className="list-inline-item">
                  <a>
                    <i className="fas fa-star"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a>
                    <i className="fas fa-star"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a>
                    <i className="fas fa-star"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a>
                    <i className="fas fa-star"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a>
                    <i className="fas fa-star"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="si_footer">
              <div className="sale_price">${sale_price}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
