import axiosInstance from "../../interceptor/axiosInstance";

export const getVendorCategories = async () => {
  const response = await axiosInstance.get("/all/category");
  return response.data.data || [];
};

export const getBrands = async () => {
  const response = await axiosInstance.get("/products/brand/");
  return response.data.data || [];
};

export const getUOMs = async () => {
  const response = await axiosInstance.get("/products/uom/");
  return response.data.data || [];
};

export const getTags = async () => {
  const response = await axiosInstance.get("/products/tags");
  return response.data.data || [];
};

export const getPCAsByCategoryId = async (id) => {
  const response = await axiosInstance.get(
    "/variant/category_attribute/?cat_id=" + id
  );
  return response.data.data || [];
};

export const getPolicy = async () => {
  const response = await axiosInstance.get("/products/policy/");
  return response.data.data[0];
};

export const getJournals = async () => {
  const response = await axiosInstance.get("/invoices/journal/");
  return response.data.data || [];
};
