import axiosInstance from "../../interceptor/axiosInstance";

export const getAllAdminDeliveries = async () => {
  const response = await axiosInstance.get("/adminpanel/delivery/list/");
  return response.data.data || [];
};

export const getDeliveryById = async (id) => {
  const response = await axiosInstance.get(
    "/adminpanel/delivery/details/?id=" + id
  );
  return response.data;
};
