import { useSelector } from "react-redux";
import { productListingTopComponent } from "../../constants/localization";

const ProductListingTop = ({ title, hideTopRight }) => {
  const { language } = useSelector(state=>state.setting);
  return (
    <div className="row">
      <div className="col-md-5">
        <div className="main-title mb0-sm">
          <h2>{title}</h2>
        </div>
      </div>
      {!hideTopRight && (
        <div className="col-md-7">
          <div className="popular_listing_sliders ui_kit_tab style2">
            <div
              className="nav nav-tabs mb30 justify-content-start justify-content-md-end"
              role="tablist"
            >
              <button
                className="nav-link active"
                id="nav-narive-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-narive"
                role="tab"
                aria-controls="nav-narive"
                aria-selected="true"
              >
                {productListingTopComponent.newArrivals[language]}
              </button>
              <button
                className="nav-link"
                id="nav-bseller-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-bseller"
                role="tab"
                aria-controls="nav-bseller"
                aria-selected="false"
              >
                {productListingTopComponent.bestSellers[language]}
              </button>
              <button
                className="nav-link"
                id="nav-brated-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-brated"
                role="tab"
                aria-controls="nav-brated"
                aria-selected="false"
              >
                {productListingTopComponent.bestRated[language]}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductListingTop;
