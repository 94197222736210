import axiosInstance from "../../interceptor/axiosInstance";

const getProducts = async (offset, limit) => {
  try {
    const url = `/all/products/list?${limit ? `&limit=${limit}` : ""}${
      offset ? `&offset=${offset}` : ""
    }`;
    const response = await axiosInstance.get(url);
    return { results: response.data.results, count: response.data.count };
  } catch (error) {
    console.log(error.message);
  }
};

const getProductById = async (id) => {
  try {
    const response = await axiosInstance.get("/products/details/?id=" + id);
    return response.data.data[0];
  } catch (error) {
    console.log(error);
  }
};

const getProductsByCategory = async (id, offset, limit) => {
  try {
    const url = `/all/products/list?cat_id=${id}${
      limit ? `&limit=${limit}` : ""
    }${offset ? `&offset=${offset}` : ""}`;

    const response = await axiosInstance.get(url);
    const data = response.data.results || [];
    return { data, count: response.data.count };
  } catch (error) {
    console.log(error.message);
  }
};

const getBrandsList = async () => {
  try {
    const response = await axiosInstance.get("/products/brand/");
    const data = response.data.data || [];
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getProductsByFilters = async (filters, offset, limit) => {
  const url = `/products/filter/?${limit ? `&limit=${limit}` : ""}${
    offset ? `&offset=${offset}` : ""
  }`;
  const response = await axiosInstance.post(url, { filters });
  return { data: response.data.results, count: response.data.count };
};

export {
  getProducts,
  getProductById,
  getProductsByCategory,
  getBrandsList,
  getProductsByFilters,
};
