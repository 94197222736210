const AdminDashboardTop = () => {
  return (
    <div className="row pb50">
      <div className="col-lg-12">
        <div className="dashboard_title_area">
          <h2>Dashboard</h2>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardTop;
