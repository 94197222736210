import { Link } from "react-router-dom";

const Error = ({ error }) => {
  return (
    <section className="custom_page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="custom_four_zero_four_bg"></div>
              <div className="custom_contant_box_404">
                <h3 className="h2">{error}</h3>
                <div style={{marginTop:"50px"}}>
                  <Link to="/" className="custom_link_404">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
