import { useEffect, useState } from "react";
import VendorProductTable from "../components/VendorProducts/Table/VendorProductTable";
import {
  getVendorProducts,
  deleteProductOrVariant,
} from "../services/vendorProducts";
import { useNavigate } from "react-router-dom";
import { getCategoriesForProduct } from "../services/categories";
import Preloader from "../../shared/components/Preloader";
import ProductFilter from "../components/VendorProducts/ProductFilter";
import Modal from "../../shared/components/Modal";
const intialSelectedProduct = {
  id: "",
  index: "",
};

const VendorProducts = () => {
  const [products, setProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(intialSelectedProduct);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    selectedCategory: "",
    status: "",
    product: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const responseProduct = await getVendorProducts();
      const responseCategory = await getCategoriesForProduct();
      setProducts(responseProduct);
      setCategories(responseCategory);
      setLoading(false);
    })();
  }, []);

  const handleModal = (id, index) => {
    setModalOpen(!modalOpen);
    setSelectedProduct({
      id,
      index,
    });
  };

  const confirmDelete = async () => {
    // false send if its variant
    const { flag } = await deleteProductOrVariant(selectedProduct.id, false);

    if (flag) {
      setProducts((prevState) =>
        prevState.filter((item) => item.id !== selectedProduct.id)
      );
    }
    setModalOpen(false);
    setSelectedProduct({
      id: "",
      index: "",
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProduct({
      id: "",
      index: "",
    });
  };

  const handleEdit = (id) => {
    navigate(`/vendor/products/${id}/edit`);
  };

  const handleFilterChange = (filterName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  if (loading) return <Preloader />;

  return (
    <>
      <div className="dashboard__content bgc-gmart-gray">
        <div className="row pb50">
          <div className="col-lg-12">
            <div className="dashboard_title_area">
              <h2>Products</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="dashboard_product_list account_user_deails">
              <ProductFilter
                categories={categories}
                filter={filter}
                onChangeFilter={handleFilterChange}
              />
              <VendorProductTable
                onEdit={handleEdit}
                onDelete={handleModal}
                products={products}
                filter={filter}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={modalOpen} onClose={closeModal} title="Delete Confirmation">
        <h6>
          Are you sure you want to delete this product? This action cannot be
          undone.
        </h6>
        <div className="d-flex justify-content-end gap-2">
          <button
            type="button"
            className="btn btn-danger"
            onClick={confirmDelete}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default VendorProducts;
