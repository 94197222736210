import { useEffect, useState } from "react";
import InvoiceSaleOrderItem from "../Invoices/InvoiceDetail/InvoiceSaleOrderItem";
import Preloader from "../../../shared/components/Preloader";
import { getVendorInvoiceBySaleId } from "../../services/order";
import CustomerDetail from "../CustomerDetail";

const InvoiceView = ({ invoiceId, onBack }) => {
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await getVendorInvoiceBySaleId(invoiceId);
      setInvoice(response);
      setLoading(false);
    })();
  }, [invoiceId]);

  if (loading || !invoice) return <Preloader />;
  const {
    customer,
    order_state,
    delivery_address,
    invoice_date,
    tracking_reference,
  } = invoice;

  return (
    <div className="px-4">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h4>Invoice Date : {invoice_date}</h4>
          <h4>Tracking Reference : {tracking_reference}</h4>
        </div>
        <button className="btn btn-log btn-thm mt20" onClick={onBack}>
          Back
        </button>
      </div>
      <CustomerDetail customer={customer} />

      <div className="application_statics">
        <div className="account_user_deails dashboard_page">
          <div className="order_table table-responsive">
            <table className="table">
              <thead className="table-light">
                <tr className="text-center">
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Tax</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Sub Total</th>
                </tr>
              </thead>
              <tbody>
                {invoice.invoice_lines.map((saleOrderItem) => (
                  <InvoiceSaleOrderItem
                    key={saleOrderItem.id}
                    saleOrderItem={saleOrderItem}
                  />
                ))}
              </tbody>
            </table>
            <hr />
            <div className="fix-order-table">
              <table className="table sub-table d-flex justify-content-end">
                <tbody>
                  <tr>
                    <td className="sub-table-label">Sub Total:</td>
                    <td className="sub-table-value">
                      ${invoice.total_with_tax}
                    </td>
                  </tr>
                  <tr>
                    <td className="sub-table-label">Discount (VELZON15):</td>
                    <td className="sub-table-value">-$0</td>
                  </tr>
                  <tr>
                    <td className="sub-table-label">Shipping Charge:</td>
                    <td className="sub-table-value">$0</td>
                  </tr>
                  <tr>
                    <td className="sub-table-label">Estimated Tax:</td>
                    <td className="sub-table-value">$0</td>
                  </tr>
                  <tr>
                    <td className="sub-table-label total">Total (USD):</td>
                    <td className="sub-table-value total">
                      ${invoice.total_with_tax}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceView;
