import React from "react";
import { useSelector } from "react-redux";

import CategoryListing from "../components/CategoryListing/CategoryListing";
import Feature from "../components/home/Features";
import ProductListingContainer from "../components/home/ProductListingContainer";
import ShopBannerCarousel from "../components/Shop/ShopBannerCarousel";
import Preloader from "../../shared/components/Preloader";
import VendorListing from "../components/VendorListing/VendorListing";
import { homePage, homeProductListingTop } from "../constants/localization";
const Home = () => {
  const { homeProducts, loading } = useSelector((state) => state.category);
  const { banners, vendors, commonLoading } = useSelector(
    (state) => state.common
  );

  const { language } = useSelector((state) => state.setting);
  if (loading || commonLoading) return <Preloader />;
  return (
    <div>
      {/* Banner Slider Carousel */}
      {banners.length > 0 && <ShopBannerCarousel banners={banners} />}
      {/* Feature */}
      <Feature />
      {/* Top Category */}
      <CategoryListing />

      {/* Vendor Listing */}

      <VendorListing vendors={vendors} />
      {/* Best Seller Product  */}
      <section className="featured-product pt0 pb90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="main-title mb0-sm">
                <h2>{homePage.bestSeller[language]}</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="popular_listing_sliders ui_kit_tab style2">
                <div
                  className="nav nav-tabs mb30 justify-content-start justify-content-lg-end"
                  role="tablist"
                >
                  <button
                    className="nav-link active"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    {homeProductListingTop.top20[language]}
                  </button>
                  <button
                    className="nav-link"
                    id="nav-shopping-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-shopping"
                    role="tab"
                    aria-controls="nav-shopping"
                    aria-selected="false"
                  >
                    {homeProductListingTop.baby[language]}
                  </button>
                  <button
                    className="nav-link"
                    id="nav-destination-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-destination"
                    role="tab"
                    aria-controls="nav-destination"
                    aria-selected="false"
                  >
                    {homeProductListingTop.electronic[language]}
                  </button>
                  <button
                    className="nav-link me-0"
                    id="nav-bread-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-bread"
                    role="tab"
                    aria-controls="nav-bread"
                    aria-selected="false"
                  >
                    {homeProductListingTop.all[language]}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="popular_listing_sliders row ui_kit_tab style2">
                {/* Tab panes */}
                <div className="tab-content col-lg-12" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="best_item_slider_shop_lising_page shop_item_5grid_slider slider_dib_sm nav_none_400 dots_none owl-theme owl-carousel">
                      <div className="item ovh">
                        <div
                          className="shop_item bdrtrb1 px-2 px-sm-3 wow fadeIn"
                          data-wow-duration="1.1s"
                        >
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm1.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">SAMSUNG</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Great Value Ultra Strong Paper Towels, Split
                                Sheets, 6 Double
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item ovh">
                        <div
                          className="shop_item bdrtrb1 px-2 px-sm-3 wow fadeIn"
                          data-wow-duration="1.3s"
                        >
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm2.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">SONY</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Dell Inspiron 3000 15.6" Touchscreen Laptop -
                                Black (Intel Core i5-1035G1/256GB SSD/8GB
                                RAM/Windows 11 S)
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item ovh">
                        <div
                          className="shop_item bdrtrb1 px-2 px-sm-3 wow fadeIn"
                          data-wow-duration="1.5s"
                        >
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm3.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">Eastsport</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                LG Gram 17" Laptop -Obsidian Black (Intel Evo
                                Core i7-1165G7/1TB SSD/16GB RAM) -En -Only at
                                Best Buy
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $399.00
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item ovh">
                        <div
                          className="shop_item bdrtrb1 px-2 px-sm-3 wow fadeIn"
                          data-wow-duration="1.7s"
                        >
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm4.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">rolex</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                HP 15.6" Touchscreen Laptop - Natural Silver
                                (AMD Ryzen 5 5625U/1TB SSD/12GB RAM/Windows 11)
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item ovh">
                        <div
                          className="shop_item bdrtrb1 px-2 px-sm-3 wow fadeIn"
                          data-wow-duration="1.9s"
                        >
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm5.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">rolex</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Marketside Large Cage Free Brown Eggs, 36 Oz, 18
                                Ct
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $18.124
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-shopping"
                    role="tabpanel"
                    aria-labelledby="nav-shopping-tab"
                  >
                    <div className="best_item_slider_shop_lising_page shop_item_5grid_slider slider_dib_sm nav_none_400 dots_none owl-theme owl-carousel">
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm1.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">SAMSUNG</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Great Value Ultra Strong Paper Towels, Split
                                Sheets, 6 Double
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm2.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">SONY</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Dell Inspiron 3000 15.6" Touchscreen Laptop -
                                Black (Intel Core i5-1035G1/256GB SSD/8GB
                                RAM/Windows 11 S)
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm3.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">Eastsport</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                LG Gram 17" Laptop -Obsidian Black (Intel Evo
                                Core i7-1165G7/1TB SSD/16GB RAM) -En -Only at
                                Best Buy
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $399.00
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm4.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">rolex</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                HP 15.6" Touchscreen Laptop - Natural Silver
                                (AMD Ryzen 5 5625U/1TB SSD/12GB RAM/Windows 11)
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm5.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">rolex</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Marketside Large Cage Free Brown Eggs, 36 Oz, 18
                                Ct
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $18.124
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-destination"
                    role="tabpanel"
                    aria-labelledby="nav-destination-tab"
                  >
                    <div className="best_item_slider_shop_lising_page shop_item_5grid_slider slider_dib_sm nav_none_400 dots_none owl-theme owl-carousel">
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm1.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">SAMSUNG</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Great Value Ultra Strong Paper Towels, Split
                                Sheets, 6 Double
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm2.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">SONY</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Dell Inspiron 3000 15.6" Touchscreen Laptop -
                                Black (Intel Core i5-1035G1/256GB SSD/8GB
                                RAM/Windows 11 S)
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm3.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">Eastsport</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                LG Gram 17" Laptop -Obsidian Black (Intel Evo
                                Core i7-1165G7/1TB SSD/16GB RAM) -En -Only at
                                Best Buy
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $399.00
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm4.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">rolex</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                HP 15.6" Touchscreen Laptop - Natural Silver
                                (AMD Ryzen 5 5625U/1TB SSD/12GB RAM/Windows 11)
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm5.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">rolex</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Marketside Large Cage Free Brown Eggs, 36 Oz, 18
                                Ct
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $18.124
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-bread"
                    role="tabpanel"
                    aria-labelledby="nav-bread-tab"
                  >
                    <div className="best_item_slider_shop_lising_page shop_item_5grid_slider slider_dib_sm nav_none_400 dots_none owl-theme owl-carousel">
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm1.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">SAMSUNG</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Great Value Ultra Strong Paper Towels, Split
                                Sheets, 6 Double
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm2.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">SONY</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Dell Inspiron 3000 15.6" Touchscreen Laptop -
                                Black (Intel Core i5-1035G1/256GB SSD/8GB
                                RAM/Windows 11 S)
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm3.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">Eastsport</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                LG Gram 17" Laptop -Obsidian Black (Intel Evo
                                Core i7-1165G7/1TB SSD/16GB RAM) -En -Only at
                                Best Buy
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $399.00
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm4.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">rolex</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                HP 15.6" Touchscreen Laptop - Natural Silver
                                (AMD Ryzen 5 5625U/1TB SSD/12GB RAM/Windows 11)
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $32.50
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="shop_item bdrtrb1 px-2 px-sm-3">
                          <div className="thumb pb30">
                            <img
                              src="images/shop-items/bslm5.png"
                              alt="Best Seller Item"
                            />
                            <div className="thumb_info">
                              <ul className="mb0">
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-heart" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-dashboard-wish-list.html">
                                    <span className="flaticon-show" />
                                  </a>
                                </li>
                                <li>
                                  <a href="page-shop-list-v6.html">
                                    <span className="flaticon-graph" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="shop_item_cart_btn d-grid">
                              <a
                                href="page-shop-cart.html"
                                className="btn btn-thm"
                              >
                                Add to Cart
                              </a>
                            </div>
                          </div>
                          <div className="details">
                            <div className="sub_title">rolex</div>
                            <div className="title">
                              <a href="page-shop-single-v1.html">
                                Marketside Large Cage Free Brown Eggs, 36 Oz, 18
                                Ct
                              </a>
                            </div>
                            <div className="review d-flex db-500">
                              <ul className="mb0 me-2">
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a>
                                    <i className="fas fa-star" />
                                  </a>
                                </li>
                              </ul>
                              <div className="review_count">
                                <a>3,014 reviews</a>
                              </div>
                            </div>
                            <div className="si_footer">
                              <div className="price">
                                $18.124
                                <small>
                                  <del>$45</del>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Delivery Divider */}
      <section className="deliver-divider pt0 pb90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="online_delivery text-center">
                <h5 className="title">{homePage.deliveryText[language]}</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      {homeProducts.length > 0 &&
        homeProducts.map(({ id, name_eng, name_arb, products }, index) => (
          <ProductListingContainer
            key={index}
            name_eng={name_eng}
            name_arb={name_arb}
            products={products}
            hideTopRight={false}
          />
        ))}
    </div>
  );
};

export default Home;
