const UOMTableHeader = () => {
  return (
    <thead className="table-light">
      <tr className="text-center">
        <th scope="col">ID</th>
        <th scope="col">Active</th>
        <th scope="col">UOM Name</th>
        <th scope="col"> Action</th>
      </tr>
    </thead>
  );
};

export default UOMTableHeader;
