const classes = {
  Delivered: "style1",
  Cancel: "style2",
  "In Progress": "style3",
};

const OrderItem = ({ order }) => {
  const {
    id,
    name,
    order_date,
    paid_amount,
    order_state,
    invoice_amount,
    total_without_tax,
    total_with_tax,
  } = order;
  return (
    <>
      <tr>
        <th scope="row">#{id}</th>
        <td>{name}</td>
        <td>{order_date}</td>
        <td>{paid_amount}</td>
        <td className="status">
          <span>{order_state}</span>
        </td>
        <td>${invoice_amount}</td>
        <td>${total_with_tax}</td>
        <td>${total_without_tax}</td>
      </tr>
    </>
  );
};

export default OrderItem;
